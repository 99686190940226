import { Channel } from "@core/models/channel.model";

export interface JsonTvParamForbiddenScreen {
  id?: number;
  heureEcran?: string;
  _embedded?: object;
  channel?: Channel;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
}

export class TvParamForbiddenScreen {
  public id: number;
  public heureEcran: string;
  public channel: Channel;
  public monday: boolean;
  public tuesday: boolean;
  public wednesday: boolean;
  public thursday: boolean;
  public friday: boolean;
  public saturday: boolean;
  public sunday: boolean;

  constructor(jsonTvParamForbiddenScreen: JsonTvParamForbiddenScreen = null) {
    this.id = jsonTvParamForbiddenScreen.id;
    this.heureEcran = jsonTvParamForbiddenScreen.heureEcran;
    this.monday = jsonTvParamForbiddenScreen.monday;
    this.tuesday = jsonTvParamForbiddenScreen.thursday;
    this.wednesday = jsonTvParamForbiddenScreen.wednesday;
    this.thursday = jsonTvParamForbiddenScreen.thursday;
    this.friday = jsonTvParamForbiddenScreen.friday;
    this.saturday = jsonTvParamForbiddenScreen.saturday;
    this.sunday = jsonTvParamForbiddenScreen.sunday;

    if (jsonTvParamForbiddenScreen.channel) {
      this.channel = jsonTvParamForbiddenScreen.channel;
    } else if (
      jsonTvParamForbiddenScreen._embedded &&
      jsonTvParamForbiddenScreen._embedded["channel"]
    ) {
      this.channel = new Channel(
        jsonTvParamForbiddenScreen._embedded["channel"]
      );
    }
  }

  public getDays = () =>
    Object.entries(this)
      .filter((key) => key[0] !== "id")
      .filter((key) => key[0] !== "heureEcran");

  private translateDay = (day: string): string => {
    switch (day) {
      case "monday":
        return "Lundi";
      case "tuesday":
        return "Mardi";
      case "wednesday":
        return "Mercredi";
      case "thursday":
        return "Jeudi";
      case "friday":
        return "Vendredi";
      case "saturday":
        return "Samedi";
      case "sunday":
        return "Dimanche";
      default:
        return "";
    }
  };

  public daysToString = (): string => {
    const days = this.getDays();

    if (days.filter((day) => day[1] === false).length === 0) {
      return "Tous les jours";
    }

    const strDays = [];
    days
      .filter((day) => day[1] === true)
      .forEach((day) => strDays.push(this.translateDay(day[0])));

    return strDays.join(" ,");
  };
}
