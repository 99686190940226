import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "@env/environment";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { TvMoteurLog } from "@core/models/tv-moteur-log.model";

export interface TvMoteurLogFilter {
  itemPerPage?: number;
  page?: number;
  sortActive?: string;
  sortDirection?: string;
  search?: string;
}

@Injectable({
  providedIn: "root",
})
export class TvMoteurLogService {
  private tvMoteurLogRoute = "/tv/tv-moteur-log";

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  public getList(filter: TvMoteurLogFilter): Observable<any> {
    let url = environment.api_base_url + this.tvMoteurLogRoute;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }
    url += "?" + params.toString();

    return Observable.create((observer) => {
      this.httpService
        .get(url)
        .pipe(
          map((response) => {
            if (response["_embedded"]) {
              const total =
                response["_embedded"]["TvMoteurLog"][
                  response["_embedded"]["TvMoteurLog"].length - 1
                ]["total_count"];
              response["_embedded"]["TvMoteurLog"].splice(-1, 1);
              const list = response["_embedded"]["TvMoteurLog"].map(
                (json) => new TvMoteurLog(json)
              );
              return { list: list, total: total };
            }
            return response;
          })
        )
        .subscribe(
          (response) => observer.next(response),
          (error) => observer.error(this.catchError(error))
        );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open("Aucun traitement n'a été trouvé", null, {
          duration: 2000,
          verticalPosition: "top",
        });
        break;
      default:
        this.snackBar.open(
          error.error.detail ? error.error.detail : "Une erreur est survenue",
          null,
          { duration: 2000, verticalPosition: "top" }
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
