import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService, HttpError } from '@core/services/error-handler.service';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { ContractCgrpOff } from '@core/models/contract-cgrp-off.model';

@Injectable({
  providedIn: 'root'
})
export class ContractCgrpOffService extends BaseService {

  constructor(private httpService: HttpClient, private errorHandlerService: ErrorHandlerService) {
    super();
  }

  public getList(filter): Observable<any> {
    const httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: 'Impossible de récuperer la liste. Veuillez essayer plus tard.',
      },
      {
        statusCode: 500,
        message: 'Une erreur est survenue. Veuillez essayer plus tard.',
      },
    ];

    let url = environment.contract_microservice_api_base_url + '/contract_cgrp_off?' + this.getParamsFromFilter(filter);

    return new Observable(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(response => {
            let result = {
              items: [],
            };

            if (response['data']) {
              result.items = response['data'].map(item => {
                return new ContractCgrpOff(item);
              });
            }
            return result;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  public duplicate(id): Observable<any> {
    let url = environment.contract_microservice_api_base_url + '/contract_cgrp_off?duplicate=' + id;

    return this.httpService.get(url);
  }

  public delete(id): Observable<any> {
    const url = environment.contract_microservice_api_base_url + "/contract_cgrp_off/" + id;

    return this.httpService.delete(url);
  }

  public saveItem(data) {
    const url = environment.contract_microservice_api_base_url + '/contract_cgrp_off';
    const httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: 'Impossible de récuperer la liste des contrats. Veuillez essayer plus tard.',
      },
      {
        statusCode: 500,
        message: 'Une erreur est survenue. Veuillez essayer plus tard.',
      },
    ];

    return new Observable((observer) => {
      this.httpService.post(url, data).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          this.errorHandlerService.showErrorMessage(httpErrors, error.status);
          observer.error(error);
        }
      );
    });
  }
}
