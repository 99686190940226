export interface JsonContractSecodip {
  codSecodip?: number;
  libSecodip?: string;
  annDeb?: string;
  annFin?: string;
}

export class ContractSecodip {
  id: number;
  name: string;
  startYear: string;
  endYear: string;

  constructor(jsonContractSecodip: JsonContractSecodip = null) {
    this.id = jsonContractSecodip.codSecodip;
    this.name = jsonContractSecodip.libSecodip;
    this.startYear = jsonContractSecodip.annDeb;
    this.endYear = jsonContractSecodip.annFin;
  }
}
