import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContractComment, JsonContractComment } from '@core/models/contract-comment/contract-comment.model';
import { BaseService } from '@core/services/base/base.service';
import { ErrorHandlerService, HttpError } from '@core/services/error-handler.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContractCommentService extends BaseService {
  private httpErrors: HttpError[] = [
    { statusCode: 404, message: 'Commentaire non trouvé.' },
    { statusCode: 500, message: 'Erreur serveur, veuillez réessayer plus tard.' },
  ];

  constructor(private httpService: HttpClient, private errorHandlerService: ErrorHandlerService) {
    super();
  }

  public getCommentsFor1Contract(contractId: string): Observable<ContractComment[]> {
    if (!contractId) return;

    const url = `${environment.contract_microservice_api_base_url}/contract_comment?contractId=${contractId}`;

    return new Observable<ContractComment[]>(observer => {
      this.httpService
        .get(url)
        .pipe(
          map((response: any) => {
            let comments: ContractComment[] = [];
            if (response && response.data && Array.isArray(response.data)) {
              comments = response.data.map(comment => new ContractComment(comment));
            }
            return comments;
          })
        )
        .subscribe(
          (comments: ContractComment[]) => observer.next(comments),
          error => {
            this.errorHandlerService.showErrorMessage(this.httpErrors, error.status, error.message);
            observer.error(error);
          }
        );
    });
  }

  public deleteComment(commentId: string): Observable<void> {
    const url = `${environment.contract_microservice_api_base_url}/contract_comment/${commentId}`;

    return new Observable<void>(observer => {
      this.httpService.delete(url).subscribe(
        () => observer.next(),
        error => {
          this.errorHandlerService.showErrorMessage(this.httpErrors, error.status, error.message);
          observer.error(error);
        }
      );
    });
  }

  public updateComment(comment: ContractComment): Observable<ContractComment> {
    const url = `${environment.contract_microservice_api_base_url}/contract_comment/${comment.id}`;

    return new Observable<ContractComment>(observer => {
      this.httpService
        .put(url, comment)
        .pipe(
          map(response => response as JsonContractComment),
          map(jsonResponse => new ContractComment(jsonResponse))
        )
        .subscribe(
          (updatedComment: ContractComment) => observer.next(updatedComment),
          error => {
            this.errorHandlerService.showErrorMessage(this.httpErrors, error.status, error.message);
            observer.error(error);
          }
        );
    });
  }

  public addComment(comment: ContractComment): Observable<ContractComment> {
    const url = `${environment.contract_microservice_api_base_url}/contract_comment`;

    return new Observable<ContractComment>(observer => {
      this.httpService
        .post(url, comment)
        .pipe(
          map(response => {
            return response as JsonContractComment;
          }),
          map(jsonResponse => new ContractComment(jsonResponse))
        )
        .subscribe(
          (newComment: ContractComment) => observer.next(newComment),
          error => {
            this.errorHandlerService.showErrorMessage(this.httpErrors, error.status, error.message);
            observer.error(error);
          }
        );
    });
  }
}
