import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContractItem } from '@core/models/contract-item.model';
import { ContractState } from '@core/models/contract-state.model';
import { Contract } from '@core/models/contract.model';
import { ContractFilter, ContractService, ContractStateFilter } from '@core/services/contract/contract.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-contract-item',
  templateUrl: './contract-item.component.html',
  styleUrls: ['./contract-item.component.scss'],
})
export class ContractItemComponent implements OnInit, OnDestroy {
  private yearUrlIndex = 3;
  private contractIdUrlIndex = 4;
  public isLoadingContract: Boolean = false;
  public isLoadingContractItem: Boolean = false;
  public isLoadingContractState: Boolean = false;
  public currentContract: Contract;
  public currentContractItem: ContractItem;
  public contractStates: ContractState[] = [];
  // public contractStatesFormControl = new FormControl(); // ask JPI if need to save state
  public showDropDown = false;
  public stateDropDownId = 'stateDropDown';
  public selectedValue: number;
  public selectedLabel: string;

  public contractFilter: ContractFilter;
  public stateFilter: ContractStateFilter;

  private componentDestroyed$: Subject<void> = new Subject();

  constructor(private router: Router, private contractService: ContractService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.getContractFilterParams();
    this.getStateFilterParams();

    this.getContract(this.contractFilter); // old model data
    this.getContractItem(this.contractFilter); // new model data
    this.getContractStateList(this.stateFilter);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  public getContractFilterParams(): any {
    this.contractFilter = {
      year: this.router.url.split('/')[this.yearUrlIndex],
      searchElementById: this.router.url.split('/')[this.contractIdUrlIndex] || '',
    };
  }

  public getStateFilterParams(): any {
    this.stateFilter = {
      year: this.router.url.split('/')[this.yearUrlIndex],
    };
  }

  public getContract(filter) {
    this.isLoadingContract = true;

    // search by id and year always return only one contract : can call getContractList,
    this.contractService
      .getContractList(filter)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        contract => {
          this.isLoadingContract = false;
          if (contract[0]) {
            this.currentContract = contract[0];
            this.currentContract.name = this.capitalizeWords(contract[0].name);
            this.selectedValue = contract[0].stateContractId;
            this.selectedLabel = contract[0].stateContractLabel;
          }
        },
        error => {
          this.isLoadingContract = false;
        }
      );
  }

  public getContractStateList(filter) {
    this.isLoadingContractState = true;
    this.contractService
      .getContractStateList(this.stateFilter)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        contractStates => {
          this.isLoadingContractState = false;
          this.contractStates = contractStates;
        },
        error => {
          this.isLoadingContractState = false;
        }
      );
  }

  public onReturnButtonClick(): void {
    this.router.navigate(['/commercial/contracts']);
  }

  public capitalizeWords(text: string): string {
    return text.toLowerCase().replace(/\b[a-z]/g, function (char) {
      return char.toUpperCase();
    });
  }

  onShowDropDownButtonClick(): void {
    this.showDropDown = !this.showDropDown;
  }

  public onSelectStatesChange(stateId) {
    this.showDropDown = !this.showDropDown;
    this.selectedValue = stateId;
    this.selectedLabel = this.getSelectedLabel(stateId);

    // TODO : need to save new status ? ask @JPI
  }

  getSelectedLabel(stateId): string {
    const selectedOption = this.contractStates.find(state => state.id === stateId);
    return selectedOption ? selectedOption.name : '';
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    const targetElementWithId = document.getElementById(this.stateDropDownId);
    const clickedInsideTargetId = targetElementWithId ? targetElementWithId.contains(targetElement) : false;

    if (!clickedInsideTargetId) {
      this.showDropDown = !!clickedInsideTargetId;
    }
  }

  public getContractItem(filter) {
    this.isLoadingContractItem = true;

    this.contractService
      .getContractItem(filter)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        contractItem => {
          this.isLoadingContractItem = false;
          if (contractItem) {
            this.currentContractItem = contractItem;
          }
        },
        error => {
          this.isLoadingContractItem = false;
        }
      );
  }
}
