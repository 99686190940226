export interface JsonContractAgency {
  numClient?: number;
  nomAd2?: string;
}

export class ContractAgency {
  id: number;
  name: string;

  constructor(jsonContractAgency: JsonContractAgency = null) {
    this.id = jsonContractAgency.numClient;
    this.name = jsonContractAgency.nomAd2;
  }
}
