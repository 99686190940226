import { Component } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { PlanService } from "@core/services/plan/plan.service";
import { FacadeService } from "@core/services/facade.service";

import {
  JsonPlanSpot,
  PlanCampaign,
  PlanChannel,
  PlanPerformance,
  PlanSpot,
  PlanTarget,
} from "@core/models/plan/plan.model";

import {
  FilteredCampaign,
  FilteredChannel,
} from "@core/models/plan-filter/plan-filter.model";

import * as moment from "moment";

@Component({
  selector: "app-save-plan",
  templateUrl: "./save-plan.component.html",
  styleUrls: ["./save-plan.component.scss"],
})
export class SavePlanComponent {
  protected planForm: UntypedFormGroup;

  /**
   * Get name
   */
  get name(): string {
    return this.planForm.get("name").value;
  }

  /**
   * Get id of product if exist
   */
  get productId(): number {
    return this.planForm.get("product").value?.id
      ? this.planForm.get("product").value.id
      : null;
  }

  constructor(
    public planService: PlanService,
    public facadeService: FacadeService
  ) {}

  /**
   * Get checked target ID corresponding to main plan target
   */
  public checkedTarget(): string | null {
    const checkedTarget = this.planForm
      .get("targets")
      .value.find((target) => !!target.check);

    return checkedTarget !== undefined && checkedTarget?.input?.id
      ? checkedTarget.input.id
      : null;
  }

  /**
   * Build plan for API
   */
  protected buildPlan(isNewPlan: boolean = false): PlanPerformance {
    const paramsPlan = {
      title: this.name,
      startPeriod:
        this.planForm.get("startDate").value &&
        moment(this.planForm.get("startDate").value, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
      endPeriod:
        this.planForm.get("endDate").value &&
        moment(this.planForm.get("endDate").value, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
      productId: this.productId,
      targetId: this.checkedTarget(),
      couverture: this.planForm.get("couverture").value,
      campaigns: this.getPlanCampaigns(isNewPlan),
      channels: this.getPlanChannels(isNewPlan),
      targets: this.getPlanTargets(isNewPlan),
      spots: this.getPlanSpots(isNewPlan, this.planForm.get("spots").value),
      createUser: this.facadeService?.auth?.currentUserData?.sub?.toUpperCase(),
    };

    if (!isNewPlan) {
      paramsPlan["updateUser"] =
        this.facadeService?.auth?.currentUserData?.sub?.toUpperCase();
    }

    return paramsPlan;
  }

  /**
   * Get PlanSpot list associated to current Plan
   * @param isNewPlan
   * @param spots of plan
   */
  private getPlanSpots(isNewPlan: boolean, spots: JsonPlanSpot[]): PlanSpot[] {
    const parsedSpots = [];

    spots.forEach((spot: JsonPlanSpot) => {
      const jsonSpot = { ...spot };

      // Override id value, then format realScreenDay and displayScreenDay
      jsonSpot.id = isNewPlan ? null : spot.id;
      jsonSpot.realScreenDay = moment(spot.realScreenDay, "DD/MM/YYYY")
        .format("YYYY-MM-DD");
      jsonSpot.displayScreenDay = moment(spot.displayScreenDay, "DD/MM/YYYY")
        .format("YYYY-MM-DD");

      parsedSpots.push(new PlanSpot(jsonSpot));
    });

    return parsedSpots;
  }

  /**
   * Build PlanChannel array from checked FilteredChannel list associated to current Plan
   * @param isNewPlan if we need to create new plan
   */
  private getPlanChannels(isNewPlan: boolean): PlanChannel[] {
    const channels = [];

    this.planForm.get("channels").value?.forEach((channel: FilteredChannel) => {
      if (channel.id && channel.name) {
        const jsonChannel = {
          id: isNewPlan ? null : this.findPlanChannelId(channel.id),
          channelId: channel.id,
          channelName: channel.name,
          areaId: channel.areaId,
        };

        channels.push(new PlanChannel(jsonChannel));
      }
    });

    return channels;
  }

  /**
   * Get corresponding PlanChannel table ID, if already persisted, of channel identified by argument channelId
   * @param id to find
   */
  private findPlanChannelId(id: number): number | null {
    const foundChannel = this.planService.planPerformance.channels.find(
      (channel: PlanChannel) => +channel.channelId === id
    );

    return foundChannel?.id ? foundChannel.id : null;
  }

  /**
   * Build PlanCampaign array from checked FilteredCampaign list associated to current Plan
   * @param isNewPlan if we need to create new plan
   */
  private getPlanCampaigns(isNewPlan: boolean): PlanCampaign[] {
    const campaigns = [];

    this.planForm
      .get("campaigns")
      .value?.forEach((campaign: FilteredCampaign) => {
        if (
          campaign.id &&
          campaign.cpYear &&
          campaign.cpNum &&
          this.planForm.get("product").value?.id
        ) {
          const jsonCampaign = {
            id: isNewPlan ? null : this.findPlanCampaignId(campaign.id),
            campaignId: campaign.id,
            campaignNum: campaign.cpNum,
            campaignYear: campaign.cpYear,
            productId: this.planForm.get("product").value?.id,
          };

          campaigns.push(new PlanCampaign(jsonCampaign));
        }
      });

    return campaigns;
  }

  /**
   * Get corresponding PlanCampaign table ID, if already persisted, of campaign identified by argument campaignId
   * @param id to find
   */
  private findPlanCampaignId(id: number): number | null {
    const foundCampaign = this.planService.planPerformance.campaigns.find(
      (campaign: PlanCampaign) => +campaign.campaignId === id
    );

    return foundCampaign?.id ? foundCampaign.id : null;
  }

  /**
   * Get associated plan targets
   */
  private getPlanTargets(isNewPlan: boolean): PlanTarget[] {
    const targets = [];

    this.planForm.get("targets").value?.forEach((target: any) => {
      if (target?.input?.id) {
        const data = {};

        data["targetId"] = target.input.id;
        data["id"] = isNewPlan ? null : this.findPlanTargetId(target.input?.id);

        targets.push(data);
      }
    });

    return targets;
  }

  /**
   * Get corresponding PlanTarget table ID, if already persisted, of target identified by argument targetId
   * @param id to find
   */
  private findPlanTargetId(id: string): number | null {
    const foundTarget = this.planService.planPerformance.targets.find(
      (target: PlanTarget) => target.targetId === id
    );

    return foundTarget?.id ? foundTarget.id : null;
  }
}
