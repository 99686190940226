import { Pipe, PipeTransform } from '@angular/core';
import { TvChannel } from '@core/models/tv-channel.model';

export interface Item {
  name: string;
}

@Pipe({
  name: 'channelFilter',
})
export class ChannelFilterPipe implements PipeTransform {
  transform(items: TvChannel[], args?: any): any {
    if (args) {
      if (items) {
        return items.filter(item => args && item && item.name && item.name.toLowerCase().includes(args.toLowerCase()));
      } else {
        return [];
      }
    }
    return items;
  }
}
