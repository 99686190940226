import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-screen-type',
  templateUrl: './screen-type.component.html',
  styleUrls: ['./screen-type.component.scss']
})
export class ScreenTypeComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<void> = new Subject();
  searchControl: FormControl;
  goBack = false;
  typeSource = [];
  typeSelectedRoute = [];
  typeSelectedDisplayList = [];
  typeSelected = [];
  routeName = '';
  routeNameList = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ScreenTypeComponent>,
  ) { 
    this.searchControl = new FormControl(null, [
      Validators.minLength(2),
      Validators.maxLength(40),
    ]);
  }

  ngOnInit(): void {
    this.search();
    this.typeSource = this.data.screenType;
    this.typeSelectedDisplayList = this.data.screenType;
    if (this.data.selectedType) {
      this.typeSelected = this.data.selectedType;
    }
    this.closeByClickingOutside();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  /**
   * Display children type list
   * @param type 
   */
  showChildren(type): void {
    this.typeSelectedDisplayList = type.children;

    // push children libevt to create and generate routename
    this.routeNameList.push(type.libevt);
    this.generateRouteName(this.routeNameList);

    // we push children display list to know the path back to display
    this.typeSelectedRoute.push(this.typeSelectedDisplayList);

    this.goBack = true;
  }

  selectType(type): void {
    if (!type.isSelected) {
      type.isSelected = true;
      this.typeSelected.push(type);
      this.data.screenTypeToInsert.push(type);
    } else {
      type.isSelected = false;
      let isNotSelected = this.typeSelected.findIndex(e => e.isSelected === false);
      this.typeSelected.splice(isNotSelected, 1);
      this.data.screenTypeToDelete.push(type);
    }
  }

  goBackParent(): void {
    this.searchControl.setValue('', { emitEvent: false });
    for (let key of Object.keys(this.typeSelectedRoute)) {
      if (this.typeSelectedRoute.length > 1) {
        this.typeSelectedDisplayList = this.typeSelectedRoute[Number(key)-1];
      } else {
        this.typeSelectedDisplayList = this.typeSource;
        this.goBack = false;
        this.routeName = '';
      }
    }
    //delete last element of array
    this.typeSelectedRoute.pop();
    this.routeNameList.pop();
    this.generateRouteName(this.routeNameList);
  }

  submit(): void {
    this.dialogRef.close(this.typeSelected);
  }

  closeByClickingOutside() {
    this.dialogRef.backdropClick().subscribe(()=>{
      this.dialogRef.close(this.typeSelected);
    });
  }

  generateRouteName(routeNameList): void {
    this.routeName = routeNameList.join('/ ');
  }

  clearSearchInput(): void {
    this.searchControl.setValue('', { emitEvent: false });
    this.goBack = false;
    this.routeName = '';
    this.typeSelectedRoute = [];
    this.typeSelectedDisplayList = this.typeSource;
  }

  search(): void {
    this.searchControl.valueChanges
    .pipe(
      debounceTime(500),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((data: string) => {
      this.typeSelectedDisplayList = [];
      this.deepSearch(this.typeSource, data.toUpperCase());
    });
  }

  /**
   * search correspondence inside parent/children/grandchildren
   * @param arr 
   * @param target 
   * @returns 
   */
  deepSearch(arr, target) {
    for (let obj of arr) {
      for (let key in obj) {
        if (key === 'libevt' && obj[key].includes(target)) {
          this.typeSelectedDisplayList.push(obj);
        } 
        else {
          if (typeof obj[key] === 'object') {
            this.deepSearch(obj[key], target);
          }
        }
      }
    }
    return null;
  } 
}
