import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TvChannel, TvChannelGroup } from '@core/models/tv-channel.model';
import { AuthService } from '@core/services/auth.service';
import { ChannelGroups, FakeRegChannel, TvChannelService } from '@core/services/tv-channel/tv-channel.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

export interface RegChannelCheckBoxOption {
  name: string;
  isChecked: boolean;
  regChannel: TvChannel | null;
}

@Component({
  selector: 'app-channel-select-with-reg-list',
  templateUrl: './channel-select-with-reg-list.component.html',
  styleUrls: ['./channel-select-with-reg-list.component.scss'],
})
export class ChannelSelectWithRegListComponent implements OnInit, OnDestroy {
  readonly allRegChannelCheckBoxOption: RegChannelCheckBoxOption = {
    name: 'Toutes les chaînes régionales',
    isChecked: false,
    regChannel: null,
  };
  public regChannelCheckBoxOptions: RegChannelCheckBoxOption[] = [];

  public showDropDown = false;
  public showChannelGroupSelectDropDown = false;
  public showRegListDropDown = false;
  public showClearButton = false;
  public showRegListButton = false;
  public currentChannel$: BehaviorSubject<TvChannel>;
  public channelGroupButtonTitle;

  public regChannelListIsLoading = true;

  public userInfoSubscription: Subscription;
  public channelSearchControlSubscription: Subscription;
  public userId = '';

  public channels$: Observable<TvChannel[]>;
  public filter;

  public regChannelsForm: FormGroup;
  public selectAllControl: FormControl;

  @ViewChild('channelInput', { static: true }) channelInput;

  public channelSearchControl = new FormControl();

  public channelGroups: TvChannelGroup[] = [];

  constructor(
    public fb: FormBuilder,
    public eRef: ElementRef,
    private tvChannelService: TvChannelService,
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getTvChannelGroups();
    this.handleChannelSearchControlSubscription();
  }

  ngOnDestroy(): void {
    if (this.userInfoSubscription) {
      this.userInfoSubscription.unsubscribe();
    }
    if (this.channelSearchControlSubscription) {
      this.channelSearchControlSubscription.unsubscribe();
    }
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement): void {
    const clickedInside = this.eRef.nativeElement.contains(targetElement);

    if (!clickedInside) {
      this.showDropDown = !!this.eRef.nativeElement.contains(targetElement);
      this.showRegListDropDown = !!this.eRef.nativeElement.contains(targetElement);
    }
  }

  onShowDropDownButtonClick(): void {
    this.showDropDown = !this.showDropDown;
  }

  onShowChannelGroupSelectDownButtonClick(): void {
    this.showChannelGroupSelectDropDown = !this.showChannelGroupSelectDropDown;
  }

  clearChannelSearchInput(): void {
    this.channelSearchControl.setValue('');
    this.showClearButton = false;
  }

  onGroupSelectOptionClick(option: TvChannelGroup): void {
    this.channelGroupButtonTitle = option.name;
    this.showChannelGroupSelectDropDown = false;

    if (option.id === ChannelGroups[0].id || option.id === ChannelGroups[1].id) {
      // Case AllChannels and Favourite Channels
      this.getTvChannels(option.name, this.userId, null, null, true);
    } else if (option.isNat == false) {
      // Case F3 Reg Group
      this.showFakeRegChannelAndButton();
    } else {
      // Case get channels by group
      this.getTvChannels(option.name, this.userId, option.channelGroup, option.isNat);
    }

    this.channelSearchControl.setValue('');
  }

  getTvChannels(group: string, userId, groupChannelId?, isNat?, shouldShowOnlyNatFullList?): void {
    this.channels$ = this.tvChannelService.getTvChannels(group, userId, groupChannelId, isNat, shouldShowOnlyNatFullList);
  }

  showFakeRegChannelAndButton() {
    this.channels$ = this.tvChannelService.getFakeRegChannel();
  }

  onChooseChannelClick(channel: TvChannel): void {
    this.tvChannelService.setCurrentChannel(channel);

    if (channel.id === FakeRegChannel.id) {
      this.showRegListButton = true;
      this.regChannelCheckBoxOptions = [];
      this.tvChannelService.setCurrentChannels([]);
    } else {
      this.showRegListButton = false;
      this.tvChannelService.setCurrentChannels([channel]);
    }
    setTimeout(_ => {
      this.showDropDown = false;
      this.showChannelGroupSelectDropDown = false;
    });
  }

  handleChannelSearchControlSubscription(): void {
    this.channelSearchControlSubscription = this.channelSearchControl.valueChanges.subscribe(value => {
      this.showClearButton = !!value;
      this.filter = value;
    });
  }

  getTvChannelGroups(): void {
    this.tvChannelService.getChannelGroups().subscribe(
      channelGroups => {
        this.channelGroups = channelGroups;
        this.prepareSetUp();
      },
      () => {
        this.channelGroups = ChannelGroups;
        this.prepareSetUp();
      }
    );
  }

  prepareSetUp(): void {
    /* Set default channel group */
    this.channelGroupButtonTitle = this.tvChannelService.favoriteChannelGroup.name;

    this.getFavoriteChannels();
    this.currentChannel$ = this.tvChannelService.currentChannel$;
  }

  getFavoriteChannels(): void {
    /* Get favorites channels */
    this.userInfoSubscription = this.authService.getUserInfo().subscribe(info => {
      this.userId = info.sub;
      this.getTvChannels(this.tvChannelService.favoriteChannelGroup.name, info.sub);
    });
  }

  onShowRelListPopupClick() {
    this.showRegListDropDown = true;
    if (this.regChannelCheckBoxOptions.length === 0) {
      this.regChannelListIsLoading = true;
      this.tvChannelService.getRegChannels(this.userId).subscribe((regChannels: TvChannel[]) => {
        this.regChannelCheckBoxOptions = regChannels.map(regChannel => {
          const regChannelCheckBoxOption: RegChannelCheckBoxOption = {
            name: regChannel.name,
            isChecked: false,
            regChannel: regChannel,
          };
          return regChannelCheckBoxOption;
        });
        if (this.regChannelCheckBoxOptions.length > 0) {
          this.initializeForm();
          this.subscribeToRegChannelOptionChange();
          this.regChannelListIsLoading = false;
        } else {
          this.snackBar.open('Aucune chaîne régionale non trouée.', null, {
            duration: 2000,
            verticalPosition: 'top',
            panelClass: ['chip-error'],
          });
        }
      });
    }
  }

  public initializeForm(): void {
    this.selectAllControl = new FormControl(false);
    const channelControls = this.regChannelCheckBoxOptions.map(option => new FormControl(option.isChecked));

    this.regChannelsForm = this.fb.group({
      selectAll: this.selectAllControl,
      channels: this.fb.array(channelControls),
    });
  }

  public subscribeToRegChannelOptionChange() {
    this.regChannelsForm.get('channels')?.valueChanges.subscribe(values => {
      values.forEach((isChecked, index) => {
        this.regChannelCheckBoxOptions[index].isChecked = isChecked;
      });
      this.updateCurrentChannels();
    });
  }

  get channels(): FormArray {
    return this.regChannelsForm.get('channels') as FormArray;
  }

  public areAllChannelsChecked(): boolean {
    return this.channels.controls.every(control => control.value);
  }

  public toggleAllChannels(event: any): void {
    const isChecked = event.checked;
    this.channels.controls.forEach(control => control.setValue(isChecked));
    this.updateCurrentChannels();
  }

  public updateCurrentChannels() {
    const selectedChannels = this.regChannelCheckBoxOptions
      .filter((option: RegChannelCheckBoxOption) => option.isChecked === true)
      .map((option: RegChannelCheckBoxOption) => option.regChannel);
    this.tvChannelService.setCurrentChannels(selectedChannels);
  }
}
