import * as _moment from "moment";

const moment = _moment;

export interface JsonTvMoteurLog {
  id?: number;
  dtdTraitement?: string;
  dtfTraitement?: string;
  statut?: string;
  commentaire?: string;
}

export class TvMoteurLog {
  public id: number;
  public dtdTraitement: string;
  public dtfTraitement: string;
  public statut: string;
  public commentaire: string;

  constructor(jsonTvMoteurLog: JsonTvMoteurLog = null) {
    this.id = jsonTvMoteurLog.id;
    this.dtdTraitement = moment(jsonTvMoteurLog.dtdTraitement)
      .format("DD/MM/YYYY HH:mm:ss")
      .toString();
    this.dtfTraitement = moment(jsonTvMoteurLog.dtfTraitement)
      .format("DD/MM/YYYY HH:mm:ss")
      .toString();
    this.statut = jsonTvMoteurLog.statut;
    this.commentaire = jsonTvMoteurLog.commentaire;
  }
}
