import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { merge } from 'rxjs';

@Component({
  selector: 'app-day-filter',
  templateUrl: './day-filter.component.html',
  styleUrls: ['./day-filter.component.scss']
})
export class DayFilterComponent implements OnInit {
  @Input() screenFilterForm: FormGroup;

  showDropDown = false;
  daysControl: FormControl;
  allDaysControl: FormControl;
  daysSelection  = [];
  days = [];

  readonly filterAllDays: { name: string, ischeck: boolean } = 
    { name: 'Tous les jours', ischeck: true };

  readonly filterDaysToDisplay: { name: string, short: string, ischeck: boolean, id: number }[] = [
    { name: 'Lundi', short: 'Lun.', ischeck: true, id: 2 },
    { name: 'Mardi', short: 'Mar.', ischeck: true, id: 3 },
    { name: 'Mercredi', short: 'Mer.', ischeck: true, id: 4 },
    { name: 'Jeudi', short: 'Jeu.', ischeck: true, id: 5 },
    { name: 'Vendredi', short: 'Ven.', ischeck: true, id: 6 },
    { name: 'Samedi', short: 'Sam.', ischeck: true, id: 7 },
    { name: 'Dimanche', short: 'Dim.', ischeck: true, id: 1 },
  ];

  constructor(private eRef: ElementRef) {
    this.daysControl = new FormControl(this.filterDaysToDisplay);
    this.allDaysControl = new FormControl(this.filterAllDays)
   }

  ngOnInit(): void {
    this.collectSelectedDays();
    this.setAllDaysByDefault();
  }

  /**
   * Set all day by default if startDate and EndDate are the same
   */
  setAllDaysByDefault(): void {
    merge(
      this.screenFilterForm.get("startDate").valueChanges,
      this.screenFilterForm.get("endDate").valueChanges,
    )
    .subscribe(
      () => {
        if (this.screenFilterForm.get('startDate').value !== null
        && this.screenFilterForm.get('endDate').value !== null
        &&  moment(this.screenFilterForm.get('startDate').value).format('DD/MM/YYYY') ===  moment(this.screenFilterForm.get('endDate').value).format('DD/MM/YYYY')) {
          this.allDaysControl.value.ischeck = true;
          this.daysControl.value.forEach(element => {
            element.ischeck = true;
          });
          this.collectSelectedDays();
        }
      },
      (error: Error) => {
        console.error(error);
      }
    );
  }

  /**
   * when click to select/unselect a day
   * @param day 
   */
  onSelectDaysChange(day): void {
    day.ischeck = !day.ischeck;
    this.allDaysControl.value.ischeck = this.daysControl.value.every(elem => elem.ischeck === true);
    this.collectSelectedDays();
  }

  /**
   * when click to select/unselect all days filter
   */
  onSelectAllDaysChange(): void {
    this.allDaysControl.value.ischeck = !this.allDaysControl.value.ischeck;

    if (this.allDaysControl.value.ischeck) {
      this.daysControl.value.forEach(element => {
        element.ischeck = true;
      });
    } else {
      this.daysControl.value.forEach(element => {
        element.ischeck = false;
        this.daysSelection = [];
      });
    }
    this.collectSelectedDays();
  }

  collectSelectedDays(): void {
    this.daysControl.value.forEach(element => {
      if (element.ischeck) {
        if (!this.daysSelection.includes(element)) {
          this.daysSelection.push(element);
        }  
      } else {
        if (this.daysSelection.includes(element)) {
          let index = this.daysSelection.indexOf(element);
          if (index !== -1) {
            this.daysSelection.splice(index, 1);
          }
        }
      }
    });

    this.getDays();
    this.screenFilterForm.get('days').setValue(this.days);
  }

  getDays(): void {
    this.days = [];

    this.daysSelection.forEach(element => {
      this.days.push(element.short.toUpperCase())
    });
  }

  onShowDropDownButtonClick(): void {
    this.showDropDown = !this.showDropDown;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this.eRef.nativeElement.contains(targetElement);

    if (!clickedInside) {
      this.showDropDown = !!this.eRef.nativeElement.contains(targetElement)
    }
  }
}
