import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NegotiationType } from '@core/models/negotiation-type.model';
import { BaseService } from '@core/services/base/base.service';
import { ErrorHandlerService, HttpError } from '@core/services/error-handler.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface NegotiationTypeFilter {
  id: number;
  name: string;
  rank: number;
}

@Injectable({
  providedIn: 'root',
})
export class NegotiationTypeService extends BaseService {
  constructor(private httpService: HttpClient, private errorHandlerService: ErrorHandlerService) {
    super();
  }

  public getNegotiationTypeList(filter): Observable<NegotiationType[]> {
    const httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: 'Impossible de récuperer la liste des types de négociations. Veuillez essayer plus tard.',
      },
      {
        statusCode: 500,
        message: 'Une erreur est survenue. Veuillez essayer plus tard.',
      },
    ];

    let url = environment.contract_microservice_api_base_url + '/negotiation_type?' + this.getParamsFromFilter(filter);
    // let url = environment.contract_microservice_api_base_url + '/negotiation_type/' + filter.year;

    return new Observable(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(response => {
            let negotiationTypeList: NegotiationType[] = [];
            if (response) {
              for (const key in response) {
                negotiationTypeList.push(new NegotiationType(response[key]));
              }
            }
            return negotiationTypeList;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }
}
