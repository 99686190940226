export interface IFileModel {
  fileName: string;
  data: any;
}

export class FileModel implements IFileModel {
  public fileName: string;
  public data: any;
  constructor(json: IFileModel) {
    this.fileName = json.fileName;
    this.data = json.data;
  }
}
