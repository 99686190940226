import { ContractNatioNegoCondition } from '@core/models/contract-natio-nego-condition.model';
import { NegotiationType } from '@core/models/negotiation-type.model';

export interface JsonContractNatioNego {
  id?: number;
  contractItemId?: number;
  negotiationType?: NegotiationType;
  startDate?: string;
  endDate?: string;
  rate?: number;
  value?: number;
  conditions?: ContractNatioNegoCondition[];
  groupedConditions?: ContractNatioNegoCondition[][];
}

export class ContractNatioNego {
  id: number;
  contractItemId: number;
  negotiationType: NegotiationType;
  startDate: string;
  endDate: string;
  rate: number;
  value: number;
  conditions?: ContractNatioNegoCondition[];
  groupedConditions?: ContractNatioNegoCondition[][];

  constructor(jsonContractNatioNego: JsonContractNatioNego = null) {
    this.id = jsonContractNatioNego.id;
    this.contractItemId = jsonContractNatioNego.contractItemId;
    this.negotiationType = jsonContractNatioNego.negotiationType;
    this.startDate = jsonContractNatioNego.startDate;
    this.endDate = jsonContractNatioNego.endDate;
    this.rate = jsonContractNatioNego.rate;
    this.value = jsonContractNatioNego.value;
    this.conditions = jsonContractNatioNego.conditions;
    this.groupedConditions = jsonContractNatioNego.groupedConditions;
  }
}
