import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContractAdvertiser } from '@core/models/contract-advertiser.model';
import { ContractAgency } from '@core/models/contract-agency.model';
import { ContractCampaign } from '@core/models/contract-campaign.model';
import { ContractProductType } from '@core/models/contract-product-type.model';
import { ContractProduct } from '@core/models/contract-product.model';
import { ContractSecodip } from '@core/models/contract-secodip.model';
import { ContractTarget } from '@core/models/contract-target.model';
import { BaseService } from '@core/services/base/base.service';
import { ErrorHandlerService, HttpError } from '@core/services/error-handler.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContractCategoryTypeService extends BaseService {
  constructor(private httpService: HttpClient, private errorHandlerService: ErrorHandlerService) {
    super();
  }

  private getUrlByCategoryType(filter, categoryType): string {
    switch (categoryType.id) {
      case 1: // Advertiser
        return environment.contract_microservice_api_base_url + '/contract_advertiser?' + this.getParamsFromFilter(filter);
      case 2: // Mandatory / Agency
        return environment.contract_microservice_api_base_url + '/contract_agency?' + this.getParamsFromFilter(filter);
      case 3: // Secodip
        return environment.contract_microservice_api_base_url + '/contract_secodip?' + this.getParamsFromFilter(filter);
      case 4: // Product
        return environment.contract_microservice_api_base_url + '/contract_product?' + this.getParamsFromFilter(filter);
      case 5: // Product Type
        return environment.contract_microservice_api_base_url + '/contract_product_type?' + this.getParamsFromFilter(filter);
      case 6: // Campaign
        return environment.contract_microservice_api_base_url + '/contract_campaign?' + this.getParamsFromFilter(filter);
      case 7: // Target
        return environment.contract_microservice_api_base_url + '/target?' + this.getParamsFromFilter(filter);
      case 8: // Channel
        return environment.contract_microservice_api_base_url + '/contract_channel?' + this.getParamsFromFilter(filter);
      case 9: // Screen Type
        return environment.contract_microservice_api_base_url + '/contract_screen_type?' + this.getParamsFromFilter(filter);
      case 10: // Screen
        return environment.contract_microservice_api_base_url + '/contract_screen?' + this.getParamsFromFilter(filter);
      case 11: // Time Slot
        return environment.contract_microservice_api_base_url + '/contract_time_slot?' + this.getParamsFromFilter(filter);
      default:
        break;
    }
  }

  private getItemListByCategoryType(categoryType, response): any[] {
    switch (categoryType.id) {
      case 1: // Advertiser
        return response.map(contractAdvertiser => new ContractAdvertiser(contractAdvertiser));
      case 2: // Mandatory / Agency
        return response.map(contractAgency => new ContractAgency(contractAgency));
      case 3: // Secodip
        return response.map(contractSecodip => new ContractSecodip(contractSecodip));
      case 4: // Product
        return response.map(contractProduct => new ContractProduct(contractProduct));
      case 5: // Product Type
        return response.map(contractProductType => new ContractProductType(contractProductType));
      case 6: // Campaign
        return response.map(contractCampaign => new ContractCampaign(contractCampaign));
      case 7: // Target
        return response.map(contractTarget => new ContractTarget(contractTarget));
      case 8: // Channel
        return [];
      case 9: // Screen Type
        return [];
      case 10: // Screen
        return [];
      case 11: // Time Slot
        return [];
      default:
        return [];
    }
  }

  public getItemList(filter, categoryType): Observable<any[]> {
    const httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: 'Impossible de récuperer la liste. Veuillez essayer plus tard.',
      },
      {
        statusCode: 500,
        message: 'Une erreur est survenue. Veuillez essayer plus tard.',
      },
    ];

    let url = this.getUrlByCategoryType(filter, categoryType);

    return new Observable(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(response => {
            let itemList = [];
            if (response && Array.isArray(response)) {
              itemList = this.getItemListByCategoryType(categoryType, response);
            }
            return itemList;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }
}
