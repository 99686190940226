import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { FacadeService } from '@core/services/facade.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(private facadeService: FacadeService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.facadeService.auth.isAuthorized().pipe(
      map(isAuthorized => {
        if (!isAuthorized) {
          this.facadeService.auth.redirectUrl = state.url || '';
          this.router.navigate(['/login']);
          return false;
        }
        this.facadeService.auth.handleTokenChange(this.facadeService.auth.getToken());
        return isAuthorized;
      })
    );
  }
}
