import { ContractCgrpOff } from '@core/models/contract-cgrp-off.model';
import { ContractNatioNego } from '@core/models/contract-natio-nego.model';

export interface JsonContractItem {
  id: number;
  advertiserId: string;
  year: string;
  natioNegotiationList: ContractNatioNego[];
  cgrpOffNegotiationList: ContractCgrpOff[];
}

export class ContractItem {
  id: number;
  advertiserId: string;
  year: string;
  natioNegotiationList: ContractNatioNego[];
  cgrpOffNegotiationList: ContractCgrpOff[];

  constructor(jsonContractItem: JsonContractItem = null) {
    this.id = jsonContractItem.id;
    this.advertiserId = jsonContractItem.advertiserId;
    this.year = jsonContractItem.year;
    this.natioNegotiationList = jsonContractItem.natioNegotiationList;
    this.cgrpOffNegotiationList = jsonContractItem.cgrpOffNegotiationList;
  }
}
