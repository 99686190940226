import { Component, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CategoryType } from '@core/models/category-type.model';
import { ContractItem } from '@core/models/contract-item.model';
import { ContractNatioNego } from '@core/models/contract-natio-nego.model';
import { Contract } from '@core/models/contract.model';
import { FilteredNegociationType, NegotiationType } from '@core/models/negotiation-type.model';
import { CategoryTypeService } from '@core/services/category-type/category-type.service';
import { ContractNatioNegoService } from '@core/services/contract-natio-nego/contract-natio-nego.service';
import { NegotiationTypeService } from '@core/services/negotiation-type/negotiation-type.service';
import { NatioNegoConditionDynamicComponent } from '@shared/contract/contract-item/contract-natio-nego/natio-nego-condition-dynamic/natio-nego-condition-dynamic.component';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-natio-nego-managing-dialog',
  templateUrl: './natio-nego-managing-dialog.component.html',
  styleUrls: ['./natio-nego-managing-dialog.component.scss'],
})
export class NatioNegoManagingDialogComponent implements OnInit {
  // Load & Display
  isCategoryTypeLoading: boolean = false;
  isNegotiationTypeLoading: boolean = false;
  categoryTypeList: CategoryType[];
  negotiationTypeList: NegotiationType[];
  filteredNegotiationTypeList: Observable<NegotiationType[]>;
  isSaving = false;
  firstDayOfYear = moment().startOf('year');
  lastDayOfYear = moment().endOf('year');

  // Rate Input
  readonly displayViews: { id: number; display: string }[] = [
    { id: 0, display: '%' },
    { id: 1, display: '€' },
  ];

  public selectedView = this.displayViews[0].id;

  // Display Conditions
  @ViewChild('dynamicConditionComponent', { read: ViewContainerRef, static: false })
  container!: ViewContainerRef;
  conditionComponentList: any[] = [];

  // Catch Value

  // Specify the `disabled` property at control creation time:
  negotiationForm: FormGroup;
  negotiationTypeFormControl = new FormControl(null, [Validators.required]);
  rateFormControl = new FormControl(null, [Validators.required, this.maxRateValidator(100)]);

  componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private categoryTypeService: CategoryTypeService,
    private negotiationTypeService: NegotiationTypeService,
    private contractNatioNegoService: ContractNatioNegoService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<NatioNegoManagingDialogComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      contract: Contract;
      contractItem: ContractItem;
      negotiationData: ContractNatioNego;
      isDuplication: boolean;
    }
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getCategoryTypeList();
    this.getNegotiationTypeList();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  private initForm() {
    this.negotiationForm = this.fb.group({
      startDate: [this.firstDayOfYear || null, [Validators.required]],
      endDate: [this.lastDayOfYear || null, [Validators.required]],
    });
  }

  // Validation personnalisée pour la valeur maximale
  maxRateValidator(max: number) {
    return (control: FormControl) => {
      const value = control.value;
      if (value && value > max && this.selectedView === 0) {
        return { maxRateExceeded: true }; // Retourne une erreur si la valeur est supérieure au max
      }
      return null; // Pas d'erreur si la validation est réussie
    };
  }

  // Re-Open negotiation
  private initDialog() {
    if (this.data.negotiationData && !this.isNegotiationTypeLoading && this.negotiationTypeList.length > 0) {
      // NegotiationType
      const negotiationType = this.negotiationTypeList.find(item => item.id === this.data.negotiationData.negotiationType.id);

      if (negotiationType) {
        this.negotiationTypeFormControl.setValue(
          this.displayNegotiationType(new FilteredNegociationType({ id: negotiationType.id, name: negotiationType.name }))
        );
      }

      // Date
      this.negotiationForm.get('startDate').setValue(this.data.negotiationData.startDate);
      this.negotiationForm.get('endDate').setValue(this.data.negotiationData.endDate);

      // Rate
      if (this.data.negotiationData.rate) {
        this.rateFormControl.setValue(this.data.negotiationData.rate);
        this.selectedView = 0;
      } else {
        this.rateFormControl.setValue(this.data.negotiationData.value);
        this.selectedView = 1;
      }

      // Conditions
      this.data.negotiationData.groupedConditions.map(group => {
        const label = group[0].isInclude ? 'Inclus' : 'Exclus';
        this.createDynamicConditionComponent(group[0].isInclude, label, group);
      });
    }
  }

  public getNegotiationTypeList() {
    this.isNegotiationTypeLoading = true;

    this.negotiationTypeService
      .getNegotiationTypeList({ year: this.firstDayOfYear.format('YYYY') })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        negotiationTypeList => {
          this.isNegotiationTypeLoading = false;
          this.negotiationTypeList = negotiationTypeList;

          setTimeout(() => {
            this.initDialog();
            this.initFilteredNegotiationTypeList();
          }, 700);
        },
        error => {
          this.isNegotiationTypeLoading = false;
        }
      );
  }

  private initFilteredNegotiationTypeList() {
    if (!this.isNegotiationTypeLoading) {
      this.filteredNegotiationTypeList = this.negotiationTypeFormControl.valueChanges.pipe(
        takeUntil(this.componentDestroyed$),
        startWith(''),
        map(value => this._getLabel(value)),
        map(label => (label ? this._filter(label) : this.negotiationTypeList.slice()))
      );
    }
  }

  /**
   *  Fonction pour gérer les cas de sélection d'option ou de chaîne tapée
   * @param value
   * @private
   */
  private _getLabel(value: string | NegotiationType): string {
    return typeof value === 'string' ? value : value?.name || '';
  }

  /***
   * Fonction pour afficher le label dans l'input lors de la sélection
   * @param value
   * @private
   */
  private _filter(value: string = ''): NegotiationType[] {
    const filterValue = value.toLowerCase();
    return this.negotiationTypeList.filter(
      negotiationType => negotiationType.id.toLowerCase().includes(filterValue) || negotiationType.name.toLowerCase().includes(filterValue)
    );
  }

  public displayNegotiationType(negotiationType) {
    return negotiationType?.name && negotiationType?.id ? `${negotiationType.name} (${negotiationType.id})` : negotiationType;
  }

  // Rate

  selectedDisplayView(view): void {
    this.selectedView = view.id;
  }

  // Conditions

  public getCategoryTypeList() {
    this.isCategoryTypeLoading = true;

    this.categoryTypeService
      .getCategoryTypeList({})
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        categoryType => {
          this.categoryTypeList = categoryType;
          this.isCategoryTypeLoading = false;
        },
        error => {
          this.isCategoryTypeLoading = false;
        }
      );
  }

  createDynamicConditionComponent(conditionTypeValue: number, conditionTypeLabel: string, groupedConditions = null) {
    if (this.container) {
      const conditionComponent = this.container.createComponent(NatioNegoConditionDynamicComponent);
      conditionComponent.instance.groupId = this.conditionComponentList.length + 1;
      conditionComponent.instance.contract = this.data.contract;
      conditionComponent.instance.conditionType = { value: conditionTypeValue, label: conditionTypeLabel };
      conditionComponent.instance.categoryTypeList = this.categoryTypeList;
      conditionComponent.instance.groupedConditions = groupedConditions; // not null only for edit/duplicate a nego
      conditionComponent.instance.isDuplication = this.data.isDuplication; // not null only for duplicate a nego

      this.conditionComponentList.push(conditionComponent);

      // Souscrire à l'EventEmitter du composant enfant
      conditionComponent.instance.messageEvent.pipe(takeUntil(this.componentDestroyed$)).subscribe(event => {
        if (event === 'remove') {
          this.removeDynamicConditionComponent(conditionComponent);
        }
      });
    }
  }

  removeDynamicConditionComponent(component) {
    const index = this.conditionComponentList.indexOf(component);

    if (index !== -1) {
      this.conditionComponentList[index].destroy();
      this.conditionComponentList.splice(index, 1);
    }
  }

  // Save

  getNegotiationDate() {
    let formattedStartDate = '';
    let formattedEndDate = '';

    if (typeof this.negotiationForm.get('startDate').value !== 'string') {
      formattedStartDate = moment(this.negotiationForm.get('startDate').value).format('YYYY-MM-DD');
      formattedEndDate = moment(this.negotiationForm.get('endDate').value).format('YYYY-MM-DD');
    } else {
      let [day, month, year] = this.negotiationForm.get('startDate').value.split('/');
      formattedStartDate = `${year}-${month}-${day}`;
      [day, month, year] = this.negotiationForm.get('endDate').value.split('/');
      formattedEndDate = `${year}-${month}-${day}`;
    }

    return {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
  }

  getValuesToSave() {
    const negotiationTypeFormControlValue = this.negotiationTypeFormControl.value;

    const negotiationTypeValue =
      typeof negotiationTypeFormControlValue !== 'string'
        ? this.negotiationTypeList.find(item => negotiationTypeFormControlValue.id.includes(item.id))
        : this.negotiationTypeList.find(item => negotiationTypeFormControlValue === item.name + ' (' + item.id + ')');

    const negotiationDate = this.getNegotiationDate();

    if (this.negotiationForm?.valid && this.negotiationTypeFormControl?.valid && this.rateFormControl?.valid) {
      return new ContractNatioNego({
        id: (!this.data.isDuplication ? this.data.negotiationData?.id : null) || null,
        contractItemId: this.data.contractItem?.id || null,
        negotiationType: negotiationTypeValue,
        startDate: negotiationDate.startDate || '',
        endDate: negotiationDate.endDate || '',
        rate: this.selectedView === 0 ? this.rateFormControl.value : null,
        value: this.selectedView === 1 ? this.rateFormControl.value : null,
        groupedConditions: this.conditionComponentList.map(conditionComponent => {
          if (conditionComponent.instance.categoryTypeFormControl.valid && conditionComponent.instance.searchFormControl.valid) {
            return conditionComponent.instance.getConditionList();
          }
        }),
      });
    } else {
      this.displaySnackBar('Veuillez remplir ou corriger le formulaire.', 'tv-chip-error');
    }
  }

  onValidationButtonClick() {
    const negotiationValues = this.getValuesToSave();

    this.isSaving = true;

    if (negotiationValues) {
      if (negotiationValues && negotiationValues.id) {
        // UDPATE
        this.contractNatioNegoService.edit(negotiationValues, negotiationValues.id.toString()).subscribe(
          result => {
            this.isSaving = false;
            this.displaySnackBar('Enregistrement terminé.', 'tv-chip-success');
            this.reloadPage();
          },
          error => {
            this.isSaving = false;
          }
        );
      } else {
        // CREATE
        this.contractNatioNegoService.create(negotiationValues).subscribe(
          result => {
            this.isSaving = false;
            this.displaySnackBar('Enregistrement terminé.', 'tv-chip-success');
            this.reloadPage();
          },
          error => {
            this.isSaving = false;
          }
        );
      }
    }
  }

  public displaySnackBar(message: string, panelClass: string) {
    this.snackBar.open(message, null, {
      duration: 4000,
      verticalPosition: 'top',
      panelClass: [panelClass],
    });
  }

  reloadPage() {
    location.reload();
  }

  onCancelUpdateButtonClick() {
    this.snackBar.open("L'ajout / modification a été annulée.", null, {
      duration: 4000,
      verticalPosition: 'top',
      panelClass: ['tv-chip-error'],
    });
    this.dialogRef.close();
  }
}
