export interface JsonFilteredProduct {
  id: number;
  name: string;
}

export class FilteredProduct {
  id: number;
  name: string;
  productName: string;
  productId: string;

  constructor(jsonFilteredProduct: JsonFilteredProduct) {
    if (jsonFilteredProduct.id) {
      this.id = Number(jsonFilteredProduct.id);
    }

    if (jsonFilteredProduct.name) {
      this.name = jsonFilteredProduct.name;
    }
  }
}

export interface JsonFilteredCampaign {
  id: number;
  cpNum: number;
  cpYear: number;
  mainTarget?: string;
}

export class FilteredCampaign {
  id: number;
  cpNum: number;
  cpYear: number;
  mainTarget: string;

  constructor(jsonFilteredCampaign: JsonFilteredCampaign) {
    if (jsonFilteredCampaign.id) {
      this.id = Number(jsonFilteredCampaign.id);
    }

    if (jsonFilteredCampaign.cpNum) {
      this.cpNum = Number(jsonFilteredCampaign.cpNum);
    }

    if (jsonFilteredCampaign.cpYear) {
      this.cpYear = Number(jsonFilteredCampaign.cpYear);
    }

    if (jsonFilteredCampaign.mainTarget) {
      this.mainTarget = jsonFilteredCampaign.mainTarget;
    }
  }
}

export interface JsonFilteredChannel {
  id: number;
  name: string;
  areaId: string;
}

export class FilteredChannel {
  id: number;
  name: string;
  areaId: string;
  visibility = false;

  constructor(jsonFilteredChannel: JsonFilteredChannel) {
    if (jsonFilteredChannel.id) {
      this.id = Number(jsonFilteredChannel.id);
    }

    if (jsonFilteredChannel.name) {
      this.name = jsonFilteredChannel.name;
    }

    if (jsonFilteredChannel.areaId) {
      this.areaId = jsonFilteredChannel.areaId;
    }
  }
}

export interface JsonFilteredTarget {
  id: string;
  name?: string;
}

export class FilteredTarget {
  id: string;
  name?: string;

  constructor(jsonFilteredTarget: JsonFilteredTarget) {
    if (jsonFilteredTarget.id) {
      this.id = jsonFilteredTarget.id;
    }

    if (jsonFilteredTarget.name) {
      this.name = jsonFilteredTarget.name;
    }
  }
}

export interface JsonFilteredPlan {
  id: number;
  name: string;
}

export class FilteredPlan {
  id: number;
  name: string;

  constructor(jsonFilteredPlan: JsonFilteredPlan) {
    if (jsonFilteredPlan.id) {
      this.id = jsonFilteredPlan.id;
    }

    if (jsonFilteredPlan.name) {
      this.name = jsonFilteredPlan.name;
    }
  }
}
