import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TvChannel, TvChannelGroup } from '@core/models/tv-channel.model';
import { AuthService } from '@core/services/auth.service';
import { ChannelGroups, TvChannelService } from '@core/services/tv-channel/tv-channel.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-channel-select',
  templateUrl: './channel-select.component.html',
  styleUrls: ['./channel-select.component.scss'],
})
export class ChannelSelectComponent implements OnInit, OnDestroy {
  public showDropDown = false;
  public showChannelGroupSelectDropDown = false;
  public showClearButton = false;
  public currentChannel$: BehaviorSubject<TvChannel>;
  public channelGroupButtonTitle;

  private userInfoSubscription: Subscription;
  private channelSearchControlSubscription: Subscription;
  private userId = '';

  public channels$: Observable<TvChannel[]>;
  public filter;

  @ViewChild('channelInput', { static: true }) channelInput;

  public channelSearchControl = new FormControl();

  public channelGroups: TvChannelGroup[] = [];

  constructor(private eRef: ElementRef, private tvChannelService: TvChannelService, private authService: AuthService) {}

  ngOnInit(): void {
    this.getTvChannelGroups();
    this.handleChannelSearchControlSubscription();
  }

  ngOnDestroy(): void {
    if (this.userInfoSubscription) {
      this.userInfoSubscription.unsubscribe();
    }
    if (this.channelSearchControlSubscription) {
      this.channelSearchControlSubscription.unsubscribe();
    }
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement): void {
    const clickedInside = this.eRef.nativeElement.contains(targetElement);

    if (!clickedInside) {
      this.showDropDown = !!this.eRef.nativeElement.contains(targetElement);
    }
  }

  onShowDropDownButtonClick(): void {
    this.showDropDown = !this.showDropDown;
  }

  onShowChannelGroupSelectDownButtonClick(): void {
    this.showChannelGroupSelectDropDown = !this.showChannelGroupSelectDropDown;
  }

  clearChannelSearchInput(): void {
    this.channelSearchControl.setValue('');
    this.showClearButton = false;
  }

  onGroupSelectOptionClick(option: TvChannelGroup): void {
    this.channelGroupButtonTitle = option.name;
    this.showChannelGroupSelectDropDown = false;

    if (option.id === ChannelGroups[0].id || option.id === ChannelGroups[1].id) {
      this.getTvChannels(option.name, this.userId);
    } else {
      this.getTvChannels(option.name, this.userId, option.channelGroup, option.isNat);
    }

    this.channelSearchControl.setValue('');
  }

  getTvChannels(group: string, userId, groupChannelId?, isNat?): void {
    this.channels$ = this.tvChannelService.getTvChannels(group, userId, groupChannelId, isNat);
  }

  onToggleFavoriteChannelClick(channel: TvChannel): void {
    channel.isFavorite = !channel.isFavorite;
    this.tvChannelService.updateFavoriteChannelList(this.userId, channel).subscribe();
    if (this.channelGroupButtonTitle === this.tvChannelService.favoriteChannelGroup.name) {
      setTimeout(() => {
        this.getTvChannels(this.channelGroupButtonTitle, this.userId);
      }, 700);
    }
  }

  onChooseChannelClick(channel: TvChannel): void {
    this.tvChannelService.setCurrentChannel(channel);
    setTimeout(_ => {
      this.showDropDown = false;
      this.showChannelGroupSelectDropDown = false;
    });
  }

  handleChannelSearchControlSubscription(): void {
    this.channelSearchControlSubscription = this.channelSearchControl.valueChanges.subscribe(value => {
      this.showClearButton = !!value;
      this.filter = value;
    });
  }

  getTvChannelGroups(): void {
    this.tvChannelService.getChannelGroups().subscribe(
      channelGroups => {
        this.channelGroups = channelGroups;
        this.prepareSetUp();
      },
      () => {
        this.channelGroups = ChannelGroups;
        this.prepareSetUp();
      }
    );
  }

  prepareSetUp(): void {
    /* Set default channel group */
    this.channelGroupButtonTitle = this.tvChannelService.favoriteChannelGroup.name;

    this.getFavoriteChannels();
    this.currentChannel$ = this.tvChannelService.currentChannel$;
  }

  getFavoriteChannels(): void {
    /* Get favorites channels */
    this.userInfoSubscription = this.authService.getUserInfo().subscribe(info => {
      this.userId = info.sub;
      this.getTvChannels(this.tvChannelService.favoriteChannelGroup.name, info.sub);
    });
  }
}
