import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CroiseTvDigitalService {
  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  public sendMailMediametrie() {
    const url =
      environment.api_base_url + "/tv/send-mediametrie-data-file-mail";
    return Observable.create((observer) => {
      this.httpService.get(url).subscribe(
        (response) => observer.next(response),
        (error) => observer.error(this.catchError(error))
      );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 504:
        this.snackBar.open(
          "Envoi du mail en cours. Veuillez patienter.",
          null,
          { duration: 2000, verticalPosition: "top" }
        );
        break;
      default:
        this.snackBar.open("Une erreur est survenue", null, {
          duration: 2000,
          verticalPosition: "top",
        });
        break;
    }
    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
