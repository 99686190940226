export interface JsonEmplacement {
  id?: number;
  bactif?: boolean;
}

export class Emplacement {
  public id: number;
  public bactif: boolean;

  constructor(jsonEmplacement: JsonEmplacement = null) {
    this.id = jsonEmplacement.id;
    this.bactif = jsonEmplacement.bactif;
  }
}
