import { Injectable, Injector } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import * as moment from "moment";
import { ScreenFilter } from "@core/models/screen.model";

@Injectable({
  providedIn: "root",
})
export class ScreenService {
  private routeByView = "/tv/screen-view";

  constructor(private httpService: HttpClient, private router: Router) {}

  public getScreens(filter: ScreenFilter) {
    let url = environment.api_base_url + this.routeByView;
    const params = new URLSearchParams();
    for (const key in filter) {
      params.set(key, filter[key]);
    }

    url += "?" + params.toString();

    return Observable.create((observer) => {
      this.httpService.get(url).subscribe(
        (response) => {
          let rs;
          rs = this.calculateScreen(
            response["_embedded"]["ScreenView"],
            filter
          );
          observer.next(rs);
        },
        (error) => observer.error(error)
      );
    });
  }

  /**
   * @param data any
   * @param filter ScreenFilter
   */
  private calculateScreen(data: any, filter: ScreenFilter) {
    const screens = {};

    for (let i = 0; i < data.length; i++) {
      const nbTick = data[i]["herEcran"].trim();

      if (!screens.hasOwnProperty(data[i]["herEcran"])) {
        screens[nbTick] = {};
      }

      if (data[i]["datEcran"]) {
        data[i]["datEcran"] = moment(
          data[i]["datEcran"],
          "YYYY-MM-DD HH:mm:ss"
        );
      }

      screens[nbTick][data[i]["datEcran"].format("YYYY-MM-DD")] = data[i];
    }

    const dayArr = [];
    let dayFrom = moment(filter.dateFrom, "YYYY-MM-DD");
    const dayTo = moment(filter.dateTo, "YYYY-MM-DD");

    while (dayFrom <= dayTo) {
      dayArr.push({
        dateLabel: dayFrom.format("DD/MM"),
        shortL: dayFrom.format("dd"),
        dayOfYear: dayFrom.dayOfYear(),
        day: dayFrom.format("YYYY-MM-DD"),
      });
      dayFrom = moment(dayFrom).add(1, "days");
    }

    return [dayArr, screens];
  }

  private getAliasDayOfWeek(date): string {
    switch (date.isoWeekday()) {
      case 1:
        return "LU";
      case 2:
        return "MA";
      case 3:
        return "ME";
      case 4:
        return "JE";
      case 5:
        return "VE";
      case 6:
        return "SA";
      case 7:
        return "DI";
    }
  }

  private getSeconds(str): number {
    return moment.duration(str + ":00").asSeconds();
  }

  private getDaysOfYear(dateStr): number {
    return moment(dateStr, "YYYY-MM-DD").dayOfYear();
  }

  public getScreen(filter: ScreenFilter) {
    let url = environment.api_base_url + this.routeByView;
    const params = new URLSearchParams();
    for (const key in filter) {
      params.set(key, filter[key]);
    }

    url += "?" + params.toString();

    return Observable.create((observer) => {
      this.httpService.get(url).subscribe(
        (response) => {
          let rs;
          rs = this.calculateScreen(
            response["_embedded"]["ScreenView"],
            filter
          );
          observer.next(rs);
        },
        (error) => observer.error(error)
      );
    });
  }
}
