import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Position } from "../models/position.model";
import { Observable } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class PositionService {
  private positionRoute = "/tv/tv-param-pos-substituable";

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  public getPositions(): Observable<any> {
    const url = environment.api_base_url + this.positionRoute;
    return this.httpService.get(url).pipe(
      map((jsonResponse) => {
        return jsonResponse["_embedded"]["TvParamPosSubstituable"].map(
          (json) => new Position(json)
        );
      })
    );
  }

  public updatePositions(data): Observable<any> {
    const url = environment.api_base_url + this.positionRoute;
    return Observable.create((observer) => {
      this.httpService.put(url, [data]).subscribe(
        (response) => {
          this.snackBar.open("Enregistrement effectué", null, {
            duration: 2000,
            verticalPosition: "top",
          });

          observer.next(true);
        },
        (error) => {
          this.catchError(error);
          observer.error(error);
        }
      );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      default:
        this.snackBar.open(
          error.error.detail ? error.error.detail : "Une erreur est survenue",
          null,
          { duration: 2000, verticalPosition: "top" }
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
