import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "@env/environment";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Emplacement } from "@core/models/emplacement.model";

@Injectable({
  providedIn: "root",
})
export class EmplacementService {
  private emplacementRoute = "/tv/tv-param-emplac-substituable";

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  public getEmplacements(): Observable<any> {
    const url = environment.api_base_url + this.emplacementRoute;
    return this.httpService.get(url).pipe(
      map((jsonResponse) => {
        return jsonResponse["_embedded"]["TvParamEmplacSubstituable"].map(
          (json) => new Emplacement(json)
        );
      })
    );
  }

  public updateEmplacements(data): Observable<any> {
    const url = environment.api_base_url + this.emplacementRoute;
    return Observable.create((observer) => {
      this.httpService.put(url, [data]).subscribe(
        (response) => {
          this.snackBar.open("Enregistrement effectué", null, {
            duration: 2000,
            verticalPosition: "top",
          });

          observer.next(true);
        },
        (error) => {
          this.catchError(error);
          observer.error(error);
        }
      );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      default:
        this.snackBar.open(
          error.error.detail ? error.error.detail : "Une erreur est survenue",
          null,
          { duration: 2000, verticalPosition: "top" }
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
