import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-screen-spots',
  templateUrl: './screen-spots.component.html',
  styleUrls: ['./screen-spots.component.scss'],
})
export class ScreenSpotsComponent implements OnInit {
  @Input() spotsList;

  displayedColumns: string[] = [
    'reserve',
    'screenDate',
    'product',
    'productType',
    'campaign',
    'advertiser',
    'durspot',
    'emplaceCode',
    'caInit',
    'caNet',
    'priceSector',
    'abatt',
    'utPlanning',
  ];

  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('reserve-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/r-icon.svg'));
  }

  ngOnInit(): void {}
}
