import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CategoryType } from '@core/models/category-type.model';
import { ContractNatioNegoCondition } from '@core/models/contract-natio-nego-condition.model';
import { Contract } from '@core/models/contract.model';
import { FilteredItem } from '@core/models/filtered-item.model';
import { NegotiationType } from '@core/models/negotiation-type.model';
import { ContractCategoryTypeService } from '@core/services/contract-category-type/contract-category-type.service';
import { TvSelectItemsDialogComponent } from '@shared/dialog/tv-select-items-dialog/tv-select-items-dialog.component';
import { Observable, of, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-natio-nego-condition-dynamic',
  templateUrl: './natio-nego-condition-dynamic.component.html',
  styleUrls: ['./natio-nego-condition-dynamic.component.scss'],
})
export class NatioNegoConditionDynamicComponent implements OnInit {
  public isItemLoading: boolean = false;
  public categoryTypeFormControl = new FormControl(null);
  public searchFormControl = new FormControl(null);

  @Input() groupId: number;
  @Input() contract: Contract;
  @Input() conditionType?: { value: number; label: string };
  @Input() categoryTypeList?: CategoryType[] = [];
  @Input() groupedConditions: ContractNatioNegoCondition[];
  @Input() isDuplication: boolean = false;

  public itemList: any[] = [];
  public filteredItemList: Observable<any[]>;
  public chosenItemList: any[] = [];

  @Output() messageEvent = new EventEmitter<string>();

  componentDestroyed$: Subject<void> = new Subject();

  constructor(public contractCategoryTypeService: ContractCategoryTypeService, private snackBar: MatSnackBar, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.handleCategoryTypeValueChange();
    this.handleItemValueChange();
    this.initDialog(); // load values for edit
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  initDialog() {
    if (this.groupedConditions && this.groupedConditions.length > 0) {
      this.groupedConditions.forEach(condition => {
        this.chosenItemList.push({
          id: condition.id,
          itemType: new CategoryType(condition.categoryType),
          item: { id: condition.categoryValue?.idCategoryValue, name: condition.categoryValue?.labelCategoryValue },
        });
      });
    }
  }

  public initFilteredItemList() {
    if (!this.isItemLoading) {
      this.filteredItemList = this.searchFormControl.valueChanges.pipe(
        takeUntil(this.componentDestroyed$),
        startWith(''),
        map(value => this._getLabel(value)),
        map(label => (label ? this._filter(label) : this.itemList.slice()))
      );
    }
  }

  /**
   *  Fonction pour gérer les cas de sélection d'option ou de chaîne tapée
   * @param value
   * @private
   */
  private _getLabel(value: string | NegotiationType): string {
    return typeof value === 'string' ? value : value?.name || '';
  }

  /***
   * Fonction pour afficher le label dans l'input lors de la sélection
   * @param value
   * @private
   */
  private _filter(value: string = ''): any[] {
    const filterValue = value.toLowerCase();
    return this.itemList.filter(item => item.id?.toString().includes(filterValue) || item.name?.toLowerCase().includes(filterValue));
  }

  public displayItem(item: FilteredItem): string {
    return item?.name && item?.id ? `${item.name} (${item.id})` : '';
  }

  private handleCategoryTypeValueChange() {
    this.categoryTypeFormControl.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(value => {
      this.searchFormControl.setValue('');
      this.getCategoryItemList(value);
    });
  }

  public getParamsByCategoryType(categoryType) {
    switch (categoryType.id) {
      case 1: // Advertiser
      case 4: // Product
        return { advertiserGroupId: this.contract.advertiserId };
      case 6: // Campaign
        return { advertiserGroupId: this.contract.advertiserId, year: this.contract.cgvContractYear };
      case 3: // Secodip
        return { year: this.contract.cgvContractYear };
      case 2: // Mandatory / Agency
      case 5: // Product Type
      case 7: // Target
      case 8: // Channel
      case 9: // Screen Type
      case 10: // Screen
      case 11: // Time Slot
      default:
        return {};
    }
  }

  public getCategoryItemList(categoryType) {
    let params = this.getParamsByCategoryType(categoryType);

    this.contractCategoryTypeService
      .getItemList(params, categoryType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        items => {
          this.isItemLoading = false;
          this.initFilteredItemList();
          this.itemList = items;
        },
        error => {
          this.isItemLoading = false;
        }
      );
  }

  private handleItemValueChange() {
    this.searchFormControl.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(value => {
      if (typeof value !== 'string') {
        if (!this.chosenItemList.find(chosenItem => chosenItem.item.id === value.id && chosenItem.item.name === value.name)) {
          this.chosenItemList.push({
            itemType: this.categoryTypeFormControl.value,
            item: value,
          });
        } else {
          this.snackBar.open('Cet item est déjà sélectionné.', null, {
            duration: 4000,
            verticalPosition: 'top',
            panelClass: ['tv-chip-error'],
          });
        }
      }
    });
  }

  public isFilteredItemListFill() {
    return !this.filteredItemList;
  }

  public listAllOptions(): void {
    const dialogRef = this.dialog.open(TvSelectItemsDialogComponent, {
      data: {
        itemList: of(this.itemList),
      },
      panelClass: 'tv-ns-dialog-container',
    });

    dialogRef.afterClosed().subscribe(items => {
      if (items && items.size > 0) {
        items.forEach(item => {
          if (!this.chosenItemList.find(chosenItem => chosenItem.item.id === item.id && chosenItem.item.name === item.name)) {
            this.chosenItemList.push({
              id: null,
              item: item,
              itemType: this.categoryTypeFormControl.value,
            });
          } else {
            this.snackBar.open('Certain item sont déjà sélectionnés.', null, {
              duration: 4000,
              verticalPosition: 'top',
              panelClass: ['tv-chip-error'],
            });
          }
        });
      }
    });
  }

  removeItem(chip: string): void {
    const index = this.chosenItemList.indexOf(chip);
    if (index >= 0) {
      this.chosenItemList.splice(index, 1);
    }
  }

  removeCondition(): void {
    this.messageEvent.emit('remove');
  }

  public getCategoryTypeToDisplay(categoryType) {
    return categoryType?.name.substring(0, 2);
  }

  public getConditionList() {
    return this.chosenItemList.map(chosenItem => {
      return new ContractNatioNegoCondition({
        id: !this.isDuplication ? chosenItem.id : null,
        groupId: this.groupId,
        isInclude: this.conditionType.value,
        categoryType: chosenItem.itemType,
        categoryValue: {
          idCategoryValue: chosenItem.item.id,
          labelCategoryValue: chosenItem.item.name,
        },
      });
    });
  }
}
