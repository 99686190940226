import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ContractCgrpOff } from '@core/models/contract-cgrp-off.model';
import { ContractItem } from '@core/models/contract-item.model';
import { ContractNatioNego } from '@core/models/contract-natio-nego.model';
import { Contract } from '@core/models/contract.model';
import { ContractCgrpOffService } from '@core/services/contract-cgrp-off/contract-cgrp-off.service';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CGRPOffManagingDialog } from './cgrp-off-managing-dialog/cgrp-off-managing-dialog.component';

@Component({
  selector: 'app-contract-cgrp-off',
  templateUrl: './contract-cgrp-off.component.html',
  styleUrls: ['./contract-cgrp-off.component.scss'],
})
export class ContractCgrpOffComponent implements OnInit {
  @Input() contract: Contract;
  @Input() contractItem: ContractItem;
  @Input() natioNegotiationList: ContractNatioNego[];
  itemsFilterForm: FormGroup;
  componentDestroyed$: Subject<void> = new Subject();
  panelOpened = false;
  isContractOffLoading = false;
  contractOffList: ContractCgrpOff[] = [];
  searchFormControl = new FormControl(null);
  filter = {};

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public contractCgrpOffService: ContractCgrpOffService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.itemsFilterForm = this.fb.group({
      startDate: [null],
      endDate: [null],
      search: [null],
    });

    iconRegistry.addSvgIcon('content-copy', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/content-copy.svg'));
    iconRegistry.addSvgIcon('edit', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg'));
    iconRegistry.addSvgIcon('delete-bin', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete-bin.svg'));
    iconRegistry.addSvgIcon('add-circle-fill', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add-circle-fill.svg'));
    iconRegistry.addSvgIcon('delete-circle-fill', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete-circle-fill.svg'));
  }

  ngOnInit(): void {
    this.initForm();
    // this.getContractOffList();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  public initForm(): void {
    this.itemsFilterForm.addControl('startDate', this.fb.control(null, [Validators.required]));
    this.itemsFilterForm.addControl('endDate', this.fb.control(null, [Validators.required]));
    this.itemsFilterForm.addControl('search', this.fb.control(null, [Validators.required]));
  }

  public searchContractOffList() {
    console.log(this.itemsFilterForm);
    this.filter = {
      startDate: this.itemsFilterForm.get('startDate').value ?? '',
      endDate: this.itemsFilterForm.get('endDate').value ?? '',
      name: this.searchFormControl.value ?? '',
    };

    this.getContractOffList(false);
  }

  private getContractOffList(isReload = false) {
    this.isContractOffLoading = !isReload;
    this.filter = Object.assign(this.filter, {
      clientId: this.contract ? this.contract.id : '',
      stateContractYear: this.contract ? this.contract.stateContractYear : '',
    });

    this.contractCgrpOffService
      .getList(this.filter)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        offList => {
          this.isContractOffLoading = false;
          this.contractOffList = offList.items;
          console.log('contractOffList: ', this.contractOffList);
        },
        error => {
          this.isContractOffLoading = false;
        }
      );
  }

  public valueChange(): void {
    let todayDate = new Date();

    merge(this.itemsFilterForm.get('startDate').valueChanges, this.itemsFilterForm.get('endDate').valueChanges)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        () => {
          // TODO
        },
        (error: Error) => {
          console.error(error);
        }
      );
  }

  public openCGRPOffModal(cgrpOff = null): void {
    console.log('openCGRPOffModal: ', this.contract, cgrpOff);
    const dialogRef = this.dialog.open(CGRPOffManagingDialog, {
      // width: '1000px',
      data: { contract: this.contract, cgrpOffData: cgrpOff },
    });

    dialogRef.afterClosed().subscribe(result => {
      /*
      this.snackBar.open("L'ajout / modification a été annulée.", null, {
        duration: 4000,
        verticalPosition: 'top',
        panelClass: ['tv-ns-dialog-container'],
      });
      */
      this.getContractOffList(true);
    });
  }

  public editCGRPOff(cgrpOff): void {
    const dialogRef = this.dialog.open(CGRPOffManagingDialog, {
      width: '1000px',
      data: { cgrpOff: cgrpOff },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getContractOffList(true);
    });
  }

  // functions for css animations
  onPanelOpened() {
    this.panelOpened = true;
  }

  onPanelClosed() {
    this.panelOpened = false;
  }

  public duplicateItem(item) {
    console.log('duplicateItem : ', item);
    this.contractCgrpOffService.duplicate(item.id).subscribe(
      rs => {
        this.snackBar.open('La duplication a été validée.', null, {
          duration: 4000,
          verticalPosition: 'top',
          panelClass: ['tv-ns-dialog-container'],
        });
        this.getContractOffList(true);
      },
      error => {
        this.snackBar.open('La duplication a été échouée.', null, {
          duration: 4000,
          verticalPosition: 'top',
          panelClass: ['tv-ns-dialog-container'],
        });
      }
    );
  }

  public deleteCgrpOff(item) {
    console.log('delete : ', item);
    this.contractCgrpOffService.delete(item.id).subscribe(
      rs => {
        this.snackBar.open('La suppression a été validée.', null, {
          duration: 4000,
          verticalPosition: 'top',
          panelClass: ['tv-ns-dialog-container'],
        });
        this.getContractOffList(true);
      },
      error => {
        this.snackBar.open('La suppression a été échouée.', null, {
          duration: 4000,
          verticalPosition: 'top',
          panelClass: ['tv-ns-dialog-container'],
        });
      }
    );
  }
}
