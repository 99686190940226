import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilteredItem } from '@core/models/filtered-item.model';
import { Observable, Subject } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-tv-select-items-dialog',
  templateUrl: './tv-select-items-dialog.component.html',
  styleUrls: ['./tv-select-items-dialog.component.scss'],
})
export class TvSelectItemsDialogComponent implements OnInit {
  public panelOpened = false;

  public selectAllOptions = false;
  public selectedOptions: Set<any> = new Set();
  public filteredOptions: Observable<any[]>;
  public optionFormControl = new FormControl(null);

  // public searchForm: FormGroup;
  public searchForm = new FormControl(null);
  public itemList = [];
  public filteredItemList$: Observable<any[]>;

  public chosenItemList: any[];
  public isLoadingItemList: boolean = false;

  componentDestroyed$: Subject<void> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<TvSelectItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { itemList: Observable<any[]> }
  ) {}

  ngOnInit(): void {
    this.initFilteredItemList();
    this.initFilteredOptions();
  }

  initFilteredItemList() {
    // Filtrage des éléments en fonction de la recherche
    this.filteredItemList$ = this.searchForm.valueChanges.pipe(
      startWith(''), // Démarrer avec une chaîne vide
      switchMap(searchTerm => this.filterList(searchTerm))
    );
  }

  private initFilteredOptions() {
    this.filteredOptions = this.data.itemList;
  }

  // Méthode qui retourne un Observable filtré
  filterList(searchTerm: string): Observable<any[]> {
    const lowerCaseTerm = searchTerm.toLowerCase();

    // Filtrer la liste en fonction du terme de recherche
    return this.data.itemList.pipe(
      map(items => items.filter(item => item.id?.toString().includes(lowerCaseTerm) || item.name.toLowerCase().includes(lowerCaseTerm)))
    );
  }

  onCancelSelectClick() {
    this.selectedOptions.clear();
    this.dialogRef.close();
  }

  onSaveChosenItemList() {
    this.dialogRef.close(this.selectedOptions);
  }

  onSelectAllChange(event: any, item: any = null) {
    this.selectAllOptions = event.checked;
    this.filteredOptions?.subscribe(options => {
      options.forEach(item => {
        if (this.selectAllOptions) {
          this.selectedOptions.add(item);
        } else if (!this.selectAllOptions) {
          this.selectedOptions.delete(item);
        }
      });
    });
  }

  isOptionSelected(item) {
    return this.selectedOptions.has(item);
  }

  onOptionChange(event: any, item: any) {
    if (event.checked) {
      if (!this.selectedOptions.has(item)) {
        this.selectedOptions.add(item);
      }
    } else {
      this.selectedOptions.delete(item);
    }
  }

  public displayItem(item: FilteredItem): string {
    return item?.name && item?.id ? `${item.name} (${item.id})` : '';
  }
}
