export interface JsonSecodip {
  id?: string;
  name?: string;
}

export class Secodip {
  public id: string;
  public name: string;

  constructor(jsonSecodip: JsonSecodip = null) {
    this.id = jsonSecodip.id;
    this.name = jsonSecodip.name;
  }
}
