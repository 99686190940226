import { NbcId } from '@app/app.constants';

export interface JsonTvBatchUpdateFileType {
  id: number;
  name: string;
}

export class TvBatchUpdateFileType {
  id: number;
  name: string;
  constructor(jsonTvBatchUpdateFileType: JsonTvBatchUpdateFileType = null) {
    this.id = jsonTvBatchUpdateFileType.id;
    this.name = jsonTvBatchUpdateFileType.name;
  }
}

export interface JsonTvChannelGroup {
  id: number;
  channelGroup: string;
  isNat: boolean;
  name: string;
}

export class TvChannelGroup {
  id: number;
  channelGroup: string;
  isNat: boolean;
  name: string;

  constructor(jsonTvChannelGroup: JsonTvChannelGroup = null) {
    this.id = jsonTvChannelGroup.id;
    this.channelGroup = jsonTvChannelGroup.channelGroup;
    this.isNat = !!jsonTvChannelGroup.isNat;
    this.name = jsonTvChannelGroup.name;
  }
}

export interface JsonTvChannel {
  id: number;
  name: string;
  channelCode: string;
  regionCode: string;
  isRegion: boolean;
  logo: string;
  _embedded: {
    channelGroup: {
      numRegie: string;
      nomRegie: string;
    };
    editor: {
      editorId: string;
      editorName: string;
    };
    tvBatchUpdateFileTypes: JsonTvBatchUpdateFileType[];
  };
}

export class TvChannel {
  id: number;
  name: string;
  channelCode: string;
  regionCode: string;
  isRegion: boolean;
  logo: string;
  isFavorite = false;
  nomRegie: string = 'Pas de regie';
  editorName: string;
  isNbc: boolean = false;
  batchUpdateFileTypes: TvBatchUpdateFileType[] = [];

  constructor(jsonTvChannel: JsonTvChannel = null, isFavorite?) {
    this.id = jsonTvChannel.id;
    this.name = jsonTvChannel.name;
    this.channelCode = jsonTvChannel.channelCode;
    this.regionCode = jsonTvChannel.regionCode;
    this.isRegion = jsonTvChannel.isRegion;
    this.logo = jsonTvChannel.logo;
    this.isFavorite = !!isFavorite;
    this.nomRegie = jsonTvChannel?._embedded?.channelGroup?.nomRegie;
    this.editorName = jsonTvChannel?._embedded?.editor?.editorName;
    this.isNbc = jsonTvChannel._embedded?.editor?.editorId == NbcId;
    if (
      jsonTvChannel &&
      jsonTvChannel._embedded &&
      jsonTvChannel._embedded.tvBatchUpdateFileTypes &&
      jsonTvChannel._embedded.tvBatchUpdateFileTypes
    ) {
      this.batchUpdateFileTypes = jsonTvChannel._embedded.tvBatchUpdateFileTypes.map((fileTypeJson: JsonTvBatchUpdateFileType) => {
        return new TvBatchUpdateFileType(fileTypeJson);
      });
    }
  }
}
