import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BatchUpdateService } from '@core/services/batch-update/batch-update.service';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-tv-file-upload-popup',
  templateUrl: './tv-file-upload-popup.component.html',
  styleUrls: ['./tv-file-upload-popup.component.scss'],
})
export class TvFileUploadPopupComponent implements OnInit {
  public requiredFileType;
  public caller: any;
  public file: File = null;
  public channelCode = null;
  public loading = false;
  public title = 'Importer le fichier';
  public actionButtonTitle = 'Importer le fichier';

  //TODO: add progress
  public fileUploadProgress$ = new BehaviorSubject(0);
  public shouldEmptyFileUploadInput = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TvFileUploadPopupComponent>,
    protected batchUpdateService: BatchUpdateService,
    protected snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    if (this.data && this.data.title) {
      this.title = this.data.title;
    }
    if (this.data && this.data.requiredFileType) {
      this.requiredFileType = this.data.requiredFileType;
    }
    if (this.data && this.data.actionButtonTitle) {
      this.actionButtonTitle = this.data.actionButtonTitle;
    }
    if (this.data && this.data.caller) {
      this.caller = this.data.caller;
    }
    if (this.data && this.data.channelCode) {
      this.channelCode = this.data.channelCode;
    }
  }

  onUploadClick(file: File) {
    this.loading = true;
    this.batchUpdateService.upload(file).subscribe(fileName => {
      this.loading = false;
      this.file = file;
      this.caller.onUploadFinished(file);
    },
      error => {
        this.loading = false;
        this.file = null;
        this.snackBar.open("Une erreur est survenue lors l'envoi du fichier.",
          null, { duration: 2000, verticalPosition: 'top', panelClass: ['chip-error']});
      });
  }

  onImportClick() {
    this.loading = true;
    this.batchUpdateService
      .importNbcBreakFile(this.file.name, this.channelCode)
      .subscribe(fileName => {
        this.loading = false;
        this.caller.onImportFinished(this.file.name);
        this.file = null;
        this.onButtonClick(false);
      },
        error => {
          this.loading = false;
          this.file = null;
          if(error.status == 200) {
            this.snackBar.open('Le fichier a été importé avec succès.',
              null, { duration: 2000, verticalPosition: 'top', panelClass: ['tv-chip-success'] });
          } else {
            this.snackBar.open('Une erreur est survenue lors du traitement du fichier.',
              null, { duration: 2000, verticalPosition: 'top', panelClass: ['chip-error'] });
          }
        });
  }

  onButtonClick(confirmation: boolean) {
    this.dialogRef.close(confirmation);
  }
}
