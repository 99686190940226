export interface JsonContractComment {
  id: string;
  contractId: number;
  body: string;
  bactif: number;
  author: string;
  commentDate: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
}

export class ContractComment {
  id: string;
  contractId: number;
  body: string;
  bactif: number;
  author: string;
  commentDate: string | Date;

  constructor(jsonContractComment: JsonContractComment = null) {
    if (jsonContractComment) {
      this.id = jsonContractComment.id;
      this.contractId = jsonContractComment.contractId;
      this.body = jsonContractComment.body;
      this.bactif = jsonContractComment.bactif;
      this.author = jsonContractComment.author;
      if (jsonContractComment.commentDate && jsonContractComment.commentDate.date) {
        const formattedDate = jsonContractComment.commentDate.date.replace(' ', 'T') + 'Z';
        this.commentDate = new Date(formattedDate);
      } else {
        this.commentDate = new Date();
      }
    }
  }
}
