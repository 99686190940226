export interface JsonContractProduct {
  numProduit?: number;
  nomProduit?: string;
  idGroupeAnnonceur?: number;
}

export class ContractProduct {
  id: number;
  name: string;
  advertiserId: number;

  constructor(jsonContractProduct: JsonContractProduct = null) {
    this.id = jsonContractProduct.numProduit;
    this.name = jsonContractProduct.nomProduit;
    this.advertiserId = jsonContractProduct.idGroupeAnnonceur;
  }
}
