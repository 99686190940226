import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BatchUpdateStepName } from '@core/models/batch-update-status.model';
import {
  BatchUpdateValidationData,
  BatchUpdateValidationSummary,
  DayToValidate,
  JsonBathUpdateValidationData,
} from '@core/models/batch-update-validation.model';
import { BatchUpdateCorrectionData, JsonBatchUpdateCorrectionData } from '@core/models/batch-update.model';
import { FileModel, IFileModel } from '@core/models/file.model';
import { AuthService } from '@core/services/auth.service';
import { BaseService } from '@core/services/base/base.service';
import { ErrorHandlerService, HttpError } from '@core/services/error-handler.service';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export enum UpdateType {
  PriceUpdate = 1,
  BroadcastTimeUpd = 2,
}

export interface BatchUpdateFilter {
  fileName: string;
  channel: number;
  ut?: string;
  fileType?: number;
}

@Injectable({
  providedIn: 'root',
})
export class BatchUpdateService extends BaseService {
  public uploadProgress = new BehaviorSubject(0);

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private errorHandlerService: ErrorHandlerService,
    private authService: AuthService
  ) {
    super();
  }

  upload(file: File) {
    let url = environment.api_base_url + '/tv/channel-batch-update/upload-channel-grid-to-s3';

    return new Observable<string>(observer => {
      const request = new XMLHttpRequest();
      const formData = new FormData();
      request.open('POST', url, true);

      formData.append('file', file);
      const token = this.authService.getToken();
      request.setRequestHeader('Authorization', 'Bearer ' + token.access_token);

      request.onreadystatechange = () => {
        this.updateFileUploadProgress(request.readyState);

        if (request.readyState === 4) {
          if (request.status === 200) {
            if (request.responseText === file.name) {
              observer.next(request.responseText);
            }
          } else {
            this.errorHandlerService.showErrorMessage(
              [],
              request.status,
              'Impossible de télécharger le fichier. Veuillez essayer plus tard.'
            );
            observer.error('Impossible to upload the file.');
          }
        }
      };

      request.send(formData);
    });
  }

  importNbcBreakFile(fileName: string, channelCode) {
    const httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: 'Le fichier est introuvable.',
      },
      {
        statusCode: 500,
        message: 'Une erreur est survenue. Veuillez essayer plus tard.',
      },
    ];
    const user = this.authService.currentUserData ? this.authService.currentUserData.sub : '';
    const filter = {
      ut: user.toUpperCase(),
      fileName: fileName,
      channelCode: channelCode,
    };

    let url = environment.api_base_url + '/tv/channel-batch-update/integrate-nbc-breakfile';

    url += '?' + this.getParamsFromFilter(filter);

    return new Observable(observer => {
      this.httpService.get(url).subscribe(
        (response: any) => observer.next(response),
        (error: any) => {
          if (error.status != 504 || error.status != 0) {
            if (error.status !== 200) {
              this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            } else {
              observer.next(fileName);
            }
          }
          observer.error(error);
        }
      );
    });
  }

  importNbcProgFile(fileName: string, channelCode) {
    const httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: 'Le fichier est introuvable.',
      },
      {
        statusCode: 500,
        message: 'Une erreur est survenue. Veuillez essayer plus tard.',
      },
    ];
    const user = this.authService.currentUserData ? this.authService.currentUserData.sub : '';
    const filter = {
      ut: user.toUpperCase(),
      fileName: fileName,
      channelCode: channelCode,
    };

    let url = environment.api_base_url + '/tv/channel-batch-update/integrate-nbc-progfile';

    url += '?' + this.getParamsFromFilter(filter);

    return new Observable(observer => {
      this.httpService.get(url).subscribe(
        (response: any) => observer.next(response),
        (error: any) => {
          if (error.status != 504 || error.status != 0) {
            if (error.status !== 200) {
              this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            } else {
              observer.next(fileName);
            }
          }
          observer.error(error);
        }
      );
    });
  }

  startBatchUpdateStep(filter: BatchUpdateFilter, stepName: string) {
    const httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: 'Impossible de faire la.' + stepName,
      },
      {
        statusCode: 500,
        message: 'Une erreur est survenue. Veuillez essayer plus tard.',
      },
    ];
    const user = this.authService.currentUserData ? this.authService.currentUserData.sub : '';
    filter.ut = user.toUpperCase();

    let url = environment.api_base_url + this.getBatchUpdateStepUrl(stepName);

    url += '?' + this.getParamsFromFilter(filter);

    return new Observable(observer => {
      this.httpService.get(url).subscribe(
        (response: any) => observer.next(response),
        (error: any) => {
          if (error.status != 504 && error.status != 0) {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
          }
          observer.error(error);
        }
      );
    });
  }

  updateFileUploadProgress(readyState) {
    switch (readyState) {
      case 2:
        this.uploadProgress.next(33);
        break;
      case 3:
        this.uploadProgress.next(66);
        break;
      case 4:
        this.uploadProgress.next(100);
        break;
    }
  }

  getBatchUpdateCorrectionData(idTvChannel: number) {
    const httpErrors: HttpError[] = [
      {
        statusCode: 500,
        message: 'Une erreur  est survenue lors de recuperation de données à corriger. Veuillez essayer plus tard.',
      },
    ];
    const filter = {
      idTvChannel: idTvChannel,
    };
    let url = environment.api_base_url + '/tv/channel-batch-update';

    url += '?' + this.getParamsFromFilter(filter);

    return new Observable<BatchUpdateCorrectionData[]>(observer => {
      this.httpService
        .get(url, {})
        .pipe(
          map(response => {
            if (response['_embedded']['ChannelBatchUpdate'] && response['_embedded']['ChannelBatchUpdate'].length > 0) {
              return response['_embedded']['ChannelBatchUpdate'].map(
                channelBatchUpdateData => new BatchUpdateCorrectionData(channelBatchUpdateData)
              );
            }
            return [];
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  updateBatchUpdateCorrectionDataElement(id: number, propertyToUpdate: {}) {
    if (!id || !propertyToUpdate) {
      return;
    }
    const httpErrors: HttpError[] = [
      {
        statusCode: 500,
        message: 'Une erreur  est survenue lors de correction de données. Veuillez essayer plus tard.',
      },
    ];
    let url = environment.api_base_url + '/tv/channel-batch-update/' + id;

    return new Observable<BatchUpdateCorrectionData>(observer => {
      this.httpService
        .patch(url, propertyToUpdate)
        .pipe(
          map(response => {
            return new BatchUpdateCorrectionData(response as JsonBatchUpdateCorrectionData);
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  updateListOfBatchUpdateLines(batchUpdateLines: BatchUpdateValidationData[], updateType?: UpdateType) {
    let linesToUpdate = this.prepareValidationDataForUpdate(batchUpdateLines, updateType);
    if (linesToUpdate.length > 0) {
      const httpErrors: HttpError[] = [
        {
          statusCode: 500,
          message: 'Une erreur  est survenue lors de la modification des données. Veuillez essayer plus tard.',
        },
      ];
      let url = environment.api_base_url + '/tv/channel-batch-update';
      return new Observable<BatchUpdateValidationData[]>(observer => {
        this.httpService
          .patch(url, linesToUpdate)
          .pipe(
            map(response => {
              if (response['_embedded']['ChannelBatchUpdate'] && response['_embedded']['ChannelBatchUpdate'].length > 0) {
                return response['_embedded']['ChannelBatchUpdate'].map(
                  channelBatchUpdateData => new BatchUpdateValidationData(channelBatchUpdateData)
                );
              }
              return [];
            })
          )
          .subscribe(
            (response: any) => observer.next(response),
            error => {
              this.errorHandlerService.showErrorMessage(httpErrors, error.status);
              observer.error(error);
            }
          );
      });
    }
    return of(null);
  }

  downloadBatchUpdateDataForCorrection(idTvChannel: number) {
    const httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: 'Pas de données à télécharger.',
      },
      {
        statusCode: 500,
        message: 'Impossible de télécharger le fichier. Veuillez essayer plus tard.',
      },
    ];
    const filter = {
      idTvChannel: idTvChannel,
    };
    let url = environment.api_base_url + '/tv/channel-batch-update/download-batch-update-csv-for-correction';
    url += '?' + this.getParamsFromFilter(filter);

    return new Observable<IFileModel>(observer => {
      this.httpService
        .get(url, { reportProgress: true, responseType: 'blob' })
        .pipe(
          map(
            res =>
              new FileModel({
                fileName: `export_batch_update_data_for_correction_${moment().format('YYYYMMDD')}.csv`,
                data: res,
              })
          )
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  downloadBatchUpdateDataForValidation(idTvChannel: number, broadcastDate: string) {
    const httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: 'Pas de données de validaiton à télécharger.',
      },
      {
        statusCode: 500,
        message: 'Impossible de télécharger le fichier. Veuillez essayer plus tard.',
      },
    ];
    const filter = {
      idTvChannel: idTvChannel,
      broadcastDate: broadcastDate,
    };
    let url = environment.api_base_url + '/tv/tv-batch-update-validation/download-batch-update-csv-for-validation';
    url += '?' + this.getParamsFromFilter(filter);

    return new Observable<IFileModel>(observer => {
      this.httpService
        .get(url, { reportProgress: true, responseType: 'blob' })
        .pipe(
          map(
            res =>
              new FileModel({
                fileName: `export_batch_update_data_for_validation_${moment().format('YYYYMMDD')}.csv`,
                data: res,
              })
          )
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  getBatchUpdateStepUrl(stepName: string): string {
    switch (stepName) {
      case BatchUpdateStepName.Integration:
        return '/tv/channel-batch-update/integrate-channel-grid';
      case BatchUpdateStepName.Transformation:
        return '/tv/channel-batch-update/data-transformation';
      case BatchUpdateStepName.DuplicateCheck:
        return '/tv/channel-batch-update/screen-duplicate-check';
      case BatchUpdateStepName.ClosureDateCheck:
        return '/tv/channel-batch-update/closure-date-check';
      case BatchUpdateStepName.DataPreparation:
        return '/tv/channel-break-code-updates';
      case BatchUpdateStepName.BroadcastTimeAutoChange:
        return '/tv/channel-batch-update/fix-slot-error-auto-change-hd-and-duration';
      case BatchUpdateStepName.ReportGeneration:
        return '/tv/create-tv-batch-update-report';
      case BatchUpdateStepName.FlashGeneration:
        return '/tv/create-tv-batch-update-flash';
      case BatchUpdateStepName.BreakIdOrder:
        return '/tv/channel-batch-update/fix-break-id-order';
      case BatchUpdateStepName.DataBaseUpdate:
        return '/tv/channel-screen-updates';
      default:
        return '';
    }
  }

  getValidationDataAndSummary(idTvChannel: number, broadcastDate?: string) {
    const batchUpdateValidationSummaryObs = this.getValidationSummary(idTvChannel);
    const batchUpdateValidationDataObs = this.getValidationData(idTvChannel, broadcastDate);

    return forkJoin([batchUpdateValidationSummaryObs, batchUpdateValidationDataObs]);
  }

  getValidationData(idTvChannel: number, broadcastDate?: string) {
    let dataFilter: any = {
      idTvChannel: idTvChannel,
    };
    if (broadcastDate) {
      dataFilter = {
        idTvChannel: idTvChannel,
        broadcastDate: broadcastDate,
      };
    }
    return this.httpService
      .get(environment.api_base_url + '/tv/tv-batch-update-validation/get-data-by-day' + '?' + this.getParamsFromFilter(dataFilter), {})
      .pipe(
        map(response => {
          let data: BatchUpdateValidationData[] = [];
          if (response && response['_embedded']['tv-batch-update-validations']) {
            if (response['_embedded']['tv-batch-update-validations'].length > 0) {
              data = response['_embedded']['tv-batch-update-validations'].map(
                batchUpdateValidationData => new BatchUpdateValidationData(batchUpdateValidationData)
              );
            }
            return data;
          } else {
            throw new Error("L'erreur lors de la récupération des données à valider : pas de données.");
          }
        })
      );
  }

  getValidationSummary(idTvChannel: number) {
    const summaryFilter = {
      idTvChannel: idTvChannel,
    };

    return this.httpService
      .get(
        environment.api_base_url + '/tv/tv-batch-update-validation/get-validation-summary' + '?' + this.getParamsFromFilter(summaryFilter),
        {}
      )
      .pipe(
        map(response => {
          if (
            response &&
            response['_embedded'] &&
            response['_embedded']['validation-summary'] &&
            response['_embedded']['validation-summary'].length === 1 &&
            response['_embedded']['validation-summary-by-day'] &&
            response['_embedded']['validation-summary-by-day'].length > 0
          ) {
            const batchValidationSummary = new BatchUpdateValidationSummary(response['_embedded']['validation-summary'][0]);
            batchValidationSummary.daysToValidate = response['_embedded']['validation-summary-by-day']
              .map(dayToValidateJson => {
                return new DayToValidate(dayToValidateJson);
              })
              .filter(dTv => !!(dTv && dTv.broadcastDate));
            return batchValidationSummary;
          }
          throw new Error("L'erreur lors de la récupération du résumé sur la période. Le résumé est vide.");
        })
      );
  }

  prepareValidationDataForUpdate(validationDataLines: BatchUpdateValidationData[], updateType?: UpdateType): any[] {
    let lines = [];
    if (validationDataLines && validationDataLines.length) {
      lines = validationDataLines
        .map(batchUpdateLine => {
          if (batchUpdateLine && batchUpdateLine.idBatchUpdate) {
            switch (updateType) {
              case UpdateType.PriceUpdate:
                return { id: batchUpdateLine.idBatchUpdate, grossPrice: batchUpdateLine.price, captifPrice: batchUpdateLine.captifPrice };
              case UpdateType.BroadcastTimeUpd:
                return {
                  id: batchUpdateLine.idBatchUpdate,
                  broadcastTimeUpd: batchUpdateLine.broadcastTimeNew,
                  isDuplicate: false,
                };
              default:
                return { id: batchUpdateLine.idBatchUpdate, isUserApproved: true, durationUpd: batchUpdateLine.durationNew };
            }
          }
        })
        .filter(bu => bu && bu.id);
    }
    return lines;
  }

  getNewScreensWithoutPrices(idTvChannel: number, minNotIgnoredDate: string) {
    const httpErrors: HttpError[] = [
      {
        statusCode: 500,
        message: 'Une erreur  est survenue lors de recuperation de données à corriger. Veuillez essayer plus tard.',
      },
    ];
    const filter = {
      idTvChannel: idTvChannel,
      status: 'new',
      price: 0,
      minNotIgnoredDate: minNotIgnoredDate,
    };
    let url = environment.api_base_url + '/tv/tv-batch-update-validation';

    url += '?' + this.getParamsFromFilter(filter);

    return new Observable<BatchUpdateValidationData[]>(observer => {
      this.httpService
        .get(url, {})
        .pipe(
          map(response => {
            if (response['_embedded']['tv-batch-update-validations']) {
              let data: BatchUpdateValidationData[] = response['_embedded']['tv-batch-update-validations'].map(
                (valDataJson: JsonBathUpdateValidationData) => {
                  return new BatchUpdateValidationData(valDataJson);
                }
              );
              return data;
            }
            return null;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  changeBreakCodes(
    validationDataLines: BatchUpdateValidationData[],
    valLinesWithNewBreakCodes: BatchUpdateValidationData[],
    idTvChannel,
    broadcastDate
  ) {
    const breakCodes = validationDataLines
      .map(valLine => {
        if (valLine.idBatchUpdate) {
          return valLine.breakCode;
        }
      })
      .filter(breakCode => breakCode);

    const newBreakCodes = valLinesWithNewBreakCodes.map(valLine => valLine.breakCode);
    const data = {
      listOfBreakCodes: breakCodes,
      listOfNewBreakCodes: newBreakCodes,
      broadcastDate: broadcastDate,
      idTvChannel: idTvChannel,
    };

    const httpErrors: HttpError[] = [
      {
        statusCode: 500,
        message: 'Une erreur  est survenue lors de la modification des intitulés. Veuillez essayer plus tard.',
      },
    ];

    let url = environment.api_base_url + '/tv/tv-batch-update-validation/reorder-break-codes';
    return new Observable<BatchUpdateValidationData[]>(observer => {
      this.httpService.patch(url, data).subscribe(
        (response: any) => observer.next(response),
        error => {
          this.errorHandlerService.showErrorMessage(httpErrors, error.status);
          observer.error(error);
        }
      );
    });
  }
}
