import { Component, Input } from "@angular/core";
import { NavItem } from "@core/services/nav.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-nav-item",
  templateUrl: "./nav-item.component.html",
  styleUrls: ["./nav-item.component.scss"],
})
export class NavItemComponent {
  @Input() item: NavItem;

  constructor(private router: Router) {}

  ucFirst(str: string): string {
    if (str.length > 0) {
      return str[0].toUpperCase() + str.substring(1);
    } else {
      return str;
    }
  }

  handleClickItem() {
    if (this.item.internal) {
      this.router.navigate([this.item.url]);
    } else {
      const win = window.open(this.item.url, "_blank");
      win.focus();
    }
  }
}
