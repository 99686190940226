export interface JsonContract {
  id: string;
  name: string;
  advertiserId: string;
  cgvReturnDate: string;
  cgvStateNum: string;
  cgvStatus: string;
  cgvLabel: string;
  stateContractId: number;
  stateContractLabel: string;
  CA: number;
  f2f3f5CA: number;
  regionCA: number;
  thematicCA: number;
  sponsorshipFtpCA: number;
  sponsorshipThematicCA: number;
  sponsorshipRegCA: number;
  sponsorshipSoReachCA: number;
  sponsorshipWebCA: number;
  webCA: number;
  OIWebCA: number;
  cgvContractYear: string;
  stateContractYear: string;
}

export class Contract {
  id: string;
  name: string;
  advertiserId: string;
  cgvReturnDate: string;
  cgvStateNum: string;
  cgvStatus: string;
  cgvLabel: string;
  stateContractId: number;
  stateContractLabel: string;
  CA: number;
  f2f3f5CA: number;
  regionCA: number;
  thematicCA: number;
  sponsorshipFtpCA: number;
  sponsorshipThematicCA: number;
  sponsorshipRegCA: number;
  sponsorshipSoReachCA: number;
  sponsorshipWebCA: number;
  webCA: number;
  OIWebCA: number;
  cgvContractYear: string;
  stateContractYear: string;

  constructor(jsonContract: JsonContract = null) {
    this.id = jsonContract.id;
    this.name = jsonContract.name;
    this.advertiserId = jsonContract.advertiserId;
    this.cgvReturnDate = jsonContract.cgvReturnDate;
    this.cgvStateNum = jsonContract.cgvStateNum;
    this.cgvStatus = jsonContract.cgvStatus;
    this.cgvLabel = jsonContract.cgvLabel;
    this.stateContractId = jsonContract.stateContractId;
    this.stateContractLabel = jsonContract.stateContractLabel;
    this.CA = jsonContract.CA;
    this.f2f3f5CA = jsonContract.f2f3f5CA;
    this.regionCA = jsonContract.regionCA;
    this.thematicCA = jsonContract.thematicCA;
    this.sponsorshipFtpCA = jsonContract.sponsorshipFtpCA;
    this.sponsorshipThematicCA = jsonContract.sponsorshipThematicCA;
    this.sponsorshipRegCA = jsonContract.sponsorshipRegCA;
    this.sponsorshipSoReachCA = jsonContract.sponsorshipSoReachCA;
    this.sponsorshipWebCA = jsonContract.sponsorshipWebCA;
    this.webCA = jsonContract.webCA;
    this.OIWebCA = jsonContract.OIWebCA;
    this.cgvContractYear = jsonContract.cgvContractYear;
    this.stateContractYear = jsonContract.stateContractYear;

    // if (jsonContract.contractNatioNegoList) {
    //   this.contractNatioNegoList = jsonContract.contractNatioNegoList;
    // } else if (jsonContract._embedded && jsonContract._embedded['contractNatioNegoList']) {
    //   this.contractNatioNegoList = jsonContract._embedded['contractNatioNegoList'].map(contractNationNego => {
    //     new ContractNatioNego(contractNationNego);
    //   });
    // }
    //
    // if (jsonContract.contractCgrpOffList) {
    //   this.contractCgrpOffList = jsonContract.contractCgrpOffList;
    // } else if (jsonContract._embedded && jsonContract._embedded['contractCgrpOffList']) {
    //   this.contractCgrpOffList = jsonContract._embedded['contractCgrpOffList'].map(contractCgrpOff => {
    //     new ContractCgrpOff(contractCgrpOff);
    //   });
    // }
  }
}
