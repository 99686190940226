import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-filters-dialog',
  templateUrl: './filters-dialog.component.html',
  styleUrls: ['./filters-dialog.component.scss']
})
export class FiltersDialogComponent implements OnInit {
  filtersForm: FormGroup;
  selectedFilter: FormControl;
  dataFormated = {};
  screenTypePresence: { name: string, presence: boolean }[] = [];

  readonly filtersType: { name: string, id: number }[] = [
    { name: 'Typage', id: 0 },
    { name: 'Calibrage (max)', id: 1 },
    { name: 'Fin d\'intitulé', id: 2 }
  ];

  readonly operatorType: { name: string }[] = [
    { name: 'Égale à' },
    { name: 'Supérieur à'},
    { name: 'Inférieur à' }
  ];

  typeFilters = [];

  constructor(
    public dialogRef: MatDialogRef<FiltersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{ 
      dataFilters : {
        duration?: string,
        entitled?: {
          operator: string,
          hourControl: string,
          minuteControl: string
        },
        type?: {
          name: string,
          id: string
        }
      }, 
      rawScreenTypes: [],
      screenTypes: []},
    private fb: FormBuilder,
  ) {  
	  this.selectedFilter = new FormControl(this.filtersType);
	}

  ngOnInit(): void {
    this.initForm();

    if (this.data?.rawScreenTypes){
      this.checkScreenTypePresence();
    }

    if (this.data?.dataFilters?.type) {
      for (const [key, value] of Object.entries(this.data?.dataFilters?.type)) {
        this.typeFilters.push(value);
      }
    }
  }

  public initForm() {
    this.filtersForm = this.fb.group({
      type: [this.data?.dataFilters?.type ? this.data?.dataFilters?.type : null],
      duration: [this.data?.dataFilters?.duration ? this.data.dataFilters.duration : null],
      entitled: this.fb.group({
        operator: [this.data?.dataFilters?.entitled?.operator ? this.data?.dataFilters?.entitled?.operator : this.operatorType[0].name],
        hourControl: ['--'],
        minuteControl: [this.data?.dataFilters?.entitled?.minuteControl ? this.data.dataFilters.entitled.minuteControl : null],
      }),
    })
  }

  parseAndFormatData(filterData) {
    for (const key in filterData) {
      const value = filterData[key];
      if (key === 'duration' && value !== null ) {
        this.dataFormated = {...this.dataFormated, duration: value};
      }
      if (key === 'type' && value !== null ) {
        this.dataFormated = {...this.dataFormated, type: value};
      }
      if (key === 'entitled' && value.minuteControl !== null) {
        this.dataFormated = {...this.dataFormated, entitled: {operator: value.operator, hourControl: value.hourControl, minuteControl: value.minuteControl }};
      }
     }
     return this.dataFormated;
  }

  submit() {
    this.dialogRef.close(this.parseAndFormatData(this.filtersForm.value));
  }

  close() {
    this.dialogRef.close();
    this.filtersForm.reset();
  }

  selectTypeFilter(type) {
    if (type.presence) {
      if (!this.typeFilters.includes(type.name)) {
        this.typeFilters.push(type.name);
      } else {
        const indexType = this.typeFilters.indexOf(type);
        this.typeFilters.splice(indexType, 1);
      }
      this.filtersForm.get('type').setValue(this.typeFilters);
    }
  }

  checkScreenTypePresence() {
    this.data?.rawScreenTypes.forEach(element => {
      if (this.data.screenTypes.length > 0) {
        if (this.data.screenTypes.includes(element)) {
          this.screenTypePresence.push({name: element, presence : true});
        } else {
          this.screenTypePresence.push({name: element, presence : false});
        }
      } else {
        this.screenTypePresence.push({name: element, presence : true});
      }
    });
  }
}