import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ParamAppliRef } from "@core/models/param-appli-ref.model";

@Injectable({
  providedIn: "root",
})
export class ParamAppliRefService {
  private paramAppliRefRoute = "/tv/param-appli-ref";

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  public getParamAppliRef(id) {
    const url = environment.api_base_url + this.paramAppliRefRoute;
    return Observable.create((observer) => {
      this.httpService.get(url + "/" + id).subscribe(
        (response) => observer.next(new ParamAppliRef(response)),
        (error) => observer.error(this.catchError(error))
      );
    });
  }

  public update(data) {
    const url = environment.api_base_url + this.paramAppliRefRoute;
    return Observable.create((observer) => {
      this.httpService.put(url, [data]).subscribe(
        (response) => {
          this.snackBar.open("Enregistrement effectué", null, {
            duration: 2000,
            verticalPosition: "top",
          });

          observer.next(true);
        },
        (error) => {
          this.catchError(error);
          observer.error(error);
        }
      );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open("Aucun secodip n'a été trouvé", null, {
          duration: 2000,
          verticalPosition: "top",
        });
        break;
      default:
        this.snackBar.open(
          error.error.detail ? error.error.detail : "Une erreur est survenue",
          null,
          { duration: 2000, verticalPosition: "top" }
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
