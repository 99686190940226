import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { environment } from "@env/environment";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Secodip } from "../models/secodip.model";
import { Observable } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";

export interface SecodipFilter {
  itemPerPage?: number;
  page?: number;
  sortActive?: string;
  sortDirection?: string;
  search?: string;
  id?: any;
}

@Injectable({
  providedIn: "root",
})
export class SecodipService {
  private secodipRoute = "/tv/secodip";

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  public getSecodip(filter: SecodipFilter = null): Observable<any> {
    let url = environment.api_base_url + this.secodipRoute;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }
    url += "?" + params.toString();

    return this.httpService.get(url).pipe(
      map((jsonResponse) => {
        const total =
          jsonResponse["_embedded"]["Secodip"][
            jsonResponse["_embedded"]["Secodip"].length - 1
          ]["total_count"];
        jsonResponse["_embedded"]["Secodip"].splice(-1, 1);
        const list = jsonResponse["_embedded"]["Secodip"].map(
          (json) => new Secodip(json)
        );
        return { list: list, total: total };
      })
    );
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open("Aucun secodip n'a été trouvé", null, {
          duration: 2000,
          verticalPosition: "top",
        });
        break;
      default:
        this.snackBar.open(
          error.error.detail ? error.error.detail : "Une erreur est survenue",
          null,
          { duration: 2000, verticalPosition: "top" }
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
