// File Types
import { transformDate } from '@shared/utils/transform-date-funcs/transform-date-funcs';

// Batch Update Correction Data
export interface JsonBatchUpdateCorrectionData {
  id: number;
  idTvChannel: number;
  broadcastDate: string;
  broadcastDateUpd: any;
  broadcastTime: string;
  broadcastTimeUpd: string | null;
  duration: string;
  durationUpd: string | null;
  titleBefore: string;
  titleAfter: string;
}

export class BatchUpdateCorrectionData {
  id: number;
  idTvChannel: number;
  broadcastDate: string;
  broadcastDateUpdIsValid: boolean;
  broadcastDateUpd = '';
  broadcastTime: string;
  broadcastTimeUpdIsValid: boolean;
  broadcastTimeUpd = '';
  duration: string;
  durationUpdIsValid: boolean;
  durationUpd = '';
  titleBefore: string;
  titleAfter: string;

  constructor(jsonBatchUpdateCorrectionData: JsonBatchUpdateCorrectionData) {
    this.id = jsonBatchUpdateCorrectionData.id;
    this.idTvChannel = jsonBatchUpdateCorrectionData.idTvChannel;

    this.broadcastDate = jsonBatchUpdateCorrectionData.broadcastDate;
    if (jsonBatchUpdateCorrectionData.broadcastDateUpd) {
      this.broadcastDateUpdIsValid = true;
      const date = transformDate(jsonBatchUpdateCorrectionData.broadcastDateUpd.date);
      if (date) {
        this.broadcastDateUpd = date;
      }
    } else {
      this.broadcastDateUpdIsValid = false;
    }

    this.broadcastTime = jsonBatchUpdateCorrectionData.broadcastTime;
    if (jsonBatchUpdateCorrectionData.broadcastTimeUpd) {
      this.broadcastTimeUpdIsValid = true;
      this.broadcastTimeUpd = jsonBatchUpdateCorrectionData.broadcastTimeUpd;
    } else {
      this.broadcastTimeUpdIsValid = false;
    }

    this.duration = jsonBatchUpdateCorrectionData.duration;
    if (jsonBatchUpdateCorrectionData.durationUpd) {
      this.durationUpdIsValid = true;
      this.durationUpd = jsonBatchUpdateCorrectionData.durationUpd;
    } else {
      this.durationUpdIsValid = false;
    }

    this.titleBefore = jsonBatchUpdateCorrectionData.titleBefore;
    this.titleAfter = jsonBatchUpdateCorrectionData.titleAfter;
  }
}
