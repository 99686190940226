import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { map } from "rxjs/operators";
import { SpotCampaign } from "@core/models/spot-campaign.model";

export interface SpotCampaignFilter {
  datEcran: string;
  herEcran: string;
  codChaine: string;
  codRegion: string;
  norSpot: string;
  search?: string;
}

@Injectable({
  providedIn: "root",
})
export class SpotCampaignService {
  private endpoint = "/tv/tv-campaign-screen-sub";

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  public getList(filter: SpotCampaignFilter): Observable<any> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    const url =
      environment.api_base_url + this.endpoint + "?" + params.toString();

    return new Observable((observer) => {
      this.httpService
        .get(url)
        .pipe(
          map((response) => {
            if (response["_embedded"]) {
              const next = response["_embedded"]["TvCampaignSpotSub"].pop();
              const current = response["_embedded"]["TvCampaignSpotSub"].pop();
              const previous = response["_embedded"]["TvCampaignSpotSub"].pop();
              const suiv =
                response["_embedded"]["TvCampaignSpotSub"].pop()[
                  "titreEmissSuiv"
                ];
              const preced =
                response["_embedded"]["TvCampaignSpotSub"].pop()[
                  "titreEmissPreced"
                ];
              const total =
                response["_embedded"]["TvCampaignSpotSub"].pop()["total_count"];
              const list = response["_embedded"]["TvCampaignSpotSub"].map(
                (elem) => new SpotCampaign(elem)
              );
              return { list, preced, suiv, total, previous, current, next };
            }
            return response;
          })
        )
        .subscribe(
          (response) => observer.next(response),
          (error) => observer.error(this.catchError(error))
        );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open("Aucune campagne n'a été trouvée", null, {
          duration: 2000,
          verticalPosition: "top",
        });
        break;
      default:
        this.snackBar.open(
          error.error.detail ? error.error.detail : "Une erreur est survenue",
          null,
          { duration: 2000, verticalPosition: "top" }
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
