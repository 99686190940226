export interface JsonChannelsSeco {
  chaines?: string;
  libSecodip?: string;
  codSecodip?: string;
}

export class ChannelsSeco {
  public channels: any;
  public libSecodip: string;
  public codSecodip: string;

  constructor(jsonChannelsSeco: JsonChannelsSeco = null) {
    const jsonChannels = JSON.parse(jsonChannelsSeco.chaines);
    this.channels = Object.keys(jsonChannels).map((e) => e);
    this.libSecodip = jsonChannelsSeco.libSecodip;
    this.codSecodip = jsonChannelsSeco.codSecodip;
  }
}
