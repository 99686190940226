import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";

import { DateEvalTV } from "@core/models/plan/plan.model";

import * as moment from 'moment/moment';

@Component({
  selector: 'app-tv-select-date-popup',
  templateUrl: './tv-select-date-popup.component.html',
  styleUrls: ['./tv-select-date-popup.component.scss']
})
export class TvSelectDatePopupComponent implements OnInit {
  min: moment.Moment;
  max: moment.Moment;
  groupDate: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<TvSelectDatePopupComponent>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DateEvalTV,
  ) {
    this.min = data?.start ?? null;
    this.max = data?.end ?? null;

    this.groupDate = this.fb.group({
      start: [data?.start ?? null, [Validators.required]],
      end: [data?.end ?? null, [Validators.required]]
    });
  }

  ngOnInit(): void {}

  /**
   * Close dialog with parameter
   * @param dateEvalTV
   */
  close(dateEvalTV: DateEvalTV = null): void {
    this.dialogRef.close(dateEvalTV);
  }

  /**
   * Validation of date for EvalTV
   */
  validation(): void {
    if (this.groupDate.get('start').valid && this.groupDate.get('end').valid) {
      if (moment(this.groupDate.get('start').value).isSameOrBefore(this.groupDate.get('end').value)) {
        this.close({
          start: this.groupDate.get('start').value,
          end: this.groupDate.get('end').value
        });
      } else {
        // Display error message
        this.snackBar.open('Date incompatible', null, {
          duration: 3000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
          panelClass: ['error-snack']
        });
      }
    }
  }
}
