export interface JsonNegotiationType {
  id?: string;
  name?: string;
  rank?: number;
  repartAvoirV3StartDate?: string;
  repartAvoirV3EndDate?: string;
  repartAvoirV3ChannelStartDate?: string;
  repartAvoirV3ChannelEndDate?: string;
}

export class NegotiationType {
  id?: string;
  name?: string;
  rank?: number;
  repartAvoirV3StartDate?: string;
  repartAvoirV3EndDate?: string;
  repartAvoirV3ChannelStartDate?: string;
  repartAvoirV3ChannelEndDate?: string;

  constructor(jsonNegotiationType: JsonNegotiationType = null) {
    this.id = jsonNegotiationType.id;
    this.name = jsonNegotiationType.name;
    this.rank = jsonNegotiationType.rank;
    this.repartAvoirV3StartDate = jsonNegotiationType.repartAvoirV3StartDate;
    this.repartAvoirV3EndDate = jsonNegotiationType.repartAvoirV3EndDate;
    this.repartAvoirV3ChannelStartDate = jsonNegotiationType.repartAvoirV3ChannelStartDate;
    this.repartAvoirV3ChannelEndDate = jsonNegotiationType.repartAvoirV3ChannelEndDate;
  }
}

export interface JsonFilteredNegociationType {
  id: string;
  name: string;
}

export class FilteredNegociationType {
  id: string;
  name?: string;

  constructor(jsonFilteredNegociationType: JsonFilteredNegociationType) {
    if (jsonFilteredNegociationType.id) {
      this.id = jsonFilteredNegociationType.id;
    }

    if (jsonFilteredNegociationType.name) {
      this.name = jsonFilteredNegociationType.name;
    }
  }
}
