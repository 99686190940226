import { Pipe, PipeTransform, LOCALE_ID, Inject } from "@angular/core";

@Pipe({
  name: "numberFormater",
})
export class NumberFormaterPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  transform(
    value: string | number,
    locale: string = this.localeId,
    defaults: string = ""
  ): string {
    return !value
      ? defaults
      : new Intl.NumberFormat(locale).format(this.toNumber(value));
  }

  toNumber(value: string | number): number {
    return Number((value + "").replace(/\s/g, ""));
  }
}
