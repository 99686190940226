import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { JsonToken } from '../models/token.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  static UNAUTHORIZED_STATUS = 401;
  static DEFAULT_REDIRECT_URL = '';
  static TOKEN_KEY = 'auth-token';
  static REDIRECT_URL_KEY = 'auth-redirect-url';
  static ENDPOINT_LOGOUT = '/logout';
  static ENDPOINT_USERINFO = '/userinfo';

  private _tokenChanged: BehaviorSubject<JsonToken> = new BehaviorSubject(null);
  private _refreshTimeout: any;
  private _refreshTokenInProgress: boolean;
  private _tokenRefreshedSubject = new Subject<JsonToken>();
  private _userInfoInProgress: boolean;
  private _userInfoSubject = new Subject<any>();
  private _currentUserData: any;
  private _initSubject = new BehaviorSubject<boolean>(false);

  private _checking: BehaviorSubject<boolean> = new BehaviorSubject(false);

  set checking(val: boolean) {
    if (val !== this._checking.value) {
      this._checking.next(val);
    }
  }

  get isChecking(): boolean {
    return this._checking.value;
  }

  get checkingState(): Observable<boolean> {
    return this._checking.asObservable();
  }

  set redirectUrl(url: string) {
    localStorage.setItem(AuthService.REDIRECT_URL_KEY, url);
  }

  get redirectUrl(): string {
    return localStorage.getItem(AuthService.REDIRECT_URL_KEY);
  }

  get authUrl(): string {
    return `${environment.api_base_url}/oauth`;
  }

  get hostUrl(): string {
    return document.location.origin;
  }

  get currentUserData(): any {
    return this._currentUserData;
  }

  constructor(private httpService: HttpClient, private router: Router, @Inject(LOCALE_ID) private localeId) {}

  init() {
    this._initSubject.next(true);
  }

  onInit() {
    return this._initSubject.asObservable();
  }

  /**
   * Return an observable containing token data
   *
   * @return {Observable<JsonToken>}
   */
  getToken(): JsonToken {
    return JSON.parse(localStorage.getItem(AuthService.TOKEN_KEY) || '{}');
  }

  /**
   * Set new token data into store
   * Return an observable containing token data
   *
   * @param {JsonToken} token The token data to store
   * @return {Observable<JsonToken>}
   */
  setToken(token: JsonToken): void {
    if (token) {
      token.date = new Date().toString();
      localStorage.setItem(AuthService.TOKEN_KEY, JSON.stringify(token));
    } else {
      localStorage.removeItem(AuthService.TOKEN_KEY);
    }

    this.handleTokenChange(token);
  }

  /**
   * Decode token id using base64
   */
  decodeIdToken() {
    const base64Url = this.getToken().id_token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
  }

  /**
   * Return an observable containing a boolean
   * The boolean indicate the current user authenticated state, TRUE means user is authenticated
   *
   * @return {Observable<boolean>}
   */
  isAuthenticated(): boolean {
    return !!this.getToken().access_token;
  }

  /**
   * Return an observable containing a boolean
   * The boolean indicate the current user authorized state, TRUE means user is authorized (token still valid)
   *
   * @return {Observable<boolean>}
   */
  isAuthorized(): Observable<boolean> {
    this.checking = true;
    if (!this.isAuthenticated()) {
      this.checking = false;
      return of(false);
    }
    return this.getUserInfo().pipe(
      tap(() => (this.checking = false)),
      mergeMap((data: boolean) => {
        if (!data) {
          return this.processRefresh().pipe(
            mergeMap(() => of(true)),
            catchError(() => of(false))
          );
        }
        return of(true);
      }),
      catchError(() => of(false))
    );
  }

  /**
   * Return an observable containing the user info object
   * If a userInfo request is still pending, the function will wait the response
   *
   * @return {Observable<any>}
   */
  getUserInfo(force?: boolean): Observable<any> {
    if (this._userInfoInProgress && !force) {
      // A request is already in progress
      // Wait for user info finish then forward new user info to subscriber
      return new Observable(observer => {
        this.onUserInfoChanged().subscribe(info => {
          observer.next(info);
          observer.complete();
        });
      });
    }

    if (this.isAuthenticated()) {
      // Read token data from store
      const token = this.getToken();

      this._userInfoInProgress = true;

      const url = this.authUrl + AuthService.ENDPOINT_USERINFO;
      const options = {
        headers: {
          Authorization: this.ucFirst(token.token_type) + ' ' + token.access_token,
        },
      };

      let finish = false;

      return this.httpService.get(url, options).pipe(
        tap(() => {
          finish = true;
        }),
        map(info => {
          this._userInfoInProgress = false;
          this._userInfoSubject.next(info);
          this._currentUserData = info;
          return info;
        }),
        catchError(err => {
          this._userInfoInProgress = false;
          // User is authenticated but access_token is not valid
          // Try to refresh the access_token
          // If refresh is success then recall getUserInfos with the refreshed token
          return this.processRefresh().pipe(
            mergeMap(() => {
              return this.getUserInfo(true).pipe(
                map(infos => {
                  this._userInfoInProgress = false;
                  this._userInfoSubject.next(infos);
                  this._currentUserData = infos;
                  return infos;
                })
              );
            })
          );
        })
      );
    }
    return of(null);
  }

  /**
   * Perform login action by a OAuth2 code
   *
   * @param {string} code The code to exchange
   * @param {string|null} redirectUrl The url to redirect after login success, if not set use the service redirect url
   */
  login(code: string, redirectUrl?: string): Subscription {
    return this.processLogin(code).subscribe(
      () => {
        redirectUrl = redirectUrl || this.redirectUrl;
        this.router.navigateByUrl(redirectUrl);
      },
      err => {
        this.router.navigate(['/login'], {
          queryParams: { error: err.message },
        });
      }
    );
  }

  /**
   * Perform logout action
   */
  logout(): void {
    this.processLogout();
  }

  /**
   * Redirect to the login page
   */
  redirectToLogin(): void {
    const url = environment.oidc.openid_authorize_endpoint;
    const params = new URLSearchParams();

    params.set('client_id', environment.oidc.client_id);
    params.set('redirect_uri', `${this.hostUrl}${environment.oidc.auth_callback_path}`);
    params.set('response_type', environment.oidc.response_type);
    params.set('scope', environment.oidc.scope);

    window.location.href = url + '?' + params.toString();
  }

  /**
   * Redirect to the logout page
   */
  redirectToLogout(): void {
    // const url = this.authUrl + AuthService.ENDPOINT_LOGOUT;

    // const params = new URLSearchParams();

    // params.set('client_id', environment.oidc.client_id);
    // params.set('logout_uri', `${window.location.protocol}//${window.location.host}`);

    // window.location.href = url + '?' + params.toString();

    this.router.navigate(['/login']);
    location.reload();
  }

  /**
   * Handle login process
   * First it will exchange the given OAuth2 code
   * Then it set token date and save it to store
   * Return an observable containing token data
   *
   * @param {string} code The OAuth2 code to exchange
   * @return {Observable<JsonToken>}
   */
  processLogin(code: string): Observable<JsonToken> {
    const url = this.authUrl;
    const params = new URLSearchParams();
    const clientId = environment.oidc.client_id;

    params.set('grant_type', environment.oidc.grant_type);
    params.set('client_id', clientId);
    params.set('redirect_uri', this.hostUrl + environment.oidc.auth_callback_path);
    params.set('scope', environment.oidc.scope);
    params.set('code', code);

    const options = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    };

    return this.httpService.post(url, params.toString(), options).pipe(
      map((jsonToken: JsonToken) => {
        this.setToken(jsonToken);
        return jsonToken;
      })
    );
  }

  /**
   * WARNING: refresh token is disabled !!
   *
   * Handle refresh token process
   * If a refresh process is still in progress, the function will wait the response
   *
   * @return {Observable<JsonToken>}
   */
  processRefresh(): Observable<JsonToken> {
    this.setToken(null);
    return throwError(new Error('Invalid token or missing refresh_token'));
    // if (this._refreshTokenInProgress) {
    //   // A refresh is already in progress
    //   // Wait for refresh finish then forward newToken to subscriber
    //   return new Observable(observer => {
    //     this.onTokenRefreshed().subscribe((newToken: JsonToken) => {
    //       observer.next(newToken);
    //       observer.complete();
    //     });
    //   });
    // }

    // this._refreshTokenInProgress = true;

    // // Read token data from store
    // const token = this.getToken();

    // // Token and/or refresh_token are not defined
    // if (!token || !token.refresh_token) {
    //   return throwError(new Error('Invalid token or missing refresh_token'));
    // }

    // const url = this.authUrl;

    // const params = new URLSearchParams();

    // params.set('grant_type', 'refresh_token');
    // params.set('client_id', environment.oidc.client_id);
    // params.set('scope', environment.oidc.scope);
    // params.set('refresh_token', token.refresh_token);

    // const options = {
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded'
    //   }
    // };

    // // Send refresh request
    // return this.httpService.post(url, params.toString(), options).pipe(map((newToken: any) => {
    //   // Override refresh_token inside new token
    //   newToken.refresh_token = token.refresh_token;
    //   this.setToken(newToken);
    //   this._refreshTokenInProgress = false;
    //   return newToken;
    // }), catchError(err => {
    //   this.setToken(null);
    //   return throwError(err);
    // }));
  }

  /**
   * Handle logout process
   *
   * @return {Observable<any>}
   */
  processLogout(): void {
    this.setToken(null);
    this.redirectToLogout();
  }

  /**
   * Create an authenticated request (add authorization header)
   *
   * @param {HttpRequest<any>} request The request to update
   * @param {HttpHandler} next         The request handler
   * @param {boolean|true} retry       Retry behavior, if status is unauthorized and is TRUE it will retry to send request after a refresh
   * @param {boolean|true} logout      Logout behavior, if request fail due to unauthorized or refresh fail and is TRUE then logout user
   * @return {Observable<HttpEvent<any>>}
   */
  authenticateRequest(request: HttpRequest<any>, next: HttpHandler, retry = true, logout = true): Observable<HttpEvent<any>> {
    const updatedReq = this.addAuthHeader(request);

    return next.handle(updatedReq).pipe(
      map((event: any) => event),
      catchError((error: HttpErrorResponse) => {
        if (error.status === AuthService.UNAUTHORIZED_STATUS && retry) {
          if (!this.isAuthenticated()) {
            if (logout) {
              this.logout();
            }
            return throwError(error);
          }
          return this.processRefresh().pipe(
            switchMap(() => {
              return next.handle(this.addAuthHeader(request));
            }),
            catchError(err => {
              if (logout) {
                this.logout();
              }
              return throwError(err);
            })
          );
        }
        return throwError(error);
      })
    );
  }

  /**
   * Add authorization header to the given request if token is defined
   *
   * @param {HttpRequest<any>} req The request to update
   * @return {Observable<HttpRequest<any>>}
   */
  addAuthHeader(req: HttpRequest<any>): HttpRequest<any> {
    const token = this.getToken();
    if (!!token && !!token.access_token) {
      return req.clone({
        setHeaders: {
          Authorization: this.ucFirst(token.token_type) + ' ' + token.access_token,
        },
      });
    }
    return req;
  }

  /**
   * Publish token change event
   *
   * @param {JsonToken} token The token data
   */
  handleTokenChange(token: JsonToken): void {
    if (JSON.stringify(token || {}) !== JSON.stringify(this._tokenChanged.value || {})) {
      // Publish auth change event only if token is different than old token
      this._tokenChanged.next(token);
    }
  }

  /**
   * Start refresh process then publish token refresh event
   *
   * @param {JsonToken} token The token data
   */
  startTokenRefreshTimeout(token?: JsonToken, timeout?: number): void {
    token = token || this.getToken();

    if (!token) {
      return;
    }

    timeout = timeout !== undefined ? timeout : this.tokenExpireIn(token) - 600;

    // If timeout is negative, force it to
    timeout = timeout < 0 ? 0 : timeout;

    // Stop refresh timeout is already defined
    if (this._refreshTimeout) {
      this.stopTokenRefreshTimeout();
    }

    // Start refresh timeout
    this._refreshTimeout = setTimeout(() => {
      this.processRefresh().subscribe(newToken => {
        newToken.date = new Date().toString();
        // Publish token refreshed event
        this._tokenRefreshedSubject.next(newToken);
        this.startTokenRefreshTimeout();
      });
    }, timeout * 1000);
  }

  /**
   * Stop the token refresh timeout
   */
  stopTokenRefreshTimeout(): void {
    clearTimeout(this._refreshTimeout);
  }

  /**
   * Return the number of second before token expire
   *
   * @param {JsonToken} token
   * @return {number}
   */
  tokenExpireIn(token: JsonToken): number {
    const tokenDateCreation = new Date(token.date);
    const tokenTimestampExpiration = Math.round(tokenDateCreation.getTime() / 1000) + token.expires_in;
    return tokenTimestampExpiration - Math.round(new Date().getTime() / 1000);
  }

  hasRefresHToken(): boolean {
    const token = this.getToken();
    return !!token && !!token.refresh_token;
  }

  /**
   * Return the token change subject emitter as an observable
   *
   * @return {Observable<JsonToken>}
   */
  onTokenChanged(): Observable<JsonToken> {
    return this._tokenChanged.asObservable();
  }

  /**
   * Return the user info change subject emitter as an observable
   *
   * @return {Observable<any>}
   */
  onUserInfoChanged(): Observable<any> {
    return this._userInfoSubject.asObservable();
  }

  /**
   * Return the token refresh subject emitter as an observable
   *
   * @return {Observable<JsonToken>}
   */
  onTokenRefreshed(): Observable<JsonToken> {
    return this._tokenRefreshedSubject.asObservable();
  }

  ucFirst(str: string): string {
    if (str.length > 0) {
      return str[0].toUpperCase() + str.substring(1);
    } else {
      return str;
    }
  }

  /**
   * Format user info to have only OU
   * @param ou
   * @returns
   */
  formatOu(ou: string): string {
    if (ou) {
      let index = ou.indexOf(',');
      return ou.substring(index + 1);
    }
  }

  /**
   * Check if user OU have right to acces to menu
   * @param OUCode
   */
  checkAccess(OUCode): Observable<any> {
    const info = {
      ou: OUCode,
      resources: [
        { resource_module: 'dev', permission_code: 'R' },
        { resource_module: 'planning', permission_code: 'R' },
        { resource_module: 'or', permission_code: 'R' },
        { resource_module: 'commercial', permission_code: 'R' },
        { resource_module: 'adv', permission_code: 'R' },
      ],
      app: 'TV',
    };

    const url = environment.manager_api_base_url + AuthService.ENDPOINT_USERINFO + '/check-access';

    return this.httpService.post(url, info).pipe(
      map((response: object) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}
