import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-screen-historic',
  templateUrl: './screen-historic.component.html',
  styleUrls: ['./screen-historic.component.scss'],
})
export class ScreenHistoricComponent implements OnInit {
  @Input() screenHistory;

  displayedColumns: string[] = [
    'dateMvt',
    'ut',
    'commentMvt',
    'valueBefore',
    'valueAfter',
    'hourBefore',
    'hourAfter',
    'screenStatusBefore',
    'screenStatusAfter',
  ];

  constructor() {}

  ngOnInit(): void {}
}
