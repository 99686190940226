export interface JsonContractTarget {
  id?: number;
  name?: string;
}

export class ContractTarget {
  id: number;
  name: string;

  constructor(jsonContractTarget: JsonContractTarget = null) {
    this.id = jsonContractTarget.id;
    this.name = jsonContractTarget.name;
  }
}
