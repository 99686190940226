import { CategoryType } from '@core/models/category-type.model';

export interface JsonContractNatioNegoCondition {
  id?: number;
  groupId?: number;
  isInclude?: number;
  categoryType?: CategoryType;
  categoryValue?: any;
}

export class ContractNatioNegoCondition {
  id: number;
  groupId: number;
  isInclude: number;
  categoryType?: CategoryType;
  categoryValue?: any;

  constructor(jsonContractNatioNegoCondition: JsonContractNatioNegoCondition = null) {
    this.id = jsonContractNatioNegoCondition.id;
    this.groupId = jsonContractNatioNegoCondition.groupId;
    this.isInclude = jsonContractNatioNegoCondition.isInclude;
    this.categoryType = jsonContractNatioNegoCondition.categoryType;
    this.categoryValue = jsonContractNatioNegoCondition.categoryValue;
  }
}
