import { Injectable } from '@angular/core';
import { IFileModel } from '@core/models/file.model';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  constructor() {}

  public getParamsFromFilter(filter: any): string {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    return params.toString();
  }

  downloadFile(res: IFileModel): void {
    const url = window.URL.createObjectURL(res.data);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = res.fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
