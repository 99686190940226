import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ContractNatioNego } from '@core/models/contract-natio-nego.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContractNatioNegoService {
  private natioNegotiationRoute = '/contract_natio_nego';

  constructor(private httpService: HttpClient, private snackBar: MatSnackBar, private router: Router) {}

  public create(negotiation: ContractNatioNego) {
    const url = environment.contract_microservice_api_base_url + this.natioNegotiationRoute;

    const data = { negotiation: negotiation };

    return new Observable(observer => {
      this.httpService.post(url, data).subscribe(
        response => {
          if (response && response['data']) {
            observer.next(new ContractNatioNego(response));
          } else {
            observer.next(response);
          }
        },
        error => observer.error(this.catchError(error))
      );
    });
  }

  public edit(negotiation: ContractNatioNego, id: string): Observable<any> {
    const url = environment.contract_microservice_api_base_url + this.natioNegotiationRoute + '/' + id;

    return new Observable(observer => {
      this.httpService.put(url, negotiation).subscribe(
        response => {
          if (response && response['data']) {
            observer.next(new ContractNatioNego(response));
          } else {
            observer.next(response);
          }
        },
        error => {
          this.catchError(error);
          observer.error(false);
        }
      );
    });
  }

  public remove(id) {
    let url = environment.contract_microservice_api_base_url + this.natioNegotiationRoute + '/' + id;

    return new Observable(observer => {
      this.httpService.delete(url).subscribe(
        () => {
          observer.next(true);
        },
        error => {
          this.catchError(error);
          observer.error(false);
        }
      );
    });
  }

  private catchError(error: any, routeRedirect: string = null): any {
    this.snackBar.open(error.error.data?.msg ? error.error.data.msg : 'Une erreur est survenue', null, {
      duration: 2000,
      verticalPosition: 'top',
    });

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
