export interface JsonContractAdvertiser {
  numClient?: number;
  nomAd1?: string;
  idGroupeAnnonceur?: number;
}

export class ContractAdvertiser {
  id: number;
  name: string;
  advertiserId: number;

  constructor(jsonContractAdvertiser: JsonContractAdvertiser = null) {
    this.id = jsonContractAdvertiser.numClient;
    this.name = jsonContractAdvertiser.nomAd1;
    this.advertiserId = jsonContractAdvertiser.idGroupeAnnonceur;
  }
}
