export interface JsonPosition {
  id?: number;
  bactif?: boolean;
}

export class Position {
  public id: number;
  public bactif: boolean;

  constructor(jsonPosition: JsonPosition = null) {
    this.id = jsonPosition.id;
    this.bactif = jsonPosition.bactif;
  }
}
