export interface JsonChannel {
  id?: number;
  name?: string;
  numRegie?: string;
}

export class Channel {
  public id: number;
  public name: string;
  public numRegie: string;


  constructor(jsonChannel: JsonChannel = null) {
    this.id = jsonChannel.id;
    this.name = jsonChannel.name;
    this.numRegie = jsonChannel.numRegie;
  }
}
