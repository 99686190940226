import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@core/services/base/base.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorHandlerService, HttpError } from '@core/services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class TargetService extends BaseService {

  constructor(private httpService: HttpClient, private errorHandlerService: ErrorHandlerService) {
    super();
  }

  public getList(filter): Observable<any[]> {
    const httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: 'Impossible de récuperer la liste des cibles. Veuillez essayer plus tard.',
      },
      {
        statusCode: 500,
        message: 'Une erreur est survenue. Veuillez essayer plus tard.',
      },
    ];

    let url = environment.contract_microservice_api_base_url + '/target?' + this.getParamsFromFilter(filter);

    return new Observable(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }
}
