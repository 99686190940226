import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Position } from "@core/models/position.model";

@Injectable({
  providedIn: "root",
})
export class FormatSubService {
  private route = "/tv/param-format-substituable";

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  public getFormats(): Observable<any> {
    const url = environment.api_base_url + this.route;
    return this.httpService.get(url).pipe(
      map((jsonResponse) => {
        return jsonResponse["_embedded"]["TvParamFormatSubstituable"];
      })
    );
  }

  public delete(id): Observable<any> {
    const url = environment.api_base_url + this.route + "/" + id;

    return this.httpService.delete(url);
  }

  public addFormat(data) {
    const url = environment.api_base_url + this.route;

    return this.httpService.post(url, data);
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      default:
        this.snackBar.open(
          error.error.detail ? error.error.detail : "Une erreur est survenue",
          null,
          { duration: 2000, verticalPosition: "top" }
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
