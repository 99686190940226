import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContractItem } from '@core/models/contract-item.model';
import { ContractNatioNego } from '@core/models/contract-natio-nego.model';
import { Contract } from '@core/models/contract.model';
import { ContractNatioNegoService } from '@core/services/contract-natio-nego/contract-natio-nego.service';
import { NatioNegoManagingDialogComponent } from '@shared/contract/contract-item/contract-natio-nego/natio-nego-managing-dialog/natio-nego-managing-dialog.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-contract-natio-nego',
  templateUrl: './contract-natio-nego.component.html',
  styleUrls: ['./contract-natio-nego.component.scss'],
})
export class ContractNatioNegoComponent implements OnInit {
  @Input() contract: Contract;
  @Input() contractItem: ContractItem;
  @Input() natioNegotiationList: ContractNatioNego[];

  itemsFilterForm: FormGroup;
  componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private contractNatioNegoService: ContractNatioNegoService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  public initForm(): void {
    this.itemsFilterForm = this.fb.group({
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      search: [null, [Validators.required]],
    });
  }

  public openNatioNegoModal(natioNegoItem = null, isDuplication = false): void {
    // TODO : delete contract with @bvu view
    this.dialog.open(NatioNegoManagingDialogComponent, {
      data: {
        contract: this.contract,
        contractItem: this.contractItem,
        negotiationData: natioNegoItem,
        isDuplication: isDuplication,
      },
    });
  }

  public removeNatioNegotiation(natioNegoItem = null): void {
    this.contractNatioNegoService.remove(natioNegoItem.id.toString()).subscribe(
      result => {
        this.snackBar.open('Suppression de la négotiation.', null, {
          duration: 4000,
          verticalPosition: 'top',
          panelClass: ['tv-chip-success'],
        });
        location.reload();
      },
      error => {
        this.snackBar.open('Une erreur est survenue.', null, {
          duration: 4000,
          verticalPosition: 'top',
          panelClass: ['tv-chip-error'],
        });
      }
    );
  }

  // TODO : implement search by filter method
  // public valueChange(): void {
  //   merge(this.itemsFilterForm.get('startDate').valueChanges, this.itemsFilterForm.get('endDate').valueChanges)
  //     .pipe(takeUntil(this.componentDestroyed$))
  //     .subscribe(
  //       () => {
  //         // TODO
  //       },
  //       (error: Error) => {
  //         console.error(error);
  //       }
  //     );
  // }
}
