export interface JsonContractCampaign {
  numProduit?: number;
  norCampagne?: number;
  annCampagne?: string;
  nomCampagne?: string;
  idGroupeAnnonceur?: number;
}

export class ContractCampaign {
  id: string;
  name: string;
  campaignYear: string;
  advertiserId: number;

  constructor(jsonContractCampaign: JsonContractCampaign = null) {
    this.id = jsonContractCampaign.annCampagne + '-' + jsonContractCampaign.norCampagne;
    this.name = jsonContractCampaign.nomCampagne;
    this.campaignYear = jsonContractCampaign.annCampagne;
    this.advertiserId = jsonContractCampaign.idGroupeAnnonceur;
  }
}
