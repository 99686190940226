import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs/index";
import { FacadeService } from "@core/services/facade.service";
import { environment } from "@env/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private facadeService: FacadeService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.url.match(environment.api_base_url)) {
      return next.handle(req);
    }
    return this.facadeService.auth.authenticateRequest(req, next);
  }
}
