import { Secodip } from "@core/models/secodip.model";

export interface JsonTvParamNbSecoByScreen {
  nbConcurency?: number;
  _embedded?: object;
  secodip?: Secodip;
}

export class TvParamNbSecoByScreen {
  public nbConcurency: number;
  public secodip: Secodip;

  constructor(jsonTvParamNbSecoByScreen: JsonTvParamNbSecoByScreen = null) {
    this.nbConcurency = jsonTvParamNbSecoByScreen.nbConcurency;

    if (jsonTvParamNbSecoByScreen.secodip) {
      this.secodip = jsonTvParamNbSecoByScreen.secodip;
    } else if (
      jsonTvParamNbSecoByScreen._embedded &&
      jsonTvParamNbSecoByScreen._embedded["secodip"]
    ) {
      this.secodip = new Secodip(
        jsonTvParamNbSecoByScreen._embedded["secodip"]
      );
    }
  }
}
