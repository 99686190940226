import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

export interface NavItem {
  label: string;
  icon: string;
  url: string;
  internal?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  constructor(private http: HttpClient) {}

  getNavMenu() {
    return this.http.get("assets/mocks/nav.json");
  }
}
