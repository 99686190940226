import { Injectable, Injector } from "@angular/core";
import { AuthService } from "./auth.service";
import { SecodipService } from "./secodip.service";
import { NavService } from "./nav.service";
import { ParamAppliRefService } from "./param-appli-ref.service";
import { ScreenService } from "@core/services/screen.service";
import { ChannelSecodipService } from "./channel-secodip.service";
import { TvParamNbSecoByScreenService } from "@core/services/tv-param-nb-seco-by-screen.service";
import { TvParamForbiddenScreenService } from "@core/services/tv-param-forbidden-screen.service";
import { PositionService } from "@core/services/position.service";
import { SpotCampaignService } from "@core/services/spot-campaign.service";
import { FormatSubService } from "@core/services/format-sub.service";
import { TvMoteurLogService } from "@core/services/tv-moteur-log.service";
import { AlertService } from "@core/services/alert.service";
import { CroiseTvDigitalService } from "@core/services/croise-tv-digital.service";
import { EmplacementService } from "@core/services/emplacement.service";
import { KantarService } from "@core/services/kantar.service";
import { MediametrieService } from "@core/services/mediametrie.service";

@Injectable({
  providedIn: "root",
})
export class FacadeService {
  private authService: AuthService;
  get auth(): AuthService {
    if (!this.authService) {
      this.authService = this.injector.get(AuthService);
    }
    return this.authService;
  }

  private navService: NavService;
  get nav(): NavService {
    if (!this.navService) {
      this.navService = this.injector.get(NavService);
    }
    return this.navService;
  }

  private ParamAppliRefService: ParamAppliRefService;
  get ParamAppliRef(): ParamAppliRefService {
    if (!this.ParamAppliRefService) {
      this.ParamAppliRefService = this.injector.get(ParamAppliRefService);
    }
    return this.ParamAppliRefService;
  }

  private SecodipService: SecodipService;
  get Secodip(): SecodipService {
    if (!this.SecodipService) {
      this.SecodipService = this.injector.get(SecodipService);
    }
    return this.SecodipService;
  }

  private ScreenService: ScreenService;
  get Screen(): ScreenService {
    if (!this.ScreenService) {
      this.ScreenService = this.injector.get(ScreenService);
    }
    return this.ScreenService;
  }

  private ChannelSecodipService: ChannelSecodipService;
  get ChannelSecodip(): ChannelSecodipService {
    if (!this.ChannelSecodipService) {
      this.ChannelSecodipService = this.injector.get(ChannelSecodipService);
    }
    return this.ChannelSecodipService;
  }

  private TvParamNbSecoByScreenService: TvParamNbSecoByScreenService;
  get TvParamNbSecoByScreen(): TvParamNbSecoByScreenService {
    if (!this.TvParamNbSecoByScreenService) {
      this.TvParamNbSecoByScreenService = this.injector.get(
        TvParamNbSecoByScreenService
      );
    }
    return this.TvParamNbSecoByScreenService;
  }

  private TvParamForbiddenScreenService: TvParamForbiddenScreenService;
  get TvParamForbiddenScreen(): TvParamForbiddenScreenService {
    if (!this.TvParamForbiddenScreenService) {
      this.TvParamForbiddenScreenService = this.injector.get(
        TvParamForbiddenScreenService
      );
    }
    return this.TvParamForbiddenScreenService;
  }

  private PositionService: PositionService;
  get Position(): PositionService {
    if (!this.PositionService) {
      this.PositionService = this.injector.get(PositionService);
    }
    return this.PositionService;
  }

  private SpotCampaignService: SpotCampaignService;
  get SpotCampaign(): SpotCampaignService {
    if (!this.SpotCampaignService) {
      this.SpotCampaignService = this.injector.get(SpotCampaignService);
    }
    return this.SpotCampaignService;
  }

  private formatSubService: FormatSubService;
  get Format(): FormatSubService {
    if (!this.formatSubService) {
      this.formatSubService = this.injector.get(FormatSubService);
    }
    return this.formatSubService;
  }

  private tvMoteurLogService: TvMoteurLogService;
  get TvMoteurLog(): TvMoteurLogService {
    if (!this.tvMoteurLogService) {
      this.tvMoteurLogService = this.injector.get(TvMoteurLogService);
    }
    return this.tvMoteurLogService;
  }

  private alertService: AlertService;
  get Alert(): AlertService {
    if (!this.alertService) {
      this.alertService = this.injector.get(AlertService);
    }
    return this.alertService;
  }

  private croiseTvDigitalService: CroiseTvDigitalService;
  get CroiseTvDigital(): CroiseTvDigitalService {
    if (!this.croiseTvDigitalService) {
      this.croiseTvDigitalService = this.injector.get(CroiseTvDigitalService);
    }
    return this.croiseTvDigitalService;
  }

  private EmplacementService: EmplacementService;
  get Emplacement(): EmplacementService {
    if (!this.EmplacementService) {
      this.EmplacementService = this.injector.get(EmplacementService);
    }
    return this.EmplacementService;
  }

  private mediametrieService: MediametrieService;
  get Mediametrie(): MediametrieService {
    if (!this.mediametrieService) {
      this.mediametrieService = this.injector.get(MediametrieService);
    }
    return this.mediametrieService;
  }

  private kantarService: KantarService;
  get Kantar(): KantarService {
    if (!this.kantarService) {
      this.kantarService = this.injector.get(KantarService);
    }
    return this.kantarService;
  }

  CroiseTvDigitalService;

  constructor(private injector: Injector) {}
}
