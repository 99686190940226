import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BatchUpdateService } from '@core/services/batch-update/batch-update.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TvFileUploadPopupComponent } from '@shared/dialog/tv-file-upload-popup/tv-file-upload-popup.component';

@Component({
  templateUrl: './tv-file-upload-popup.component.html',
  styleUrls: ['./tv-file-upload-popup.component.scss'],
})
export class TvFileUploadPopupProgComponent extends TvFileUploadPopupComponent{
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TvFileUploadPopupProgComponent>,
    protected batchUpdateService: BatchUpdateService,
    protected snackBar: MatSnackBar
  ) {
    super(data, dialogRef, batchUpdateService, snackBar)
  }

  onImportClick() {
    this.loading = true;
    this.batchUpdateService
      .importNbcProgFile(this.file.name, this.channelCode)
      .subscribe(fileName => {
        this.loading = false;
        this.caller.onImportFinished(this.file.name);
        this.file = null;
        this.onButtonClick(false);
      },
        error => {
          this.loading = false;
          this.file = null;
          if(error.status == 200) {
            this.snackBar.open('Le fichier a été importé avec succès.',
              null, { duration: 2000, verticalPosition: 'top', panelClass: ['tv-chip-success'] });
          } else {
            this.snackBar.open('Une erreur est survenue lors du traitement du fichier.',
              null, { duration: 2000, verticalPosition: 'top', panelClass: ['chip-error'] });
          }
        });
  }
}
