import * as moment from 'moment';

export interface DateEvalTV {
  start: moment.Moment;
  end: moment.Moment;
}

export interface JsonPlanProduct {
  productId: number;
  productName: string;
}

export class PlanProduct {
  productId: number;
  productName: string;

  constructor(jsonPlanProduct: JsonPlanProduct) {
    if (jsonPlanProduct.productId) {
      this.productId = jsonPlanProduct.productId;
    }

    if (jsonPlanProduct.productName) {
      this.productName = jsonPlanProduct.productName;
    }
  }
}

export interface JsonPlanCampaign {
  id: number;
  campaignId: number;
  campaignNum: number;
  campaignYear: number;
  productId: number;
}

export class PlanCampaign {
  id: number;
  campaignId: number;
  campaignNum: number;
  campaignYear: number;
  productId: number;

  constructor(jsonPlanCampaign: JsonPlanCampaign) {
    if (jsonPlanCampaign.id) {
      this.id = jsonPlanCampaign.id;
    }

    if (jsonPlanCampaign.campaignId) {
      this.campaignId = jsonPlanCampaign.campaignId;
    }

    if (jsonPlanCampaign.campaignNum) {
      this.campaignNum = jsonPlanCampaign.campaignNum;
    }

    if (jsonPlanCampaign.campaignYear) {
      this.campaignYear = jsonPlanCampaign.campaignYear;
    }

    if (jsonPlanCampaign.productId) {
      this.productId = jsonPlanCampaign.productId;
    }
  }
}

export interface JsonPlanChannel {
  id: number;
  channelId: number;
  channelName: string;
  areaId: string;
}

export class PlanChannel {
  id: number;
  channelId: number;
  channelName: string;
  areaId: string;

  constructor(jsonPlanChannel: JsonPlanChannel) {
    if (jsonPlanChannel.id) {
      this.id = jsonPlanChannel.id;
    }

    if (jsonPlanChannel.channelId) {
      this.channelId = jsonPlanChannel.channelId;
    }

    if (jsonPlanChannel.channelName) {
      this.channelName = jsonPlanChannel.channelName;
    }

    if (jsonPlanChannel.areaId) {
      this.areaId = jsonPlanChannel.areaId;
    }
  }
}

export interface JsonPlanTarget {
  id: number;
  targetId: string;
}

export class PlanTarget {
  id: number;
  targetId: string;

  constructor(jsonPlanTarget: JsonPlanTarget) {
    if (jsonPlanTarget.id) {
      this.id = jsonPlanTarget.id;
    }

    if (jsonPlanTarget.targetId) {
      this.targetId = jsonPlanTarget.targetId;
    }
  }
}

export interface JsonPlanSpot {
  id?: number;
  spotNumber: number;
  areaId: string;
  brutPrice: number;
  campaignId: number;
  campaignNum: number;
  campaignYear: number;
  cgrpNet30s: number;
  cgrpNet20s: number;
  channelId: string;
  grp: number;
  negoRate: number;
  grpRate: number;
  priceRate: number;
  netPrice: number;
  realScreenDay: string;
  displayScreenDay: string;
  screenDuration: number;
  screenHour: string;
  tauxTarif: string;
  tauxTarif30s: string;
  tauxTarif20s: string;
  spotAbattement: string;
  campaignAbattement: string;
  isRegional: string;
  isInDemande: number;
}

export class planMassInfo {
  status?: number;
  message?: string;
}

export class PlanSpot extends planMassInfo {
  id: number;
  spotNumber: number;
  areaId: string;
  brutPrice: number;
  campaignId: number;
  campaignNum: number;
  campaignYear: number;
  cgrpNet30s: number;
  cgrpNet20s: number;
  channelId: string;
  negoRate: number;
  grpRate: number;
  priceRate: number;
  grp: number;
  netPrice: number;
  realScreenDay: string;
  displayScreenDay: string;
  screenDuration: number;
  screenHour: string;
  tauxTarif: string;
  tauxTarif30s: string;
  tauxTarif20s: string;
  isBookable = false;
  spotAbattement: string;
  campaignAbattement: string;
  isRegional: string;
  position?: number;
  isInDemande = 0;

  constructor(jsonPlanSpot: JsonPlanSpot) {
    super();

    if (jsonPlanSpot.id) {
      this.id = jsonPlanSpot.id;
    }

    if (jsonPlanSpot.spotNumber) {
      this.spotNumber = jsonPlanSpot.spotNumber;
    }

    if (jsonPlanSpot.areaId) {
      this.areaId = jsonPlanSpot.areaId;
    }

    if (jsonPlanSpot.brutPrice) {
      this.brutPrice = jsonPlanSpot.brutPrice;
    }

    if (jsonPlanSpot.cgrpNet30s) {
      this.cgrpNet30s = jsonPlanSpot.cgrpNet30s;
    }

    if (jsonPlanSpot.cgrpNet20s) {
      this.cgrpNet20s = jsonPlanSpot.cgrpNet20s;
    }

    if (jsonPlanSpot.channelId) {
      this.channelId = jsonPlanSpot.channelId;
    }

    if (jsonPlanSpot.campaignNum) {
      this.campaignNum = Number(jsonPlanSpot.campaignNum);
    }

    if (jsonPlanSpot.grp) {
      this.grp = jsonPlanSpot.grp;
    }

    if (jsonPlanSpot.negoRate !== null && jsonPlanSpot.negoRate !== undefined) {
      this.negoRate = Number(jsonPlanSpot.negoRate);
    }

    if (jsonPlanSpot.grpRate !== null && jsonPlanSpot.grpRate !== undefined) {
      this.grpRate = Number(jsonPlanSpot.grpRate);
    }

    if (jsonPlanSpot.priceRate !== null && jsonPlanSpot.priceRate !== undefined) {
      this.priceRate = Number(jsonPlanSpot.priceRate);
    }

    if (jsonPlanSpot.netPrice) {
      this.netPrice = jsonPlanSpot.netPrice;
    }

    if (jsonPlanSpot.realScreenDay) {
      this.realScreenDay = jsonPlanSpot.realScreenDay;
    }

    if (jsonPlanSpot.displayScreenDay) {
      this.displayScreenDay = jsonPlanSpot.displayScreenDay;
    }

    if (jsonPlanSpot.screenHour) {
      this.screenHour = jsonPlanSpot.screenHour;
    }

    if (jsonPlanSpot.screenDuration) {
      this.screenDuration = jsonPlanSpot.screenDuration;
    }

    if (jsonPlanSpot.campaignId) {
      this.campaignId = jsonPlanSpot.campaignId;
    }

    if (jsonPlanSpot.campaignYear) {
      this.campaignYear = jsonPlanSpot.campaignYear;
    }

    if (jsonPlanSpot.tauxTarif) {
      this.tauxTarif = jsonPlanSpot.tauxTarif;
    }

    if (jsonPlanSpot.tauxTarif30s) {
      this.tauxTarif30s = jsonPlanSpot.tauxTarif30s;
    }

    if (jsonPlanSpot.tauxTarif20s) {
      this.tauxTarif20s = jsonPlanSpot.tauxTarif20s;
    }

    if (jsonPlanSpot.spotAbattement) {
      this.spotAbattement = jsonPlanSpot.spotAbattement;
    }

    if (jsonPlanSpot.campaignAbattement) {
      this.campaignAbattement = jsonPlanSpot.campaignAbattement;
    }

    if (jsonPlanSpot.isRegional) {
      this.isRegional = jsonPlanSpot.isRegional;
    }

    if (jsonPlanSpot.isInDemande) {
      this.isInDemande = +jsonPlanSpot.isInDemande;
    }
  }
}

export interface JsonPlanPerformance {
  id?: number;
  title: string;
  startPeriod: string;
  endPeriod: string;
  productId: number;
  targetId: string;
  productName?: string;
  couverture?: number;
  campaigns: JsonPlanCampaign[];
  channels: JsonPlanChannel[];
  targets: JsonPlanTarget[];
  spots: JsonPlanSpot[];
  createUser: string;
  updateUser: string;
}

export class PlanPerformance {
  id?: number;
  title: string;
  startPeriod: string;
  endPeriod: string;
  productId: number;
  targetId: string;
  productName?: string;
  couverture?: number;
  campaigns: PlanCampaign[];
  channels: PlanChannel[];
  targets: PlanTarget[];
  spots: PlanSpot[];
  createUser: string;
  updateUser?: string;

  constructor(jsonPlanPerformance: JsonPlanPerformance) {
    if (jsonPlanPerformance.id) {
      this.id = jsonPlanPerformance.id;
    }

    if (jsonPlanPerformance.title) {
      this.title = jsonPlanPerformance.title;
    }

    if (jsonPlanPerformance.startPeriod) {
      this.startPeriod = jsonPlanPerformance.startPeriod;
    }

    if (jsonPlanPerformance.endPeriod) {
      this.endPeriod = jsonPlanPerformance.endPeriod;
    }

    if (jsonPlanPerformance.productId) {
      this.productId = jsonPlanPerformance.productId;
    }

    if (jsonPlanPerformance.productName) {
      this.productName = jsonPlanPerformance.productName;
    }

    if (jsonPlanPerformance.couverture) {
      this.couverture = jsonPlanPerformance.couverture;
    }

    if (jsonPlanPerformance.campaigns) {
      this.campaigns = jsonPlanPerformance.campaigns.map((jsonPlanCampaign: JsonPlanCampaign) => new PlanCampaign(jsonPlanCampaign));
    }

    if (jsonPlanPerformance.channels) {
      this.channels = jsonPlanPerformance.channels.map((jsonPlanChannel: JsonPlanChannel) => new PlanChannel(jsonPlanChannel));
    }

    if (jsonPlanPerformance.targetId) {
      this.targetId = jsonPlanPerformance.targetId;
    }

    if (jsonPlanPerformance.targets) {
      // To order targets array by main target in first position
      const targetOrder = [this.targetId];

      this.targets = jsonPlanPerformance.targets
        .sort((a: JsonPlanTarget, b: JsonPlanTarget) => targetOrder.indexOf(b.targetId) - targetOrder.indexOf(a.targetId))
        .map((jsonPlanTarget: JsonPlanTarget) => new PlanTarget(jsonPlanTarget));
    }

    if (jsonPlanPerformance.spots) {
      this.spots = jsonPlanPerformance.spots.map((jsonPlanSpot: JsonPlanSpot) => new PlanSpot(jsonPlanSpot));
    }

    if (jsonPlanPerformance.createUser) {
      this.createUser = jsonPlanPerformance.createUser;
    }

    if (jsonPlanPerformance.updateUser) {
      this.updateUser = jsonPlanPerformance.updateUser;
    }
  }
}

export interface JsonListPlan {
  id?: number;
  plan_name?: string;
  product?: string;
  advertiser?: string;
  campaigns?: string;
  channels?: string;
  start_period?: string;
  end_period?: string;
  create_date?: string;
  create_user?: string;
}

export class ListPlan {
  id?: number;
  planName?: string;
  product?: string;
  advertiser?: string;
  campaigns?: string;
  channels?: string[] = [];
  startPeriod?: string;
  endPeriod?: string;
  createDate?: string;
  createUser?: string;

  constructor(jsonListPlan: JsonListPlan) {
    if (jsonListPlan?.id) {
      this.id = jsonListPlan.id;
    }

    if (jsonListPlan?.plan_name) {
      this.planName = jsonListPlan.plan_name;
    }

    if (jsonListPlan?.product) {
      this.product = jsonListPlan.product;
    }

    if (jsonListPlan?.advertiser) {
      this.advertiser = jsonListPlan.advertiser;
    }

    if (jsonListPlan?.campaigns) {
      this.campaigns = jsonListPlan.campaigns;
    }

    if (jsonListPlan?.channels) {
      this.channels = jsonListPlan.channels.replace(/\s/g, '').split(',');
    }

    if (jsonListPlan?.start_period) {
      this.startPeriod = moment(jsonListPlan.start_period, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }

    if (jsonListPlan?.end_period) {
      this.endPeriod = moment(jsonListPlan.end_period, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }

    if (jsonListPlan?.create_date) {
      this.createDate = moment(jsonListPlan.create_date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }

    if (jsonListPlan?.create_user) {
      this.createUser = jsonListPlan.create_user;
    }
  }
}
