import { ContractCgrpOffCondition } from '@core/models/contract-cgrp-off-condition.model';
import { Contract } from './contract.model';
import { Target } from './target.model';

export interface JsonContractCgrpOff {
  id?: number;
  idContract?: number;
  targetCode?: string;
  startDate?: any;
  endDate?: any;
  value?: string;
  contractItem?: Contract;
  conditions?: ContractCgrpOffCondition[];
  target?: Target;
}

export class ContractCgrpOff {
  id: number;
  idContract: number;
  targetCode: string;
  startDate: string;
  endDate: string;
  value: string;
  conditions?: ContractCgrpOffCondition[];
  target?: Target;

  constructor(jsonContractCgrpOff: JsonContractCgrpOff = null) {
    this.id = jsonContractCgrpOff.id;
    this.idContract = jsonContractCgrpOff.idContract;
    this.targetCode = jsonContractCgrpOff.targetCode;
    this.startDate = this.getDate(jsonContractCgrpOff.startDate.date);
    this.endDate = this.getDate(jsonContractCgrpOff.endDate.date);

    this.value = jsonContractCgrpOff.value;
    this.conditions = jsonContractCgrpOff.conditions;
    this.target = jsonContractCgrpOff.target;
  }

  getDate(str) {
    str = str.split(' ');
    if (str.length > 0) {
      return str[0].replaceAll('-', '/');
    }

    return '';
  }
}
