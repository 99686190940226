import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';

export interface TableFilterItem {
  id: string;
  label: string;
  isActive: boolean;
}

@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.scss'],
})
export class FilterPopupComponent implements OnInit {
  public showDropDown = false;
  @Input() onFilterItemClick: () => void;
  @Input() filterItems;
  public filterItemsToDisplay: TableFilterItem[] = [];

  constructor(private eRef: ElementRef) {}

  ngOnInit(): void {
    this.prepareItemsToDisplay();
  }
  prepareItemsToDisplay() {
    if (this.filterItems) {
      for (const key in this.filterItems) {
        if (this.filterItems[key].canBeHidden && this.filterItems[key].label) {
          this.filterItemsToDisplay.push({ id: key, label: this.filterItems[key].label, isActive: this.filterItems[key].isActive });
        }
      }
    }
  }

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (
      (this.eRef.nativeElement.contains(event.target) && this.showDropDown === true) ||
      (!this.eRef.nativeElement.contains(event.target) && this.showDropDown === false)
    ) {
      return;
    } else this.showDropDown = !!this.eRef.nativeElement.contains(event.target);
  }

  onShowFilterDropDownButtonClick() {
    this.showDropDown = !this.showDropDown;
  }
  onCheckBoxChange(filterItem: TableFilterItem) {
    filterItem.isActive = !filterItem.isActive;
    this.filterItems[filterItem.id].isActive = filterItem.isActive;
    this.onFilterItemClick();
  }
}
