import { Directive, ElementRef, HostListener } from '@angular/core';
import Inputmask from 'inputmask';

@Directive({
  selector: '[thousandsSeparators]'
})
export class ThousandsSeparatorsDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    let inputValue: string = event.target.value;
    inputValue = inputValue.replace(/[^0-9.]/g, '');
    const formattedValue = this.formatNumber(inputValue);
    this.el.nativeElement.value = formattedValue;
  }
  ngOnInit() {
    if (this.el.nativeElement.value !== 'Mixte') {
      Inputmask('decimal', {
        radixPoint: ',',
        placeholder: "",
        autoGroup: true,
        groupSeparator: ' ',
        groupSize: 3,
        autoUnmask: true
      }).mask(this.el.nativeElement);
    }
  }

  private formatNumber(value: string): string {
    const numberValue = parseFloat(value);

    if (!isNaN(numberValue)) {
      return numberValue.toLocaleString();
    } else {
      return value;
    }
  }
}