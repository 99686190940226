export interface JsonCategoryType {
  id: number;
  name: string;
}

export class CategoryType {
  id: number;
  name: string;

  constructor(jsonCategoryType: JsonCategoryType = null) {
    this.id = jsonCategoryType.id;
    this.name = jsonCategoryType.name;
  }
}
