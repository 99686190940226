import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { merge, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit {
  @Input() screenFilterForm: FormGroup;
  @Input() styleType: String = 'rounded';
  @Input() paramStartDate: string; // format: 'DD/MM/YYYY'
  @Input() paramEndDate: string; // format: 'DD/MM/YYYY'
  @Output() choosenDates = new EventEmitter<void>();

  constructor(private fb: FormBuilder) {}

  private componentDestroyed$: Subject<void> = new Subject();

  dateRangeForm = this.fb.group({
    startDate: [null as Date | null, [Validators.required]],
    endDate: [null as Date | null, [Validators.required]],
  });

  ngOnInit(): void {
    this.initValueChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paramStartDate || changes.paramEndDate) {
      this.setInitialDateRange();
    }
  }

  private setInitialDateRange() {
    if (this.paramStartDate) {
      const startDate = moment(this.paramStartDate, 'DD/MM/YYYY').toDate();
      this.dateRangeForm.get('startDate').setValue(startDate);
      this.screenFilterForm.get('startDate').setValue(this.paramStartDate);
    }

    if (this.paramEndDate) {
      const endDate = moment(this.paramEndDate, 'DD/MM/YYYY').toDate();
      this.dateRangeForm.get('endDate').setValue(endDate);
      this.screenFilterForm.get('endDate').setValue(this.paramEndDate);
    }
  }

  public initValueChange() {
    merge(this.dateRangeForm.get('startDate').valueChanges, this.dateRangeForm.get('endDate').valueChanges)
      .pipe(debounceTime(500), takeUntil(this.componentDestroyed$))
      .subscribe(
        () => {
          const startDate = moment(this.dateRangeForm.value.startDate).format('DD/MM/YYYY');
          const endDate = moment(this.dateRangeForm.value.endDate).format('DD/MM/YYYY');

          this.screenFilterForm.get('startDate').setValue(startDate);
          this.screenFilterForm.get('endDate').setValue(endDate);

          if (startDate) {
            this.choosenDates.emit();
          }
        },
        (error: Error) => {
          console.error(error);
        }
      );
  }
}
