import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TimeMaskDirective } from "./time-mask.directive";
import { CustomTooltipComponent } from "./popover/custom-tooltip/custom-tooltip.component";
import { CustomPopoverDirective } from "./popover/custom-popover.directive";
import { OverlayModule } from "@angular/cdk/overlay";
import { ThousandsSeparatorsDirective } from "./thousands-separators.directive";

@NgModule({
  declarations: [
    TimeMaskDirective,
    ThousandsSeparatorsDirective,
    CustomTooltipComponent,
    CustomPopoverDirective,
  ],
  imports: [CommonModule, OverlayModule],
  exports: [
    TimeMaskDirective,
    ThousandsSeparatorsDirective,
    CustomPopoverDirective,
    CustomTooltipComponent,
    OverlayModule,
  ],
})
export class DirectivesModule {}
