import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CampaignDisplayChannel } from '@core/models/campaign-display/campaign-display.model';

@Component({
  selector: 'app-region-dialog',
  templateUrl: './region-dialog.component.html',
  styleUrls: ['./region-dialog.component.scss'],
})
export class RegionDialogComponent implements OnInit {
  @Input() channels: CampaignDisplayChannel[];

  constructor(
    @Optional() public dialogRef: MatDialogRef<RegionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CampaignDisplayChannel[]
  ) {}

  ngOnInit(): void {}

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
