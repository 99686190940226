import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

export interface CustomPaginator {
  length: number;
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, OnChanges, OnDestroy {
  private defaultPageSizeOptions = [5, 10, 20, 50];
  @Input() label = 'Items par page :';
  @Input() pageSize = 0;
  @Input() length = 0;
  @Input() pageSizeOptions;

  @Output() pageChangeEvent = new EventEmitter<CustomPaginator>();

  itemsPerPageControl = new FormControl();
  private itemsPerPageControlValueChangeSubscription: Subscription;

  pageIndex = 0;
  upperCurrentPageItem = 0;
  lowerCurrentPageItem = 0;
  numberOfPages: number;

  customPaginatorObj: CustomPaginator = {
    length: this.length,
    pageIndex: this.pageIndex,
    pageSize: this.pageSize,
  };

  constructor() {}

  ngOnInit(): void {
    this.setDefaultPageSizeOption();
    this.handleItemsPerPageControlValueChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.length?.currentValue) {
      this.getNumberOfPages();
      this.getUpperAndLowerCurrentPageItem();
    }
  }

  ngOnDestroy() {
    if (this.itemsPerPageControlValueChangeSubscription) {
      this.itemsPerPageControlValueChangeSubscription.unsubscribe();
    }
  }

  setDefaultPageSizeOption(): void {
    let defaultOption;
    if (!this.pageSizeOptions || this.pageSizeOptions.length === 0) {
      this.pageSizeOptions = this.defaultPageSizeOptions;
    }

    let givenDefaultOption = this.pageSizeOptions.find((defOpt: number) => defOpt === this.pageSize);
    if (givenDefaultOption) {
      defaultOption = givenDefaultOption;
    } else {
      defaultOption = this.pageSizeOptions[1];
    }

    this.itemsPerPageControl.setValue(defaultOption);
  }

  getNumberOfPages(): void {
    if (this.length && this.length > 0 && this.pageSize && this.pageSize > 0) {
      this.numberOfPages = Math.floor(this.length / this.pageSize);

      if (this.length % this.pageSize) {
        this.numberOfPages++;
      }
    }
  }

  getUpperAndLowerCurrentPageItem(): void {
    if (!this.pageSize || !this.numberOfPages || !this.length || this.pageIndex + 1 > this.numberOfPages || this.pageIndex < 0) {
      return;
    }

    if (this.pageIndex + 1 === this.numberOfPages) {
      this.upperCurrentPageItem = this.length;
      this.lowerCurrentPageItem = this.length - (this.length % this.pageSize) + 1;
    } else {
      this.upperCurrentPageItem = (this.pageIndex + 1) * this.pageSize;
      this.lowerCurrentPageItem = this.upperCurrentPageItem - (this.pageSize - 1);
    }
  }

  onPreviousNextPageButtonClick(isNextPage: boolean): void {
    if ((this.pageIndex + 1 > this.numberOfPages && isNextPage) || (this.pageIndex - 1 < 0 && !isNextPage)) {
      return;
    }

    isNextPage ? this.pageIndex++ : this.pageIndex--;

    this.setCustomPaginatorObjValues();
    this.pageChangeEvent.emit(this.customPaginatorObj);
    this.getNumberOfPages();
    this.getUpperAndLowerCurrentPageItem();
  }

  handleItemsPerPageControlValueChange(): void {
    this.itemsPerPageControlValueChangeSubscription = this.itemsPerPageControl.valueChanges.subscribe(value => {
      this.pageIndex = 0;
      this.pageSize = value;

      this.setCustomPaginatorObjValues();
      this.pageChangeEvent.emit(this.customPaginatorObj);
      this.getNumberOfPages();
      this.getUpperAndLowerCurrentPageItem();
    });
  }

  setCustomPaginatorObjValues(): void {
    this.customPaginatorObj.length = this.length;
    this.customPaginatorObj.pageIndex = this.pageIndex;
    this.customPaginatorObj.pageSize = this.pageSize;
  }
}
