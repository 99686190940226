import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-file-upload-input',
  templateUrl: './file-upload-input.component.html',
  styleUrls: ['./file-upload-input.component.scss'],
})
export class FileUploadInputComponent implements OnChanges {
  @Input() requiredFileType: string;
  @Input() uploadProgress: BehaviorSubject<Number>;
  @Input() shouldEmptyFileUploadInput = false;
  @Output() fileUploadEvent = new EventEmitter<File>();

  @ViewChild('fileUpload') fileUploadRef!: ElementRef;

  fileName: string;
  counter: number = 0;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.shouldEmptyFileUploadInput?.currentValue) {
      this.resetFileInput();
    }
  }

  onFileSelected(event) {
    this.uploadProgress.next(0);
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      const file: File = event.target.files[0];
      this.fileName = file.name;

      this.fileUploadEvent.emit(file);
    }
  }

  resetFileInput() {
    if (this.fileUploadRef) {
      const fileInput: HTMLInputElement = this.fileUploadRef.nativeElement;
      fileInput.value = '';
      this.fileName = null;
    }
  }
}
