import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegGroupId } from '@app/app.constants';
import { TvChannel, TvChannelGroup } from '@core/models/tv-channel.model';
import { BaseService } from '@core/services/base/base.service';
import { ErrorHandlerService, HttpError } from '@core/services/error-handler.service';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export const ChannelGroups: TvChannelGroup[] = [
  new TvChannelGroup({
    id: null,
    channelGroup: 'FavoriteChannels',
    isNat: false,
    name: 'Favoris',
  }),
  new TvChannelGroup({
    id: null,
    channelGroup: 'AllChannels',
    isNat: false,
    name: 'Toutes les chaînes',
  }),
];
export const FakeRegChannel: TvChannel = {
  id: 999,
  name: 'France 3 Région',
  channelCode: '3',
  regionCode: '',
  isRegion: true,
  logo: 'https://s3.eu-west-1.amazonaws.com/tv.francetvpub.fr/assets/images/logo_channel/3.png',
  isFavorite: false,
  nomRegie: '',
  editorName: '',
  isNbc: false,
  batchUpdateFileTypes: [],
};

@Injectable({
  providedIn: 'root',
})
export class TvChannelService extends BaseService {
  private httpErrors: HttpError[] = [
    {
      statusCode: 404,
      message: 'Chaînes non trouvées.',
    },
    {
      statusCode: 500,
      message: 'Une erreur est survenue. Veuillez essayer plus tard.',
    },
  ];

  public favoriteChannelGroup: TvChannelGroup = ChannelGroups[0];

  public favoriteChannels: TvChannel[] = [];

  // Used by single channel select
  public currentChannel$: BehaviorSubject<TvChannel | null> = new BehaviorSubject(null);

  // used by single nat channel with multiple region channels select
  public currentChannels$: BehaviorSubject<TvChannel[]> = new BehaviorSubject([]);

  constructor(private httpService: HttpClient, private snackBar: MatSnackBar, private errorHandlerService: ErrorHandlerService) {
    super();
  }

  public getTvChannels(groupName, userId, channelGroupId?, isNat?, shouldShowOnlyNatFullList?): Observable<TvChannel[]> {
    if (!groupName) {
      return;
    }

    let url = environment.api_base_url + this.getUrl(groupName, userId);

    if (groupName === 'Favoris') {
      return this.getFavoriteChannels(url);
    } else {
      return this.getChannels(url, channelGroupId, isNat, shouldShowOnlyNatFullList);
    }
  }

  public updateFavoriteChannelList(userId: string, favoriteChannel: TvChannel): Observable<TvChannel[]> {
    let url = environment.api_base_url + this.getUrl('Favoris', userId);

    if (favoriteChannel.isFavorite) {
      this.favoriteChannels.push(favoriteChannel);
    } else {
      this.favoriteChannels = this.favoriteChannels.filter(favChannel => favChannel.id !== favoriteChannel.id);
    }

    return new Observable(observer => {
      this.httpService
        .patch(url, { favoriteChannels: this.favoriteChannels })
        .pipe(
          map(response => {
            if (response['_embedded']['favoriteChannels'] && Array.isArray(response['_embedded']['favoriteChannels'])) {
              this.favoriteChannels = response['_embedded']['favoriteChannels'].map(channel => new TvChannel(channel, true));
            } else {
              this.favoriteChannels = [];
            }
            return this.favoriteChannels;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(this.httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  // Used by single channel select
  public setCurrentChannel(channel: TvChannel) {
    this.currentChannel$.next(channel);
  }

  public getCurrentChannel(): TvChannel | null {
    return this.currentChannel$.getValue();
  }

  // used by single nat channel with multiple region channels select
  public setCurrentChannels(channels: TvChannel[]) {
    this.currentChannels$.next(channels);
  }

  public getCurrentChannels(): TvChannel[] {
    return this.currentChannels$.getValue();
  }

  public getFavoriteChannels(link: string): Observable<TvChannel[]> {
    return new Observable(observer => {
      this.httpService
        .get(link)
        .pipe(
          map(response => {
            if (
              response['_embedded'] &&
              response['_embedded']['favoriteChannels'] &&
              Array.isArray(response['_embedded']['favoriteChannels'])
            ) {
              this.favoriteChannels = response['_embedded']['favoriteChannels'].map(channel => new TvChannel(channel, true));
            }
            return this.favoriteChannels;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(this.httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  public getChannels(link: string, channelGroupId?: string, isNat?: boolean, shouldShowOnlyNatFullList?: boolean): Observable<TvChannel[]> {
    // Get filtered list by channel group (F3 nat and F3  Reg has the same group)
    if (channelGroupId) {
      link +=
        '?' +
        this.getParamsFromFilter({
          channelGroup: channelGroupId,
          isNat: isNat ? 1 : 0,
        });
    }
    // Get the whole list excluding all F3 reg
    if (shouldShowOnlyNatFullList) {
      link +=
        '?' +
        this.getParamsFromFilter({
          shouldShowOnlyNatFullList: 1,
        });
    }

    return new Observable(observer => {
      this.httpService
        .get(link)
        .pipe(
          map(response => {
            if (response['_embedded'] && response['_embedded']['tv-channels']) {
              const tvChannels = response['_embedded']['tv-channels'].map(channel => {
                return new TvChannel(
                  channel,
                  this.favoriteChannels.find(favoriteChannel => favoriteChannel.id === channel.id)
                );
              });
              //Case when getting no regional channels should insert Fake Reg Channel
              if (shouldShowOnlyNatFullList) {
                const tvChannelF3NatIndex = tvChannels.findIndex(obj => obj.channelCode === '3');
                if (tvChannelF3NatIndex && tvChannelF3NatIndex !== -1) {
                  tvChannels.splice(tvChannelF3NatIndex + 1, 0, FakeRegChannel);
                }
              }
              return tvChannels;
            }
            return [];
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(this.httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  private getUrl(groupName, userId?): string {
    switch (groupName) {
      case 'Favoris':
        return '/tv/tv-user/' + userId;
      case 'Toutes les chaînes':
        return '/tv/tv-channel';
      default:
        return '/tv/tv-channel';
    }
  }

  public getChannelGroups(): Observable<TvChannelGroup[]> {
    let httpErrors: HttpError[] = [
      {
        statusCode: 404,
        message: 'Chaînes non trouvées.',
      },
      {
        statusCode: 500,
        message: 'Une erreur est survenue. Veuillez essayer plus tard.',
      },
    ];
    let url = environment.api_base_url + '/tv/channel-group';
    return new Observable(observer => {
      this.httpService
        .get(url)
        .pipe(
          map(response => {
            if (response['_embedded'] && response['_embedded']['ChannelGroups'] && Array.isArray(response['_embedded']['ChannelGroups'])) {
              return ChannelGroups.concat(response['_embedded']['ChannelGroups'].map(channelGroup => new TvChannelGroup(channelGroup)));
            }
            return ChannelGroups;
          })
        )
        .subscribe(
          (response: any) => observer.next(response),
          error => {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        );
    });
  }

  public getFakeRegChannel() {
    return of([FakeRegChannel]);
  }

  public getRegChannels(userId): Observable<TvChannel[]> {
    let url = environment.api_base_url + this.getUrl('Reg', userId);
    return this.getChannels(url, RegGroupId, false);
  }
}
