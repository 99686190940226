export interface JsonParamAppliRef {
  id?: string;
  nomAppli?: string;
  valeur?: string;
}

export class ParamAppliRef {
  public id: string;
  public nomAppli: string;
  public valeur: string;

  constructor(jsonParamAppliRef: JsonParamAppliRef = null) {
    this.id = jsonParamAppliRef.id;
    this.nomAppli = jsonParamAppliRef.nomAppli;
    this.valeur = jsonParamAppliRef.valeur;
  }
}
