import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { catchError, map } from "rxjs/operators";
import { TvParamNbSecoByScreen } from "@core/models/tv-param-nb-seco-by-screen";

export interface TvParamNbSecoByScreenFilter {
  itemPerPage?: number;
  page?: number;
  sortActive?: string;
  sortDirection?: string;
  search?: string;
}

@Injectable({
  providedIn: "root",
})
export class TvParamNbSecoByScreenService {
  private endpoint = "/tv/tv-param-nb-seco-by-screen";

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  public getList(filter: TvParamNbSecoByScreenFilter): Observable<any> {
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    const url =
      environment.api_base_url + this.endpoint + "?" + params.toString();

    return new Observable((observer) => {
      this.httpService
        .get(url)
        .pipe(
          map((response) => {
            if (response["_embedded"]) {
              const total =
                response["_embedded"]["TvParamNbSecoByScreen"].pop()[
                  "total_count"
                ];
              const list = response["_embedded"]["TvParamNbSecoByScreen"].map(
                (elem) => new TvParamNbSecoByScreen(elem)
              );
              return { list, total };
            }
            return response;
          })
        )
        .subscribe(
          (response) => observer.next(response),
          (error) => observer.error(this.catchError(error))
        );
    });
  }

  public create(
    elem: TvParamNbSecoByScreen
  ): Observable<TvParamNbSecoByScreen> {
    const url = environment.api_base_url + this.endpoint;

    return new Observable((observer) => {
      this.httpService.post(url, elem).subscribe(
        (response) => {
          observer.next(new TvParamNbSecoByScreen(response));
        },
        (error) => {
          this.catchError(error);
          observer.error(false);
        }
      );
    });
  }

  public edit(
    elem: TvParamNbSecoByScreen,
    id: string
  ): Observable<TvParamNbSecoByScreen> {
    const url = environment.api_base_url + this.endpoint + "/" + id;

    return new Observable((observer) => {
      this.httpService.patch(url, elem).subscribe(
        (response) => {
          observer.next(new TvParamNbSecoByScreen(response));
        },
        (error) => {
          this.catchError(error);
          observer.error(false);
        }
      );
    });
  }

  public remove(id: string): Observable<boolean> {
    const url = environment.api_base_url + this.endpoint + "/" + id;

    return new Observable((observer) => {
      this.httpService.delete(url).subscribe(
        () => {
          observer.next(true);
        },
        (error) => {
          this.catchError(error);
          observer.error(false);
        }
      );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open("Aucun secodip n'a été trouvé", null, {
          duration: 2000,
          verticalPosition: "top",
        });
        break;
      case 409:
        this.snackBar.open("Règle déjà existante.", null, {
          duration: 2000,
          verticalPosition: "top",
        });
        break;
      default:
        this.snackBar.open(
          error.error.detail ? error.error.detail : "Une erreur est survenue",
          null,
          { duration: 2000, verticalPosition: "top" }
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
