import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContractComment } from '@core/models/contract-comment/contract-comment.model';

@Component({
  selector: 'app-comment-edit-dialog',
  templateUrl: './comment-edit-dialog.component.html',
  styleUrls: ['./comment-edit-dialog.component.scss'],
})
export class CommentEditDialogComponent {
  comment: ContractComment;
  formattedCommentDate: string;

  constructor(
    public dialogRef: MatDialogRef<CommentEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { comment: ContractComment },
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {
    this.comment = { ...data.comment };
    this.formattedCommentDate = this.formatDate(this.ensureDate(this.comment.commentDate));
  }

  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  ensureDate(date: string | Date): Date {
    if (typeof date === 'string') {
      return new Date(date);
    } else {
      return date;
    }
  }

  onSave(): void {
    this.dialogRef.close(this.comment);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
