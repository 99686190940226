export interface JsonSpotCampaign {
  annonceur: string;
  codChaine: string;
  codRegion: string;
  codSecodip: string;
  datEcran: any;
  herEcran: string;
  idPurchase: number;
  libSecodip: string;
  nomProduit: string;
  norSpot: number;
  numClient: number;
  numFilm: number;
  numProduit: number;
  purchaseTitle: string;
}

export class SpotCampaign {
  public annonceur: string;
  public codChaine: string;
  public codRegion: string;
  public codSecodip: string;
  public datEcran: any;
  public herEcran: string;
  public idPurchase: number;
  public libSecodip: string;
  public nomProduit: string;
  public norSpot: number;
  public numClient: number;
  public numFilm: number;
  public numProduit: number;
  public purchaseTitle: string;
  constructor(jsonSpotCampaign: JsonSpotCampaign = null) {
    this.annonceur = jsonSpotCampaign.annonceur;
    this.codChaine = jsonSpotCampaign.codChaine;
    this.codRegion = jsonSpotCampaign.codRegion;
    this.codSecodip = jsonSpotCampaign.codSecodip;
    this.datEcran = jsonSpotCampaign.datEcran;
    this.herEcran = jsonSpotCampaign.herEcran;
    this.idPurchase = jsonSpotCampaign.idPurchase;
    this.libSecodip = jsonSpotCampaign.libSecodip;
    this.nomProduit = jsonSpotCampaign.nomProduit;
    this.norSpot = jsonSpotCampaign.norSpot;
    this.numClient = jsonSpotCampaign.numClient;
    this.numFilm = jsonSpotCampaign.numFilm;
    this.numProduit = jsonSpotCampaign.numProduit;
    this.purchaseTitle = jsonSpotCampaign.purchaseTitle;
  }
}
