import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TvConfirmationData } from '@core/models/tv-confirmation-data.model';

@Component({
  selector: 'app-tv-confirm-popup',
  templateUrl: './tv-confirm-popup.component.html',
  styleUrls: ['./tv-confirm-popup.component.scss'],
})
export class TvConfirmPopupComponent implements OnInit {
  public title = '';
  public content = '';
  public cancelButtonTitle = 'Annuler';
  public okButtonTitle = 'Confirmer';

  constructor(@Inject(MAT_DIALOG_DATA) public data: TvConfirmationData, public dialogRef: MatDialogRef<TvConfirmPopupComponent>) {}

  ngOnInit(): void {
    if (this.data) {
      if (this.data.title) {
        this.title = this.data.title;
      }
      if (this.data.content) {
        this.content = this.data.content;
      }
      if (this.data.cancelButtonTitle) {
        this.cancelButtonTitle = this.data.cancelButtonTitle;
      }
      if (this.data.okButtonTitle) {
        this.okButtonTitle = this.data.okButtonTitle;
      }
    }
  }

  onButtonClick(confirmation: boolean) {
    this.dialogRef.close(confirmation);
  }
}
