import { Directive, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[formControlName][appTimeMask]",
})
export class TimeMaskDirective {
  constructor(public control: NgControl) {}

  @HostListener("ngModelChange", ["$event"])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener("keydown.backspace", ["$event"])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  private onInputChange(event, backspace) {
    let newVal = event.replace(/\D/g, "");

    if (newVal.length >= 5) {
      newVal = [newVal.slice(0, 2), ":", newVal.slice(2)].join("");
      return this.control.valueAccessor.writeValue(newVal.slice(0, 5));
    }

    if (newVal.length === 0) {
      newVal = "";
    } else if (newVal.length >= 3) {
      newVal = [newVal.slice(0, 2), ":", newVal.slice(2)].join("");
    }

    return this.control.valueAccessor.writeValue(newVal.slice(0, 5));
  }
}
