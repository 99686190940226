import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface HttpError {
  statusCode: number;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private snackBar: MatSnackBar) {}

  public showErrorMessage(httpErrors: HttpError[], statusCode: number, defaultMessage?) {
    let messageToShow = 'Une erreur est survenue. Veuillez essayer plus tard.';

    if (defaultMessage) {
      messageToShow = defaultMessage;
    }

    if (httpErrors.length > 0) {
      httpErrors.map(error => {
        if (error.statusCode === statusCode) {
          messageToShow = error.message;
        }
      });
    }

    this.snackBar.open(messageToShow, null, {
      duration: 2000,
      verticalPosition: 'top',
      panelClass: ['chip-error'],
    });
  }
}
