import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import {MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { TargetService } from '@core/services/target.service';
import { CategoryType } from '@core/models/category-type.model';
import { NegotiationType } from '@core/models/negotiation-type.model';
import { map, startWith, takeUntil, debounceTime } from 'rxjs/operators';
import { FilteredTarget, Target } from '@core/models/target.model';
import { CategoryTypeService } from '@core/services/category-type/category-type.service';
import { ContractCategoryTypeService } from '@core/services/contract-category-type/contract-category-type.service';
import { FilteredItem } from '@core/models/filtered-item.model';
import { Contract } from '@core/models/contract.model';
import { ContractNatioNego } from '@core/models/contract-natio-nego.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContractCgrpOffService } from '@core/services/contract-cgrp-off/contract-cgrp-off.service';
import { ContractCgrpOff } from '@core/models/contract-cgrp-off.model';
import * as moment from 'moment';

@Component({
  selector: 'app-cgrp-off-managing-dialog',
  templateUrl: './cgrp-off-managing-dialog.component.html',
  styleUrls: ['./cgrp-off-managing-dialog.component.scss'],
  standalone: false,
})
export class CGRPOffManagingDialog implements OnInit {
  noCategoryBlockAdded: boolean = true;
  categoryConditionType: string = null;
  categories = [
    { value: 'Product', viewValue: 'Produit (Pr)' },
    { value: 'Advertiser', viewValue: 'Annonceur (An)' },
    { value: 'Specific', viewValue: 'Specific (Sp)' },
  ];
  @Input() cgrpOffData: any;
  public filteredItemList: Observable<any[]>;
  public filteredOptions: Observable<any[]>;
  public itemList: any[] = [];
  itemsFilterForm: FormGroup;
  targetControl = new FormControl(null);
  budgetControl = new FormControl(null);
  categoryTypeFormControl = new FormControl(null);
  searchFormControl = new FormControl(null);
  optionFormControl = new FormControl(null);
  filter = [];
  isCategoryTypeLoading: boolean = false;
  isTargetLoading: boolean = false;
  isItemLoading: boolean = false;
  categoryTypeList: CategoryType[];
  targetList: Target[];
  filteredTargetList: Observable<Target[]>;
  componentDestroyed$: Subject<void> = new Subject();
  advertiserId = null;
  contractYear = null;
  public choosenItemList: any[] = [];
  public editStartDate = '';
  public editEndDate = '';
  public isEditing = false;
  public isShowOption = false;
  public selectedOptions: Set<any> = new Set();
  public selectAllOptions = false;
  public saving = false;
  public categoryType = null;

  constructor(
    private iconRegistry: MatIconRegistry,
    private fb: FormBuilder,
    private targetService: TargetService,
    private categoryTypeService: CategoryTypeService,
    private contractCategoryTypeService: ContractCategoryTypeService,
    private contractCgrpOffService: ContractCgrpOffService,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialog,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { contract: Contract; cgrpOffData: ContractCgrpOff }
  ) {
    iconRegistry.addSvgIcon('add-circle-fill', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/add-circle-fill.svg'));
    iconRegistry.addSvgIcon('delete-circle-fill', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/delete-circle-fill.svg'));
  }

  ngOnInit(): void {
    this.itemsFilterForm = this.fb.group({
      startDate: [null, []],
      endDate: [null, []],
      search: [null, []],
    });

    this.getTargetList('');
    this.getCategoryTypeList();

    this.handleCategoryTypeValueChange();
    this.handleItemValueChange();
    console.log('ngOnInit CGRPOffManagingDialog data: ', this.data);

    this.targetControl.valueChanges.pipe(takeUntil(this.componentDestroyed$), debounceTime(1000)).subscribe(value => {
      this.getTargetList(value);
    });

    if (this.data) {
      this.advertiserId = this.data.contract.advertiserId;
      this.contractYear = this.data.contract.stateContractYear;

      this.initFormData();
    }
  }

  private initFormData() {
    if (this.data.cgrpOffData) {
      this.isEditing = true;
      this.budgetControl.setValue(this.data.cgrpOffData.value);

      // pre select for date
      const from = moment(this.data.cgrpOffData.startDate, 'YYYY-MM-DD HH:mm:ss.SSSSSS');
      const to = moment(this.data.cgrpOffData.endDate, 'YYYY-MM-DD HH:mm:ss.SSSSSS');
      this.editStartDate = from.format('DD/MM/YYYY');
      this.editEndDate = to.format('DD/MM/YYYY');

      if (this.data.cgrpOffData.conditions.length > 0) {
        if (this.data.cgrpOffData.conditions[0].isInclude) {
          this.addCategoryBloc('Inclut');
        } else {
          this.addCategoryBloc('Exclut');
        }

        // this.selectedOptions = new Set(this.data.cgrpOffData.conditions);
        this.selectedOptions = new Set();
        let conditions = this.data.cgrpOffData.conditions;
        console.log('conditions: ', conditions);
        for (let i = 0; i < conditions.length; i++) {
          this.selectedOptions.add(conditions[i].category);
        }
      }
    } else {
      this.editStartDate = `01/01/${this.data.contract.cgvContractYear}`;
      this.editEndDate = `31/12/${this.data.contract.cgvContractYear}`;
    }
  }

  private handleItemValueChange() {
    this.searchFormControl.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(value => {
      if (typeof value !== 'string') {
        // this.choosenItemList.push(value);
        this.selectedOptions.add(value);
      }
    });
  }

  removeItem(chip: string): void {
    const index = this.choosenItemList.indexOf(chip);
    if (index >= 0) {
      this.choosenItemList.splice(index, 1);
    }
  }

  public getFormValues() {
    return {
      // isInclude: this.conditionType,
      // categoryType: this.categoryTypeFormControl.value,
      choosenItemList: this.choosenItemList,
    };
  }

  private getTargetList(name) {
    this.isTargetLoading = true;
    const filter = {
      name: name,
    };

    this.targetService
      .getList(filter)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        targetList => {
          this.isTargetLoading = false;
          this.targetList = targetList;
          if (this.data.cgrpOffData) {
            const preselectedItem = this.targetList.find(item => item.id === this.data.cgrpOffData.targetCode);
            if (preselectedItem) {
              this.targetControl.setValue(preselectedItem);
            }
          }
          this.initFilteredTargetList();
        },
        error => {
          this.isTargetLoading = false;
        }
      );
  }

  private initFilteredTargetList() {
    if (!this.isTargetLoading) {
      this.filteredTargetList = this.targetControl.valueChanges.pipe(
        takeUntil(this.componentDestroyed$),
        startWith(''),
        map(value => {
          console.log('targetControl changed : ', value);
          return this._getLabel(value);
        }),
        map(label => {
          console.log('targetControl changed 2: ', label);
          return (label ? this._filter(label) : this.targetList.slice());
        }),
      );
    }
  }

  private _getLabel(value: string | Target): string {
    return typeof value === 'string' ? value : value?.name || '';
  }

  /***
   * Fonction pour afficher le label dans l'input lors de la sélection
   * @param value
   * @private
   */
  private _filter(value: string = ''): Target[] {
    console.log('_filter value: ', value);
    const filterValue = value.toLowerCase();
    return this.targetList.filter(
      (target) => {
        return target.id.toLowerCase().includes(filterValue) || target.name.toLowerCase().includes(filterValue);
      }
    );
  }

  public displayTarget(negotiationType: FilteredTarget): string {
    return negotiationType?.name && negotiationType?.id ? `${negotiationType.name} (${negotiationType.id})` : '';
  }

  private getCategoryTypeList() {
    this.isCategoryTypeLoading = true;

    this.categoryTypeService
      .getCategoryTypeList({})
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        categoryType => {
          this.categoryTypeList = categoryType;
          this.isCategoryTypeLoading = false;

          if (this.categoryTypeList.length > 0
                && this.data.cgrpOffData && this.data.cgrpOffData.conditions.length > 0) {
            const preselectedItem = this.categoryTypeList.find(item => item.id === this.data.cgrpOffData.conditions[0].categoryType.id);
            if (preselectedItem) {
              this.categoryTypeFormControl.setValue(preselectedItem);
            }
          }
        },
        error => {
          this.isCategoryTypeLoading = false;
        }
      );
  }

  private handleCategoryTypeValueChange() {
    this.categoryTypeFormControl.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(value => {
      this.searchFormControl.setValue('');
      this.categoryType = value;
      this.getCategoryItemList();
      this.searchFormControl.valueChanges.pipe(takeUntil(this.componentDestroyed$), debounceTime(1000)).subscribe(value => {
        this.getCategoryItemList();
      });
    });
  }

  private getParamsByCategoryType(categoryType) {
    switch (categoryType.id) {
      case 1: // Advertiser
      case 4: // Product
        return { advertiserGroupId: this.advertiserId };
      case 6: // Campaign
        return { advertiserGroupId: this.advertiserId, year: this.contractYear };
      case 3: // Secodip
        return { year: this.contractYear };
      case 2: // Mandatory / Agency
      case 5: // Product Type
      case 7: // Target
      case 8: // Channel
      case 9: // Screen Type
      case 10: // Screen
      case 11: // Time Slot
      default:
        return {};
    }
  }

  private getCategoryItemList() {
    let params = this.getParamsByCategoryType(this.categoryType);
    params = Object.assign(params, {
      name: this.searchFormControl.value,
    });

    this.contractCategoryTypeService
      .getItemList(params, this.categoryType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        items => {
          this.isItemLoading = false;
          this.itemList = items;
          /* if (this.data.cgrpOffData && this.data.cgrpOffData.conditions.length > 0) {
            const preselectedItem = this.itemList.find(item => item.id === this.data.cgrpOffData.conditions[0].idCategoryValue);
            if (preselectedItem) {
              this.searchFormControl.setValue(preselectedItem);
            }
          } */
          this.initFilteredItemList();
          this.initFilteredOptions();
        },
        error => {
          this.isItemLoading = false;
        }
      );
  }

  private initFilteredItemList() {
    if (!this.isItemLoading) {
      this.filteredItemList = this.searchFormControl.valueChanges.pipe(
        takeUntil(this.componentDestroyed$),
        startWith(''),
        map(value => this._getItemLabel(value)),
        map(label => (label ? this._itemFilter(label) : this.itemList.slice()))
      );
    }
  }

  private initFilteredOptions() {
    if (!this.isItemLoading) {
      this.filteredOptions = this.optionFormControl.valueChanges.pipe(
        takeUntil(this.componentDestroyed$),
        startWith(''),
        map(value => this._getItemLabel(value)),
        map(label => (label ? this._itemFilter(label) : this.itemList.slice()))
      );
    }
  }

  /**
   * @param value
   * @private
   */
  private _getItemLabel(value: string | NegotiationType): string {
    return typeof value === 'string' ? value : value?.name || '';
  }

  /***
   * @param value
   * @private
   */
  private _itemFilter(value: string = ''): any[] {
    const filterValue = value.toLowerCase();
    return this.itemList.filter(item => item.id?.toString().includes(filterValue) || item.name?.toLowerCase().includes(filterValue));
  }

  public displayItem(item: FilteredItem): string {
    return item?.name && item?.id ? `${item.name} (${item.id})` : '';
  }

  public addCategoryBloc(conditionType: string): void {
    this.noCategoryBlockAdded = false;
    this.categoryConditionType = conditionType;
  }

  public listAllOptions(): void {
    console.log('all options');
    this.searchFormControl.setValue('');
    this.getCategoryItemList();
    this.isShowOption = true;
  }

  onValidationButtonClick() {
    // TODO: validate data

    let conditions = [];
    if (this.selectedOptions.size > 0) {
      for (const option of this.selectedOptions) {
        conditions.push({
          'category': this.categoryTypeFormControl.value,
          'conditionType': this.categoryConditionType,
          'conditionValue': option
        });
      }
    }

    let data = {
      id: this.data?.cgrpOffData?.id ? this.data.cgrpOffData.id : null,
      targetCode: this.targetControl && this.targetControl.value ? this.targetControl.value.id : '',
      startDate: this.itemsFilterForm.get('startDate').value,
      endDate: this.itemsFilterForm.get('endDate').value,
      value: this.budgetControl.value,
      clientId: this.data.contract.id,
      stateContractYear: this.data.contract.stateContractYear,
      conditions: conditions,
    };

    console.log('data sending: ', data);
    this.saving = true;

    this.contractCgrpOffService.saveItem(data).subscribe(rs => {
      console.log('rs', rs);
      this.saving = false;

      this.dialogRef.closeAll();
    }, error => {
      console.log('error: ', error);
      this.snackBar.open('La validation a été échouée', null, {
        duration: 4000,
        verticalPosition: 'top',
        panelClass: ['tv-chip-error'],
      });
      this.saving = false;
    });
  }

  onCancelUpdateButtonClick() {
    this.dialogRef.closeAll();
  }

  goBack() {
    this.isShowOption = false;
  }

  saveOptions() {
    this.isShowOption = false;
  }

  onOptionChange(event: any, item: any) {
    if (event.checked) {
      if (!this.selectedOptions.has(item)) {
        this.selectedOptions.add(item);
      }
    } else {
      this.selectedOptions.delete(item);
    }
  }

  isOptionSelected(item) {
    return this.selectedOptions.has(item);
  }

  onSelectAllChange(event: any, item: any) {
      this.selectAllOptions = event.checked;
      this.filteredOptions?.subscribe(options => {
        options.forEach(item => {
          if (this.selectAllOptions) {
            this.selectedOptions.add(item);
          } else if (!this.selectAllOptions) {
            this.selectedOptions.delete(item);
          }
        });
      });
    }

    removeCondition(item: any) {
      console.log('remove: ', item);
      this.selectedOptions.delete(item);
    }

}
