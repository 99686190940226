export interface JsonContractState {
  id: number;
  year: string;
  name: string;
}

export class ContractState {
  id: number;
  year: string;
  name: string;

  constructor(jsonContractState: JsonContractState = null) {
    this.id = jsonContractState.id;
    this.year = jsonContractState.year;
    this.name = jsonContractState.name;
  }
}
