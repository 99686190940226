export interface JsonContractProductType {
  codType?: number;
  libType?: string;
}

export class ContractProductType {
  id: number;
  name: string;

  constructor(jsonContractProductType: JsonContractProductType = null) {
    this.id = jsonContractProductType.codType;
    this.name = jsonContractProductType.libType;
  }
}
