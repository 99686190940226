import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileModel } from '@core/models/file.model';
import {
  FilteredCampaign,
  FilteredPlan,
  FilteredProduct,
  FilteredTarget,
  JsonFilteredCampaign,
  JsonFilteredPlan,
  JsonFilteredProduct,
  JsonFilteredTarget,
} from '@core/models/plan-filter/plan-filter.model';
import { JsonListPlan, JsonPlanPerformance, JsonPlanSpot, ListPlan, PlanPerformance, PlanSpot } from '@core/models/plan/plan.model';
import { BaseService } from '@core/services/base/base.service';
import { ErrorHandlerService, HttpError } from '@core/services/error-handler.service';
import { environment } from '@env/environment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface ExportEvalTvFileFilter {
  plan_id: number;
  product_name: string;
  main_target: string;
  start_date: string;
  end_date: string;
  user: string;
  file_name: string;
}

@Injectable({
  providedIn: 'root',
})
export class PlanService extends BaseService {
  public savedPlanForm: any;
  public savedPlanFormFromServer: any;
  public savedLastOpenedDateByChannel: string[] = [];

  // Last computed plan couverture value
  public computedPlanCouverture: number = null;

  private getPlanSpot = new Subject<boolean>();
  public getPlanSpot$ = this.getPlanSpot.asObservable();

  private computeCouverture = new Subject<any>();
  public computeCouverture$ = this.computeCouverture.asObservable();

  private loadingPlan = new Subject<boolean>();
  public loadingPlan$ = this.loadingPlan.asObservable();

  private loadingMainPlan = new Subject<boolean>();
  public loadingMainPlan$ = this.loadingMainPlan.asObservable();

  // Status of plan couverture loading
  private checkSavePlan = new Subject<any>();
  public checkSavePlan$ = this.checkSavePlan.asObservable();

  // Status of plan couverture loading
  private couvertureLoading = new Subject<boolean>();
  public couvertureLoading$ = this.couvertureLoading.asObservable();

  private refreshTotalArea = new Subject<boolean>();
  public refreshTotalArea$ = this.refreshTotalArea.asObservable();

  private clearPlanFilters = new Subject<void>();
  public clearPlanFilters$ = this.clearPlanFilters.asObservable();

  private campaignsFilter = new Subject<void>();
  public campaignsFilter$ = this.campaignsFilter.asObservable();

  private channelsFilter = new Subject<void>();
  public channelsFilter$ = this.channelsFilter.asObservable();

  private channelsVisibilityFilter = new Subject<void>();
  public channelsVisibilityFilter$ = this.channelsVisibilityFilter.asObservable();

  private targetsFilter = new Subject<void>();
  public targetsFilter$ = this.targetsFilter.asObservable();

  private clearSelectedSpotList = new Subject<void>();
  public clearSelectedSpotList$ = this.clearSelectedSpotList.asObservable();

  private setTargetsFilter = new Subject<{
    targetId: string;
    index: string;
    main: boolean;
  }>();
  public setTargetsFilter$ = this.setTargetsFilter.asObservable();

  private campaignSelected = new Subject<FilteredCampaign>();
  public campaignSelected$ = this.campaignSelected.asObservable();

  private deleteSpotsFromSource = new Subject<PlanSpot[]>();
  public deleteSpotsFromSource$ = this.deleteSpotsFromSource.asObservable();

  private bookingSpotsTrigger = new Subject<void>();
  public bookingSpotsTrigger$ = this.bookingSpotsTrigger.asObservable();

  private drawerBasketTrigger = new Subject<any>();
  public drawerBasketTrigger$ = this.drawerBasketTrigger.asObservable();

  private refreshPlanSpots = new Subject<any[]>();
  public refreshPlanSpots$ = this.refreshPlanSpots.asObservable();

  private buildMassSpotsTrigger = new Subject<any>();
  public buildMassSpotsTrigger$ = this.buildMassSpotsTrigger.asObservable();

  private drawerMassTrigger = new Subject<any>();
  public drawerMassTrigger$ = this.drawerMassTrigger.asObservable();

  private planDataTrigger = new Subject<PlanSpot[]>();
  public planDataTrigger$ = this.planDataTrigger.asObservable();

  public planPerformance?: PlanPerformance;
  public planVersion: { current?: PlanPerformance; previous?: PlanPerformance } = { current: null, previous: null };

  private routeFilter = `${environment.api_base_url}/tv/filter`;
  private routeFilterPlan = `${environment.api_base_url}/tv/filter/plan`;
  private routeCalculatePlanSpots = `${environment.api_base_url}/tv/plan-spots`;
  private routePlan = `${environment.api_base_url}/tv/plan`;
  private routeListPlan = `${environment.api_base_url}/tv/plans-list`;
  private bilanExportRoute = `${environment.api_base_url}/export-bilan`;
  private bilanDownloadRoute = `${environment.api_base_url}/tv/download-bilan-eval-tv`;
  private routeCancelReservedSpot = `${environment.api_base_url}/tv/cancel-reserved-spot`;
  private routeBookSpot = `${environment.api_base_url}/tv/book-spot`;
  private spotListBookingRoute = `${environment.api_base_url}/tv/book-spot-list`;
  private spotListTimeRoute = `${environment.api_base_url}/tv/update-spots-duration`;
  private routePlanCouverture = `${environment.api_base_url}/tv/compute-couverture`;
  private spotListMassRoute = `${environment.api_base_url}/tv/spots-mass`;

  constructor(private http: HttpClient, private errorHandlerService: ErrorHandlerService) {
    super();
  }

  /**
   * Event when user click on search button
   */
  loadingPlanSpotsGrid(unCheckSave: boolean = false): void {
    this.getPlanSpot.next(unCheckSave);
  }

  /**
   * Emit computing couverture event
   */
  emitComputingCouvertureEvent(): void {
    this.emitCouvertureLoaderEvent(true);
    this.computeCouverture.next();
  }

  /**
   * Emit couverture loader event
   */
  emitCouvertureLoaderEvent(isLoading: boolean): void {
    this.couvertureLoading.next(isLoading);
  }

  /**
   * Emit check save plan event
   */
  emitCheckSavePlanEvent(): void {
    this.checkSavePlan.next();
  }

  /**
   * Event when is loading
   * @param isLoading
   */
  loadingChangePlan(isLoading: boolean): void {
    this.loadingPlan.next(isLoading);
  }

  /**
   * Event plan is loading main data
   * @param isLoading
   */
  loadingChangeMainPlan(isLoading: boolean): void {
    this.loadingMainPlan.next(isLoading);
  }

  /**
   * Send event when we need to clear plan filters
   */
  clearPlanFiltersEvent(): void {
    this.clearPlanFilters.next();
  }

  /**
   * Send event to update visibility channel
   */
  updateVisibilityChannel(): void {
    this.channelsVisibilityFilter.next();
  }

  /**
   * Event to load target in filter
   */
  loadTarget(): void {
    this.targetsFilter.next();
  }

  /**
   * Event to load channels in filter
   */
  loadChannels(): void {
    this.channelsFilter.next();
  }

  /**
   * Event to load campaign in filter
   */
  loadCampaigns(): void {
    this.campaignsFilter.next();
  }

  /**
   * Event to refresh area total
   */
  loadRefreshTotalArea(): void {
    this.refreshTotalArea.next();
  }

  /**
   * Event to load selected campaign
   */
  loadCampaignSelected(campaignSelected: FilteredCampaign): void {
    this.campaignSelected.next(campaignSelected);
  }

  /**
   * Event to delete spot from datasource
   */
  deleteSpots(planSpots: PlanSpot[]): void {
    this.deleteSpotsFromSource.next(planSpots);
  }

  /**
   * Event to feed target
   * @param targetId of selected target
   * @param index of position
   * @param main target
   */
  feedTargetFromSave(targetId: string, index: string, main: boolean): void {
    this.setTargetsFilter.next({ targetId, index, main });
  }

  /**
   * Trigger spots booking
   */
  public triggerSpotsBookingEvent(): void {
    this.bookingSpotsTrigger.next();
  }

  /**
   * Trigger drawer basket
   */
  public triggerDrawerBasketEvent(spotListToBook: any): void {
    this.drawerBasketTrigger.next(spotListToBook);
  }

  /**
   * Trigger drawer mass
   */
  public triggerDrawerMassEvent(spotListObjectToMass: any): void {
    this.drawerMassTrigger.next(spotListObjectToMass);
  }

  /**
   * Event to inject refreshed spots into main grid
   */
  public refreshPlanSpotsGridEvent(planSpotList: any[]): void {
    this.refreshPlanSpots.next(planSpotList);
  }

  /**
   * Event to build send mass spot list
   */
  public buildMassSpotsEvent(): void {
    this.buildMassSpotsTrigger.next();
  }

  /**
   * Event to send plan data
   */
  public sendPlanDataEvent(planData: PlanSpot[]): void {
    this.planDataTrigger.next(planData);
  }

  /**
   * Trigger clear selected spot list event
   */
  public triggerClearSelectedSpotsEvent(): void {
    this.clearSelectedSpotList.next();
  }

  /**
   * Transform filters into params
   * @param filters
   */
  buildParamsApi(filters: object): HttpParams {
    let params = new HttpParams();

    if (filters && typeof filters === 'object') {
      Object.entries(filters).forEach(item => {
        params = params.append(item[0], item[1]);
      });
    }

    return params;
  }

  /**
   * Get filtered Products with params
   * @param filter
   */
  getFilteredProducts(filter: object): Observable<FilteredProduct[]> {
    const apiBaseUrl = `${this.routeFilter}/product`;
    const params: HttpParams = this.buildParamsApi(filter);

    return this.http
      .get(apiBaseUrl, { params })
      .pipe(
        map((jsonFilteredProducts: JsonFilteredProduct[]) =>
          jsonFilteredProducts.map((jsonFilteredProduct: JsonFilteredProduct) => new FilteredProduct(jsonFilteredProduct))
        )
      );
  }

  /**
   * Get Campaigns with params
   * @param filter
   */
  getFilteredCampaigns(filter: object): Observable<FilteredCampaign[]> {
    const apiBaseUrl = `${this.routeFilter}/campaign`;
    const params: HttpParams = this.buildParamsApi(filter);

    return this.http.get(apiBaseUrl, { params }).pipe(
      map((jsonFilteredCampaigns: JsonFilteredCampaign[]) =>
        jsonFilteredCampaigns.map((jsonFilteredCampaign: JsonFilteredCampaign) => new FilteredCampaign(jsonFilteredCampaign))
      ),
      catchError((error: Error) => throwError(error))
    );
  }

  /**
   * Get target list
   */
  getTargets(): Observable<FilteredTarget[]> {
    const apiBaseUrl = `${this.routeFilter}/target`;

    return this.http.get(apiBaseUrl).pipe(
      map((jsonFilteredTargets: JsonFilteredTarget[]) =>
        jsonFilteredTargets.map((jsonFilteredTarget: JsonFilteredTarget) => new FilteredTarget(jsonFilteredTarget))
      ),
      catchError((error: Error) => throwError(error))
    );
  }

  /**
   * Get plan spots according to filter values
   */
  computePlanSpots(filter: object): Observable<PlanSpot[]> {
    const apiBaseUrl = this.routeCalculatePlanSpots;
    const params: HttpParams = this.buildParamsApi(filter);

    return this.http
      .get(apiBaseUrl, { params })
      .pipe(map((jsonPlanSpots: JsonPlanSpot[]) => jsonPlanSpots.map((jsonPlanSpot: JsonPlanSpot) => new PlanSpot(jsonPlanSpot))))
      .pipe(catchError((error: Error) => throwError(error)));
  }

  /**
   * Post plan
   * @param plan object to save
   */
  postPlan(plan: PlanPerformance): Observable<PlanPerformance> {
    const apiBaseUrl = this.routePlan;

    return this.http
      .post(apiBaseUrl, plan)
      .pipe(map((jsonPlanPerformance: JsonPlanPerformance) => new PlanPerformance(jsonPlanPerformance)))
      .pipe(catchError((error: Error) => throwError(error)));
  }

  /**
   * Post request to compute Plan couverture
   *
   * @param planData global plan data and plan spots data required to get "couverture" value from EvalTV
   */
  computePlanCouverture(planData: object): Observable<number | null> {
    return this.http
      .post(this.routePlanCouverture, planData)
      .pipe(
        map((couverture: number | null) => {
          this.computedPlanCouverture = couverture;

          return couverture;
        })
      )
      .pipe(catchError((error: Error) => throwError(error)));
  }

  /**
   * Update Plan
   * @param plan form plan
   * @param id of plan
   */
  updatePlan(plan: PlanPerformance, id: number): Observable<PlanPerformance> {
    const apiBaseUrl = `${this.routePlan}/${id}`;

    return this.http
      .put(apiBaseUrl, plan)
      .pipe(map((jsonPlanPerformance: JsonPlanPerformance) => new PlanPerformance(jsonPlanPerformance)))
      .pipe(catchError((error: Error) => throwError(error)));
  }

  /**
   * Get filtered plans by name/id
   * @param filter
   */
  getFilteredPlans(filter: object): Observable<any> {
    const apiBaseUrl = `${this.routeFilterPlan}`;
    const params: HttpParams = this.buildParamsApi(filter);

    return this.http
      .get(apiBaseUrl, { params })
      .pipe(
        map((jsonFilteredPlans: JsonFilteredPlan[]) =>
          jsonFilteredPlans.map((jsonFilteredPlan: JsonFilteredPlan) => new FilteredPlan(jsonFilteredPlan))
        )
      )
      .pipe(catchError((error: Error) => throwError(error)));
  }

  /**
   * Get list of plan
   */
  getListPlan(): Observable<ListPlan[]> {
    const apiBaseUrl = `${this.routeListPlan}`;

    return this.http
      .get(apiBaseUrl)
      .pipe(map((jsonListPlans: JsonListPlan[]) => jsonListPlans.map((jsonListPlan: JsonListPlan) => new ListPlan(jsonListPlan))))
      .pipe(catchError((error: Error) => throwError(error)));
  }

  /**
   * Delete list of plans
   */
  deleteListPlan(listPlans: ListPlan[]): Observable<object> {
    const apiBaseUrl = `${this.routePlan}`;

    return this.http
      .request('delete', apiBaseUrl, {
        body: {
          ids: listPlans.map((listPlan: ListPlan) => listPlan.id),
        },
      })
      .pipe(catchError((error: Error) => throwError(error)));
  }

  /**
   * Open plan by id
   */
  openPlan(id: number): Observable<any> {
    const apiBaseUrl = `${this.routePlan}/${id}`;

    return this.http
      .get(apiBaseUrl)
      .pipe(map((jsonPlanPerformance: JsonPlanPerformance) => new PlanPerformance(jsonPlanPerformance)))
      .pipe(catchError((error: Error) => throwError(error)));
  }

  /**
   * Generate bilan evalTv excel file
   * @param filters
   */
  public generateXlsxBilanEvalTv(filters: ExportEvalTvFileFilter): Observable<void> {
    const httpErrors: HttpError[] = [
      {
        statusCode: 500,
        message: 'Une erreur est survenue lors de la création du plan.',
      },
    ];
    const apiUrl = `${this.bilanExportRoute}`;
    const params: HttpParams = this.buildParamsApi(filters);
    const fileName = filters.file_name;

    return new Observable<void>(observer => {
      this.http.get(apiUrl, { params, responseType: 'blob' }).subscribe(
        () => {
          return this.getFile(fileName, observer);
        },
        error => {
          // This check error.status == 0 is for r7 because 504 gives 0 in error object in R7 environment
          if (error.status == 504 || error.status == 0) {
            return this.getFile(fileName, observer);
          } else {
            this.errorHandlerService.showErrorMessage(httpErrors, error.status);
            observer.error(error);
          }
        }
      );
    });
  }

  /**
   * Cancel reserved spot
   * @param filters
   */
  public cancelReservedSpot(filters: object): Observable<any> {
    const apiUrl = `${this.routeCancelReservedSpot}`;
    const params: HttpParams = this.buildParamsApi(filters);

    return this.http
      .get(apiUrl, { params })
      .pipe(map(response => response))
      .pipe(catchError((error: Error) => throwError(error)));
  }

  /**
   * Book a spot
   * @param filters
   */
  public bookSpot(filters: object): Observable<any> {
    const apiUrl = `${this.routeBookSpot}`;
    const params: HttpParams = this.buildParamsApi(filters);

    return this.http
      .get(apiUrl, { params })
      .pipe(map(response => response))
      .pipe(catchError((error: Error) => throwError(error)));
  }

  // POST with a body of group spots data
  bookSpotList(spotsData: object): Observable<object> {
    return this.http
      .post(this.spotListBookingRoute, spotsData)
      .pipe(map((spotsResponse: object) => spotsResponse))
      .pipe(catchError((error: Error) => throwError(error)));
  }

  /**
   * Update time of selected spot
   * @param spotsData
   */
  updateTimeSpotList(spotsData: object): Observable<object> {
    return this.http
      .post(this.spotListTimeRoute, spotsData)
      .pipe(map((spotsResponse: object) => spotsResponse))
      .pipe(catchError((error: Error) => throwError(error)));
  }

  /**
   * Sand to mass of selected spot
   * @param spotsData
   */
  sendToMassSpotList(spotsData: object): Observable<object> {
    return this.http
      .post(this.spotListMassRoute, spotsData)
      .pipe(map((spotsResponse: object) => spotsResponse))
      .pipe(catchError((error: Error) => throwError(error)));
  }

  public getFile(fileName: string, observer) {
    const params = new URLSearchParams();
    params.set('file_name', fileName);

    const apiUrl = `${this.bilanDownloadRoute}` + '?' + params.toString();

    this.http
      .get(apiUrl, { reportProgress: true, responseType: 'blob' })
      .pipe(
        map(
          res =>
            new FileModel({
              fileName: fileName,
              data: res,
            })
        )
      )
      .subscribe(
        (response: any) => observer.next(response),
        () => {
          setTimeout(() => {
            this.getFile(fileName, observer);
          }, 3000);
        }
      );
  }
}
