import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { merge, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-hour-filter',
  templateUrl: './hour-filter.component.html',
  styleUrls: ['./hour-filter.component.scss'],
})
export class HourFilterComponent implements OnInit {
  @Input() screenFilterForm: FormGroup;
  @Input() shouldOnlyDisplayBreakCodeOption = false;

  hourForm: FormGroup;
  showDropDown = false;

  public displayHour: { id: number; display: string; name: string }[] = [
    { id: 1, display: 'Intitulé', name: 'herecran' },
    { id: 2, display: 'Heure ', name: 'herreelle' },
  ];

  readonly filterAllTimeSlot: { name: string; ischeck: boolean } = { name: 'Toute les plage horaires', ischeck: true };

  constructor(private eRef: ElementRef, private fb: FormBuilder) {}

  private componentDestroyed$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.filterDisplayOptions();
    this.initForm();
    this.initValueChange();
    this.setDefaultValues();
  }

  filterDisplayOptions() {
    if (this.shouldOnlyDisplayBreakCodeOption) {
      this.displayHour = this.displayHour.filter(option => option.id === 1);
    }
  }

  initForm() {
    this.hourForm = this.fb.group({
      startHour: ['00', [Validators.required]],
      startMinute: ['00', [Validators.required]],
      endHour: ['27', [Validators.required]],
      endMinute: ['99', [Validators.required]],
    });
  }

  initValueChange() {
    merge(
      this.hourForm.get('startHour').valueChanges,
      this.hourForm.get('startMinute').valueChanges,
      this.hourForm.get('endHour').valueChanges,
      this.hourForm.get('endMinute').valueChanges
    )
      .pipe(debounceTime(100), takeUntil(this.componentDestroyed$))
      .subscribe(
        () => {
          this.filterAllTimeSlot.ischeck = false;
          this.screenFilterForm.get('startHour').setValue(`${this.hourForm.value.startHour}:${this.hourForm.value.startMinute}`);
          this.screenFilterForm.get('endHour').setValue(`${this.hourForm.value.endHour}:${this.hourForm.value.endMinute}`);
        },
        (error: Error) => {
          console.error(error);
        }
      );
  }

  setDefaultValues() {
    this.screenFilterForm.get('hourType').setValue(this.displayHour[0].name, { emitEvent: false });
    this.screenFilterForm.get('startHour').setValue(`${this.hourForm.value.startHour}:${this.hourForm.value.startMinute}`);
    this.screenFilterForm.get('endHour').setValue(`${this.hourForm.value.endHour}:${this.hourForm.value.endMinute}`);
  }

  onShowDropDownButtonClick() {
    this.showDropDown = !this.showDropDown;
  }

  selectedDisplayHourType(value) {
    this.screenFilterForm.get('hourType').setValue(value.name, { emitEvent: false });
  }

  onSelectAllTimeSlotChange() {
    this.filterAllTimeSlot.ischeck = !this.filterAllTimeSlot.ischeck;

    // If the checkbox is checked we set default value to hourForm and screenFilterForm
    this.hourForm.get('startHour').setValue('00', { emitEvent: false }),
      this.hourForm.get('startMinute').setValue('00', { emitEvent: false }),
      this.hourForm.get('endHour').setValue('27', { emitEvent: false }),
      this.hourForm.get('endMinute').setValue('99', { emitEvent: false });

    this.screenFilterForm.get('startHour').setValue(`${this.hourForm.value.startHour}:${this.hourForm.value.startMinute}`);
    this.screenFilterForm.get('endHour').setValue(`${this.hourForm.value.endHour}:${this.hourForm.value.endMinute}`);
  }

  /**
   * Check and patch formControlTime with correct format of FormControlValue
   * @param controlName
   */
  checkFormatTime(controlName: string): void {
    const formControlHour = this.hourForm.get(controlName);
    let formControlValue = Number(formControlHour.value);

    if (formControlHour.value && formControlValue < 10 && formControlValue >= 0) {
      formControlHour.patchValue('0' + formControlValue);
    } else if (formControlHour.value === 0 && formControlValue === 0) {
      formControlHour.patchValue('00');
    } else if (formControlHour.value && formControlHour.value !== formControlValue) {
      formControlHour.patchValue(formControlValue);
    }
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this.eRef.nativeElement.contains(targetElement);

    if (!clickedInside) {
      this.showDropDown = !!this.eRef.nativeElement.contains(targetElement);
    }
  }
}
