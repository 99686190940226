export function transformDate(date: string): string {
  if (date && date.length > 10) {
    const newDate = new Date(date);
    return newDate.toLocaleString('fr', { year: 'numeric', month: '2-digit', day: '2-digit' });
  }
  return '';
}

export function createDateFrom(date): Date | null {
  if (date && date.length >= 10) {
    const reg = date.match('^\\d{4}-\\d{2}-\\d{2}');
    if (reg) {
      // Because of YYYY-MM-DD vs (new Date construct MM/DD/YYYY) :
      date = date.substring(0, 10);
      const splitDate = date.split('-');
      if (splitDate.length === 3) {
        const year = splitDate[0];
        const month = splitDate[1];
        const days = splitDate[2];
        if (Number(days) <= getDaysInMonth(Number(month), Number(year))) {
          return new Date(year + '/' + month + '/' + days);
        }
      }
    }
  }
  return null;
}

/* January is considered 1. Using Date object should add*/
export function getDaysInMonth(month: number, year: number) {
  switch (month) {
    case 2:
      return (year % 4 == 0 && year % 100) || year % 400 == 0 ? 29 : 28;
    case 4:
    case 6:
    case 9:
    case 11:
      return 30;
    default:
      return 31;
  }
}

export function getOracleTypeDateStringFromDateObject(date: Date): string {
  if (date instanceof Date) {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return date.getFullYear() + '-' + month + '-' + day;
  }
  return '';
}

export function getOracleDateWithoutMinutesFromString(date: String): string | null {
  if (date && date.length >= 10) {
    const reg = date.match('^\\d{4}-\\d{2}-\\d{2}');
    if (reg) {
      return date.substring(0, 10);
    }
  }
  return null;
}
