import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { environment } from "@env/environment";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ChannelsSeco } from "../models/exclude-secodip.model";
import { map } from "rxjs/operators";

export interface ExcluceChaineSecoFilter {
  itemPerPage?: number;
  page?: number;
  sortActive?: string;
  sortDirection?: string;
  search?: string;
}

@Injectable({
  providedIn: "root",
})
export class ChannelSecodipService {
  private channelsSecodipRoute = "/tv/exclude-chaine-seco";

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  public getList(filter: ExcluceChaineSecoFilter): Observable<any> {
    let url = environment.api_base_url + this.channelsSecodipRoute;

    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }
    url += "?" + params.toString();

    return Observable.create((observer) => {
      this.httpService
        .get(url)
        .pipe(
          map((response) => {
            if (response["_embedded"]) {
              let listChannelsSeco = response["_embedded"]["ExcludeChaineSeco"];
              const total =
                listChannelsSeco[listChannelsSeco.length - 1]["total_count"];
              listChannelsSeco.pop();
              listChannelsSeco = listChannelsSeco.map(
                (jsonChannelSeco) => new ChannelsSeco(jsonChannelSeco)
              );
              return { listChannelsSeco, total };
            }
            return response;
          })
        )
        .subscribe(
          (response) => observer.next(response),
          (error) => observer.error(this.catchError(error))
        );
    });
  }

  public deleteSecodip(channelsSeco) {
    let url = environment.api_base_url + this.channelsSecodipRoute + "/delete";
    const params = new URLSearchParams();
    for (const key in channelsSeco) {
      if (channelsSeco.hasOwnProperty(key)) {
        params.set(key, channelsSeco[key]);
      }
    }
    url += "?" + params.toString();
    return Observable.create((observer) => {
      this.httpService.delete(url).subscribe(
        (response) => observer.next(response),
        (error) => observer.error(this.catchError(error))
      );
    });
  }

  public addSecodip(channels, secodip) {
    const url = environment.api_base_url + this.channelsSecodipRoute;
    const data = { secodip: secodip, chaines: channels };
    return Observable.create((observer) => {
      this.httpService.post(url, data).subscribe(
        (response) => observer.next(response),
        (error) => observer.error(this.catchError(error))
      );
    });
  }

  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open("Aucun secodip n'a été trouvé", null, {
          duration: 2000,
          verticalPosition: "top",
        });
        break;
      default:
        this.snackBar.open(
          error.error.detail ? error.error.detail : "Une erreur est survenue",
          null,
          { duration: 2000, verticalPosition: "top" }
        );
        break;
    }

    if (routeRedirect) {
      this.router.navigate([routeRedirect]);
    }

    return error;
  }
}
